import { ButtonDS, Typography } from "get-life-storybook-ts";
import { getMessage } from "../PaymentCardContent/PaymentCardContent.component";
import CheckoutSignController from "./CheckoutSign.controller";
import Spinner from "get-life-storybook-ts/lib/components/Icons/Spinner";
import "./../../css/checkOutSign.scss";

const CheckoutSign = () => {
  const {
    domain,
    handleOnSignDelayedButtonClicked,
    iframeUrl,
    isSignaturit,
    signText,
    translate,
  } = CheckoutSignController();

  const checkouSignMessage = () => {
    return (
      <>
        {getMessage({
          message: translate("checkOutSign.avatarTextFirst"),
          replace: (
            <Typography
              variant="Link"
              text={signText[domain]?.email}
              href={`mailto:${signText[domain]?.email}`}
              className="text-purple !no-underline"
            />
          ),
        })}

        {getMessage({
          message: translate("checkOutSign.avatarTextSecond"),
          replace: (
            <Typography
              variant="Link"
              text={signText[domain]?.phone}
              href={`tel:${signText[domain]?.phone}`}
              className="text-purple !no-underline"
            />
          ),
        })}
      </>
    );
  };

  return (
    <div className="checkoutSign-bodyContainer">
      <div className="checkoutSign-contractContainer">
        <div className="checkoutSign-contractContainer__message">
          {checkouSignMessage()}
        </div>
        {isSignaturit ? (
        <div id="checkoutPay-requestNote" className="checkoutPay-requestNote">
          {iframeUrl !== "" ? (
            <iframe
              width="100%"
              height="100%"
              title="contract-viewer"
              src={iframeUrl}
            />
          ) : (
            <div className="checkoutSign-contractContainer__spinner">
              <Spinner height="12" width="12" />
            </div>
          )}
        </div>
        ) : (
        <div id="checkoutPay-requestNote" className="checkoutPay-requestNote">
          {iframeUrl !== "" ? (
            <iframe
              width="100%"
              height="500px"
              title="contract-viewer"
              src={iframeUrl}
            />
          ) : (
            <div className="checkoutSign-contractContainer__spinner">
              <Spinner height="12" width="12" />
            </div>
          )}
        </div>
        )}
        <div className="checkoutSign-innerButtonContainer">
          <ButtonDS
            buttonType="secondary"
            size="32"
            label={translate("checkOutSign.btnText")}
            onClick={handleOnSignDelayedButtonClicked}
          />
        </div>
      </div>
    </div>
  );
};

export default CheckoutSign;
