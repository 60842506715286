import Progression from "./components/Progression.component";
import Welcome from "./components/Welcome/Welcome.component";
import DecisionPageController from "./DecisionPage.controller";
import moment from "moment";
import "./DecisionPage.styles.scss";
import { Policydetails, ButtonDS } from "get-life-storybook-ts";
import { numberFormatPayment, renderUnderlined } from "../../utils/Utils";

const DecisionPage = (): JSX.Element => {
  const { data, progression, translate, continueProcess, resetQuote } =
    DecisionPageController();

  return (
    <>
      <div className="decisionPage backGround-white">
        <div className="welcome-container">
          <Welcome
            name={data?.name}
            email={data?.email}
            date={moment(data?.birthDate).format("DD-MM-YYYY")}
            city={data?.province}
          />
        </div>

        <div className="decisionPage-container">
          <Policydetails
            bodyText={
              data?.intention
                ? renderUnderlined(data?.intention, translate)
                : ""
            }
            point1={translate("payment.policyDetails.death")}
            point2={{
              text: translate("payment.policyDetails.IPA"),
              value: data?.product === "premium",
            }}
            price={numberFormatPayment(data?.capital, "capital")}
            subtitle={translate("payment.policyDetails.subtitle")}
            title={translate("decision.resume")}
            background="white"
            notShowIpa={
              data?.product === "basic" &&
              process.env.REACT_APP_DOMAIN_LOCALE === "fr"
            }
          />
          <div className="progression-container">
            <Progression
              title={translate("decisionPage.Progression.title")}
              list={progression}
              type=""
            />
          </div>
          <div className="continue-container">
            <p className="text-continue">
              {translate("decision.continue.question")}
            </p>
            <ButtonDS
              rightIcon="ArrowGoRightIcon"
              label={translate("decision.continue.button")}
              onClick={() => continueProcess()}
            />
            <ButtonDS
              buttonType="secondary"
              label={translate("decision.reset.button")}
              onClick={() => resetQuote()}
            />
            <p className="text-continue">
              {translate("decision.call.question")} <br></br>{" "}
              <a href={`tel:${process.env.REACT_APP_TEL}`}>
                {translate("decision.call.link")} {process.env.REACT_APP_TEL}
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DecisionPage;
