import { useContext } from "react";
import Hello from './../../../../../assets/img/hello.png';
import Checked from './../../../../../assets/img/cubierto.png';
import Chat from './../../../../../assets/img/chatWithTeam/Group 42@3x.png';
import Phone from './../../../../../assets/img/phone_contact.png';
import Email from './../../../../../assets/img/email.png';
import Time from './../../../../../assets/img/time_icon.png';
import './../css/globalPosition.scss';
import numberFormat from '../../../../../utils/Utils';
import { I18nContext } from '../../../../../context/i18n.context';
import { GlobalPositionEmails, GlobalPhoneDashboard } from "../../../../../utils/InternationlChanges";
import { ButtonDS } from "get-life-storybook-ts";
import { DOMAIN } from "../../../../../constants/Global";

export default function GlobalPosition(props) {
    const { lead } = props.userData[0];
    const capital = lead.capital;
    const capitalFormatted = numberFormat(capital, 'capital');
    const { product } = props;
    
    const EMAIL = GlobalPositionEmails[DOMAIN];

    const talkAsesor = () => {
        window.open(`mailto:${EMAIL}`);
    };

    const {
        state: { translate },
    } = useContext(I18nContext);

    return (
        <div className="globalPosition-container">
            <p className="globalPosition-title">{translate("dashboard.globalPosition.title")}</p>
            <div className="globalPosition-welcome">
                <img src={Hello} alt="Bienvenido" />
                <p>{translate("dashboard.globalPosition.welcome")}</p>
                <p>&nbsp; {lead.name}</p>
            </div>

            <div className="globalPosition-resume">
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <p className="globalPosition-resumeTitle">
                            {translate("dashboard.globalPosition.summary")}
                        </p>
                    </div>
                </div>
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <img src={Checked} alt="completo" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.globalPosition.capital")}
                        </p>
                    </div>
                    <p className="globalPosition-resumeBoxData">
                        {capitalFormatted}
                    </p>
                </div>
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <img src={Checked} alt="completo" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.globalPosition.warranty")}
                        </p>
                    </div>
                    <p className="globalPosition-resumeBoxData">
                        {product.coverage === 'basic'
                            ? translate("dashboard.globalPosition.basic")
                            : translate("dashboard.globalPosition.premium")}
                    </p>
                </div>
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <img src={Checked} alt="completo" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.globalPosition.myProduct")}
                        </p>
                    </div>
                    <p className="globalPosition-resumeBoxData">
                        {translate("dashboard.globalPosition.lifeInsurance")}
                    </p>
                </div>
            </div>

            <div className="globalPosition-asesorContainer">
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <p className="globalPosition-resumeTitle">
                            {translate("dashboard.globalPosition.talkAdvisor")}
                        </p>
                    </div>
                </div>
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.globalPosition.advisor")}
                        </p>
                    </div>
                    <img
                        src={Chat}
                        alt="contacta con tu asesor y te ayudaremos"
                        style={{ width: '39.9px', height: '34.9px' }}
                    />
                </div>
                <div
                    className="globalPosition-resumeBoxContainer"
                    style={{ backgroundColor: 'rgba(245,196,220, 0.5)' }}
                >
                    <div className="globalPosition-resumeBox">
                        <img src={Time} alt="horario de contacto" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.globalPosition.callTime")}
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            {' '}
                            &nbsp; {translate("dashboard.globalPosition.time")}
                        </p>
                    </div>
                </div>
                <div
                    className="globalPosition-resumeBoxContainer"
                    style={{ backgroundColor: 'rgba(245,196,220, 0.5)' }}
                >
                    <div className="globalPosition-resumeBox">
                        <img src={Phone} alt="horario de contacto" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.globalPosition.phone")}
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            {' '}
                            &nbsp; {GlobalPhoneDashboard[DOMAIN]}
                        </p>
                    </div>
                </div>
                <div
                    className="globalPosition-resumeBoxContainer"
                    style={{ backgroundColor: 'rgba(245,196,220, 0.5)' }}
                >
                    <div className="globalPosition-resumeBox">
                        <img src={Email} alt="teléfono de contacto" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.globalPosition.email")}
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            {' '}
                            &nbsp; {EMAIL}
                        </p>
                    </div>
                </div>
                <div className="globalPosition-resumeButtonContainer">
                    <ButtonDS
                        buttonType="secondary"
                        label={translate("dashboard.globalPosition.reserve")}
                        style={{width: "267px"}}
                        onClick={props.handleAsesor}
                    />
                </div>
            </div>

            <div className="globalPosition-talkTeamContainer">
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <p className="globalPosition-resumeTitle">
                            {translate("dashboard.globalPosition.talkUs")}
                        </p>
                    </div>
                </div>
                <div
                    className="globalPosition-resumeBoxContainer"
                    style={{ borderBottom: 'none ' }}
                >
                    <div className="globalPosition-resumeBox">
                        <p className="globalPosition-resumeTitle">
                        {translate("dashboard.globalPosition.help")}                            
                        </p>

                        <p
                            className="globalPosition-resumeTitle"
                            style={{ fontWeight: 'bold' }}
                        ></p>
                    </div>
                </div>
                <div className="globalPosition-resumeButtonContainer">
                    <ButtonDS
                        buttonType="secondary"
                        onClick={talkAsesor}
                        label={translate("dashboard.globalPosition.talkUs")}
                        style={{width: "267px"}}
                    />
                </div>
            </div>
        </div>
    );
}
