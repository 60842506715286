import HttpCouponsRepository from "../../api/request/Coupons/Coupons.service";
import { CheckCouponI, checkPromotion } from "./checkPromotion";

interface CouponPromotion {
  code: string;
  conditions: string;
  dateId: string;
  description: string;
  detail: number;
  exceptions: null;
  isWholeYearAppliedPromo: boolean;
  mode: string;
  monthlyPrice: number;
  name: string;
  promotedYearlyPrice: number;
  totalSavings: number;
}
export interface ApplyCouponResponse {
  promotion: CouponPromotion;
  promotionalCodeHasApplied: boolean;
}

export const applyPromotion = async ({
  leadId,
  promotion,
  price,
  noValidationNeeded
}: CheckCouponI): Promise<ApplyCouponResponse | null> => {
  const couponsRepository = new HttpCouponsRepository(leadId);
  if (noValidationNeeded) {
    return couponsRepository
    .applyCouponOrganic({ promotionalCode: promotion })
    .then((couponsData: ApplyCouponResponse) => {
      return couponsData;
    })
    .catch(() => {
      throw new Error("Apply Promotion is not available");
    });
  }

  const checkPromo = await checkPromotion({ leadId, promotion, price });
  if (checkPromo) {
    return couponsRepository
      .applyCouponOrganic({ promotionalCode: promotion })
      .then((couponsData: ApplyCouponResponse) => {
        return couponsData;
      })
      .catch(() => {
        throw new Error("Apply Promotion is not available");
      });
  }
  return null;
};
