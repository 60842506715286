function selectGetlifeStyle() {
	const styles = {
		control: (styles, state) => ({
			...styles,
			border: state.menuIsOpen ? 'solid 2px #b8b6b0' : 'solid 2px rgba(33, 33, 33, 0.08)',
			borderRadius: '8px',
			height: '54px',

			boxShadow: state.menuIsOpen ? '0 0 0 5px rgba(33, 33, 33, 0.08)' : null,
			':hover': {
				border: state.menuIsOpen ? 'solid 2px #b8b6b0' : 'solid 2px rgba(33, 33, 33, 0.08)',
			},
			':active': {
				border: 'solid 2px #b8b6b0',
				boxShadow: '0 0 0 5px rgba(33, 33, 33, 0.08)',
			},
		}),

		option: (styles, { isSelected }) => {
			return {
				...styles,
				backgroundColor: isSelected ? '#f2f1ed' : null,
				color: '#2c2302',
				margin: '16px',
				width: 'calc(100% - 32px)',
				padding: '10px 18px',
				borderRadius: '8px',
				':hover': {
					cursor: 'pointer',
					backgroundColor: 'rgba(33, 33, 33, 0.08)'
				},
			};
		},
		valueContainer: (styles) => ({
			...styles,
			maxHeight: '50px'
		}),
		multiValueLabel: (styles) => ({
			...styles,
			backgroundColor: 'white',
			fontSize: '16px'
		}),
		multiValueRemove: (styles) => ({
			...styles,
			backgroundColor: 'white',
			color: '#2c2302',
			':hover': {
				backgroundColor: 'white',
				color: '#ff0000',
				cursor: 'pointer',
			},
		}),
		indicatorSeparator: (styles) => ({
			...styles,
			backgroundColor: 'white',
		}),
		dropdownIndicator: (styles) => ({
			...styles,
			color: '#2c2302',
			marginRight: '8px',
			':hover': {
				color: '#2c2302',
			},
		}),
		placeholder: (styles) => ({
			...styles,
			color: 'hsl(0, 0%, 80%)'
		})
	};
	return styles;
}
export default selectGetlifeStyle();
