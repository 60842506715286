import { IbanElement } from "@stripe/react-stripe-js";
import { ButtonDS, Modal, Tabs } from "get-life-storybook-ts";
import ModalPolicyController, {
  PaymentMethodT,
} from "./ModalPolicy.controller";
import CardSetupForm from "../../../PaymentGateway/components/CardSetupForm/CardSetupForm.component";
import CreaditCard from "get-life-storybook-ts/lib/components/Icons/CreditCard";
import ContinueArrow from "get-life-storybook-ts/lib/components/Icons/ContinueArrow";

const ModalPolicy = ({
  availablePaymentMethod,
  clientSecret,
  fetchGetDataCard,
  nodeElementModal,
  userData,
}: any) => {
  const {
    handleClickSubmit,
    handleStripeStatus,
    IBAN_ELEMENT_OPTIONS,
    setPaymentMethod,
    stripeInputStatus,
    translate,
  } = ModalPolicyController({
    nodeElementModal,
    clientSecret,
    fetchGetDataCard,
    userData,
  });

  const listNavTabs: any = [
    {
      id: "card",
      title: translate("dashboard.tab.card"),
      activeTab: "card",
      icon: <CreaditCard />,
      disabled: !availablePaymentMethod?.creditCard,
    },
    {
      id: "sepa_debit",
      title: translate("dashboard.tab.sepa"),
      icon: <ContinueArrow />,
      disabled: !availablePaymentMethod?.sepa,
    },
  ];

  const listTabsContent: any = [
    {
      id: "card",
      children: (

        <CardSetupForm
          handleStripeStatus={handleStripeStatus}
          stripeInputStatus={stripeInputStatus}
          translate={translate}
        />

      ),
    },
    {
      id: "sepa_debit",
      children: (

        <IbanElement options={IBAN_ELEMENT_OPTIONS} />

      ),
    },
  ];

  return (
    <Modal
      ref={nodeElementModal}
      classNameContent="dashboard__modal"
      ModalTitle={
        <p className="dashboard__modal--title">
          {translate("dashboard.modal.modify")}
        </p>
      }
      ModalBody={
        <Tabs
          listNavTabs={listNavTabs}
          listTabsContent={listTabsContent}
          onChange={(id) => setPaymentMethod(id as PaymentMethodT)}
        />
      }
      ModalActions={
        <ButtonDS
          label={translate("dashboard.policy.editBtn")}
          onClick={() => handleClickSubmit()}
        />
      }
    />
  );
};

export default ModalPolicy;
