import React from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import "./CardSetup.styles.scss";
import { StripeElementChangeEvent } from "@stripe/stripe-js";

interface CardSetupFormI {
  handleStripeStatus: (
    key: "error",
    value: StripeElementChangeEvent["error"],
    keySecond: "complete",
    valueSecond: StripeElementChangeEvent["complete"]
  ) => void;
  stripeInputStatus: {
    complete: StripeElementChangeEvent["complete"];
    error: StripeElementChangeEvent["error"];
  } | undefined
  translate: (key: string) => string;
}

const CardSetupForm: React.FC<CardSetupFormI> = ({
  handleStripeStatus,
  stripeInputStatus,
  translate,
}) => {
  return (
    <div className="card-form">
      <div className="flex flex-col pb-7">
        <p className="text-question-small font-bold  max-w-[740px] md:text-center"></p>
        <div className="w-full">
          <label htmlFor="Nombre" className="font-normal">
            {translate("payment.cardSetup.name")}
          </label>
          <CardNumberElement
            options={{
              showIcon: true,
            }}
            className="mt-2 cardNumber--input"
            onChange={(e) =>
              handleStripeStatus("error", e.error, "complete", e.complete)
            }
          />
          {stripeInputStatus?.error && stripeInputStatus.error.code === 'incomplete_number' && (
            <p className="text-red text-[13px] pt-[10px] text-left self-start">
              {stripeInputStatus?.error.message}
            </p>
          )}
        </div>
      </div>
      <div className="card--row2">
        <div className="flex flex-col pb-7">
          <p className="text-question-small font-bold  max-w-[740px] md:text-center"></p>
          <div className="w-full">
            <label htmlFor="Nombre" className="font-normal">
              {translate("payment.cardSetup.expire")}
            </label>
            <CardExpiryElement
              className="mt-2 cardNumber--input"
              onChange={(e) =>
                handleStripeStatus("error", e.error, "complete", e.complete)
              }
            />
            {stripeInputStatus?.error && stripeInputStatus.error.code === 'incomplete_expiry' && (
              <p className="text-red text-[13px] pt-[10px] text-left self-start">
                {stripeInputStatus?.error.message}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col pb-7">
          <p className="text-question-small font-bold  max-w-[740px] md:text-center"></p>
          <div className="w-full">
            <label htmlFor="Nombre" className="font-normal">
              {translate("payment.cardSetup.cvc")}
            </label>
            <CardCvcElement
              className="mt-2 cardNumber--input"
              onChange={(e) =>
                handleStripeStatus("error", e.error, "complete", e.complete)
              }
            />
            {stripeInputStatus?.error && stripeInputStatus.error.code === 'incomplete_cvc' && (
              <p className="text-red text-[13px] pt-[10px] text-left self-start">
                {stripeInputStatus?.error.message}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSetupForm;
