import * as React from "react";
import { I18nContext } from "../../../../context/i18n.context";
import "./Welcome.styles.scss";

interface WelcomeI {
    name?: string;
    email?: string;
    date?: string;
    city?: string;
}

const Progression: React.FC<WelcomeI> = ({ name, email, date, city }) => {

    const {
        state: { translate },
      } = React.useContext(I18nContext);

    return (
        <div className="welcomeSection">
            <h1 className="welcomeSection__title">{translate("welcome.title")}{name ? ` ${name},` : `,`}</h1>
            <h5 className="welcomeSection_subtitle">{translate("welcome.subtitle")}</h5>
            <div className="welcomeSection__userInfo">
                {email && <div><img src="https://storage.googleapis.com/getlife-bucket-1/public/image/v2/mail.svg" alt="email"/>{email}</div>}
                {date && <div><img src="https://storage.googleapis.com/getlife-bucket-1/public/image/v2/calendarx24.svg" alt="calendar"/>{date}</div>}
                {city && <div><img src="https://storage.googleapis.com/getlife-bucket-1/public/image/v2/map-pin.svg" alt="city"/>{city}</div>}
            </div>
        </div>
    );
};

export default Progression;
