import { useEffect, useState } from "react";
import apiGetDownloadableFiles from "../api/request/apiGetDownloadableFiles";
import { CoverageT } from "../api/request/Lead/Interfaces/ProductInterface.response";

interface DownloadFilesI {
  price: number;
  coverage: CoverageT;
  leadId: string;
  translate: (key: string) => string;
}

const useGetDownloadFiles = ({
  price,
  coverage,
  leadId,
  translate,
}: DownloadFilesI) => {
  const [downloadableFiles, setDownloadableFiles] = useState<any>([]);
  const [downloadableLoading, setDownloadableLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (leadId && coverage) {
        const files = await apiGetDownloadableFiles(
          leadId,
          translate,
          coverage
        );
        setTimeout(() => setDownloadableLoading(false), 1500);
        setDownloadableFiles(files);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, coverage, price]);

  return { downloadableFiles, downloadableLoading };
};

export default useGetDownloadFiles;
