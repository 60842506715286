import { GlobalIcon } from "get-life-storybook-ts"

export const coverList = (translate, LOCALE, ipa) => {
  if (LOCALE === "es" || LOCALE === "pt") {
    if (ipa == "basic") {
      return [
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("newUpsell.listSection.cover.first"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("newUpsell.listSection.cover.second"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("newUpsell.listSection.cover.third"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("newUpsell.listSection.cover.fourth"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("newUpsell.listSection.cover.fifth"),
        },
      ];
    } else {
      return [
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("upsell.Card.Accordion.list.coverIPA.firstPoint"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("upsell.Card.Accordion.list.coverIPA.secondPoint"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("upsell.Card.Accordion.list.coverIPA.thirdPoint"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("upsell.Card.Accordion.list.coverIPA.fourthPoint"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("upsell.Card.Accordion.list.coverIPA.fifthPoint"),
        },
        {
          iconElement: (
            <div>
              <GlobalIcon
                iconName="FilledCheckIcon"
                size="XS"
                color="#018565"
              />
            </div>
          ),
          text: translate("upsell.Card.Accordion.list.coverIPA.sixthPoint"),
        },
      ];
    }
  } else if (LOCALE === "it") {
    return [
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("newUpsell.listSection.cover.first"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("newUpsell.listSection.cover.second"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("newUpsell.listSection.cover.third"),
      }
    ];
  } else {
    return [
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.cover.firstPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.cover.secondPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.cover.thirdPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.cover.fourthPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.cover.sixthPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="FilledCheckIcon" size="XS" color="#018565" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.cover.eighthPoint"),
      },
    ];
  }
};

export const notCoverList = (translate, LOCALE) => {
  if (LOCALE === "es" || LOCALE === "pt") {
    return [
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("newUpsell.listSection.notCover.first"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("newUpsell.listSection.notCover.second"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("newUpsell.listSection.notCover.third"),
      },
    ];
  } else if (LOCALE === "it") {
    return [
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("newUpsell.listSection.notCover.first"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("newUpsell.listSection.notCover.second"),
      },
    ];
  } else {
    return [
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.notCover.secondPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.notCover.thirdPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.notCover.fourthPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.notCover.fifthPoint"),
      },
      {
        iconElement: (
          <div>
            <GlobalIcon iconName="XIcon" size="XS" color="#EA5F5F" />
          </div>
        ),
        text: translate("upsell.Card.Accordion.list.notCover.sixthPoint"),
      },
    ];
  }
};

export const Truspilot = (translate) => [
  {
    commentText: translate("truspilot.opinion.text1"),
    commentUser: translate("truspilot.opinion.name1"),
  },
  {
    commentText: translate("truspilot.opinion.text2"),
    commentUser: translate("truspilot.opinion.name2"),
  },
  {
    commentText: translate("truspilot.opinion.text3"),
    commentUser: translate("truspilot.opinion.name3"),
  },
  {
    commentText: translate("truspilot.opinion.text4"),
    commentUser: translate("truspilot.opinion.name4"),
  },
  {
    commentText: translate("truspilot.opinion.text5"),
    commentUser: translate("truspilot.opinion.name5"),
  },
  {
    commentText: translate("truspilot.opinion.text6"),
    commentUser: translate("truspilot.opinion.name6"),
  },
  {
    commentText: translate("truspilot.opinion.text7"),
    commentUser: translate("truspilot.opinion.name7"),
  },
  {
    commentText: translate("truspilot.opinion.text8"),
    commentUser: translate("truspilot.opinion.name8"),
  }
];

export const IconSection = (translate) => [
  {
    icon: "CalendarOutlineIcon",
    text: translate("newUpsell.iconSection.text1"),
  },
  {
    icon: "SaveMoneyIcon",
    text: translate("newUpsell.iconSection.text2"),
  },
  {
    icon: "PriceIcon",
    text: translate("newUpsell.iconSection.text3"),
  },
];

export const stepList = (translate) => [
  { text: translate("newUpsell.stepbar.text1"), active: true, activePass: true },
  { text: translate("newUpsell.stepbar.text2"), active: false, activePass: false },
  { text: translate("newUpsell.stepbar.text3"), active: false, activePass: false },
];
