const manualValidations = (validations, value) => {
  function validateDNI(dni) {
    var number, lett, letter;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
      number = dni.substr(0, dni.length - 1);
      number = number.replace("X", 0);
      number = number.replace("Y", 1);
      number = number.replace("Z", 2);
      lett = dni.substr(dni.length - 1, 1);
      number = number % 23;
      letter = "TRWAGMYFPDXBNJZSQVHLCKET";
      letter = letter.substring(number, number + 1);
      if (letter != lett || dni.length != 9) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  function validateCIF(cif) {
    if (cif.length !== 9) {
      return false;
    }

    var letters = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
    var digits = cif.substr(1, cif.length - 2);
    var letter = cif.substr(0, 1);
    var control = cif.substr(cif.length - 1);
    var sum = 0;
    var i;
    var digit;

    if (!letter.match(/[A-Z]/)) {
      return false;
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);

      if (isNaN(digit)) {
        return false;
      }

      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = parseInt(digit / 10) + (digit % 10);
        }

        sum += digit;
      } else {
        sum += digit;
      }
    }

    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
  }

  function setResult(errorText) {
    if (errorText) {
      result.error = true;
      result.errorMessage = errorText;
    } else {
      result.error = false;
      result.errorMessage = false;
    }
  }

  const result = {
    error: false,
    errorMessage: false,
  };
  if (validations.required && !value) {
    result.error = true;
    result.errorMessage = validations.requiredText;
    return result;
  } else {
    result.error = false;
    result.errorMessage = false;
  }

  switch (validations.regExp) {
    case "idNumberFunction":
      if (validateDNI(value)) {
        setResult(false);
      } else {
        setResult(validations.errorText);
        return result;
      }
      return result;

    case "cifNumberFunction":
      if (validateCIF(value)) {
        setResult(false);
      } else {
        setResult(validations.errorText);
        return result;
      }
      return result;

    default:
      const clone = new RegExp(validations.regExp);
      if (clone && value !== "" && !clone.test(value)) {
        setResult(validations.errorText);
        return result;
      } else {
        setResult(false);
      }
      return result;
  }
};

export default manualValidations;
