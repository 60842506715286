import {
  Accordion,
  ButtonDS,
  GlobalIcon,
  IconNameT,
  TextInput,
  Typography,
} from "get-life-storybook-ts";
import MinusIcon from "get-life-storybook-ts/lib/components/Icons/MinusIcon";
import PlusIcon from "get-life-storybook-ts/lib/components/Icons/PlusIcon";
import * as React from "react";
import { ValidateCouponT } from "../PaymentPage/PaymentPage.controller";
import CouponController from "./Coupon.controller";

import "./Coupon.styles.scss";

interface ConditionsPromoLinkI {
  translate: (key: string) => string;
  onConditionsClick?: () => void;
}

interface ValidatedMessageI extends ConditionsPromoLinkI {
  validateCoupon?: ValidateCouponT;
}
export interface CouponI extends ValidatedMessageI {
  couponCode?: string;
  handleClick: (code: string, yearlyPrice: number) => void;
  handleRemove: (code: string) => void;
  headerLabel: string;
  isRemovable: boolean;
  yearlyPrice: number;
  couponInfo?: JSX.Element;
}

export const ConditionsPromoLink: React.FC<ConditionsPromoLinkI> = ({
  translate,
  onConditionsClick,
}) => (
  <Typography
    variant="Link"
    weight="700"
    className="cursor-pointer"
    onClick={onConditionsClick}
    text={translate("coupons.inputText.link")}
    color="life-blue-100"
  />
);


const Coupon: React.FC<CouponI> = ({
  couponCode,
  handleClick,
  handleRemove,
  headerLabel,
  translate,
  validateCoupon,
  yearlyPrice,
  couponInfo
}) => {
  const { code, disabled, handleChange } = CouponController({
    couponCode,
  });

  const CouponIconName = {
    validate: "FilledCheckIcon",
    invalidate: "AlertCircleIcon",
    error: "",
    "validate-null": "FilledCheckIcon",
  }[validateCoupon || "error"];

  const CouponIconColor = {
    validate: "#018565",
    invalidate: "#E53737",
    error: "",
    "validate-null": "#018565",
  }[validateCoupon || "error"];

  const CouponMessage = {
    validate: translate("coupons.inputText.validated"),
    invalidate: translate("coupons.inputText.invalid"),
    error: "",
    "validate-null": translate("coupons.inputText.validated"),
  }[validateCoupon || "error"];

  return (
    <div className="coupon">
      <Accordion
        expanded={!!code}
        headerContent={<Typography variant="H3" text={headerLabel} />}
        headerContentActive={
          <Typography
            variant="H3"
            text={translate("coupons.accordion.headerContentActive")}
          />
        }
        icons={[<PlusIcon color="black" />, <MinusIcon color="black" />]}
        bodyContent={
          <div className="coupon__body">
            <div className="coupon__body__input">
              <TextInput
                initialValue={code}
                label={translate("coupons.inputText.label")}
                onValueChange={handleChange}
                disabled={
                  validateCoupon === "validate" ||
                  validateCoupon === "validate-null"
                }
                placeholder={translate("coupons.input.placeholder")}
                iconName={CouponIconName as IconNameT}
                iconColor={CouponIconColor}
                initialError={{
                  error: validateCoupon === "invalidate" ? true : false,
                  message: "",
                }}
              />
              {validateCoupon ? (
                <button
                  onClick={() => {
                    handleChange("");
                    handleRemove(code);
                  }}
                  className="coupon__remove"
                >
                  <GlobalIcon
                    iconName="TrashIcon"
                    size="XXXS"
                    color="currentColor"
                  />
                  <Typography text="" variant="SpanSmall" weight="400">
                    <span dangerouslySetInnerHTML={{ __html: CouponMessage }} />
                  </Typography>
                </button>
              ) : null}
            </div>
            {
              couponInfo ?
              <div className="coupon__body__conditions">
                {couponInfo}
              </div>
              : null
            }
            <div className="coupon__body__button">
              <ButtonDS
                disabled={
                  disabled ||
                  validateCoupon === "validate" ||
                  validateCoupon === "validate-null"
                }
                label={translate("coupons.button.label")}
                onClick={() => handleClick(code, yearlyPrice)}
                leftIcon="TagOutlineIcon"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Coupon;
