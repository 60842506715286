import {
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { SetupIntentResult, StripeElementChangeEvent } from "@stripe/stripe-js";
import { useContext, useState } from "react";
import HttpPaymentRepository from "../../../../../../api/request/Payment/Payment.service";
import { GetlifeContext } from "../../../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../../../context/i18n.context";

export type PaymentMethodT = "card" | "sepa_debit";

type ConfirmStripeIntentT = "confirmCardSetup" | "confirmSepaDebitSetup";

export interface StripeIntentStatusI {
  complete: StripeElementChangeEvent["complete"];
  error: StripeElementChangeEvent["error"];
}

type PaymentMethodSelectedT = {
  [k in PaymentMethodT]: {
    stripeFunction: ConfirmStripeIntentT;
    element: any;
    billing_details: string | any;
  };
};

enum PaymentMethod {
  card = "CREDIT_CARD",
  sepa_debit = "SEPA",
}

const ModalPolicyController = ({
  nodeElementModal,
  clientSecret,
  fetchGetDataCard,
  userData,
}: any) => {
  const { nodeElementAlert, setNotification, leadId, token } =
    useContext(GetlifeContext);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const [stripeInputStatus, setStripeInputStatus] =
    useState<StripeIntentStatusI>({
      complete: false,
      error: undefined,
    });
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodT>("card");
  const stripe = useStripe();
  const elements = useElements();

  const paymentRepository = new HttpPaymentRepository(leadId, token);

  const handleStripeStatus = (
    index: any,
    value: any,
    index2: any,
    value2: any
  ) => {
    const prevValue = stripeInputStatus;

    setStripeInputStatus({
      ...prevValue,
      [index]: value,
      [index2]: value2,
    });
  };

  const isButtonDisabled = () => {
    if (stripeInputStatus.complete && stripeInputStatus.error === undefined) {
      return false;
    }
    return true;
  };

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    placeholderCountry: process.env.REACT_APP_DOMAIN_LOCALE,
  };

  const paymentMethodSelected: PaymentMethodSelectedT = {
    card: {
      stripeFunction: "confirmCardSetup",
      element: CardNumberElement,
      billing_details: userData.name + " " + userData.lastName,
    },

    sepa_debit: {
      stripeFunction: "confirmSepaDebitSetup",
      element: IbanElement,
      billing_details: {
        name: userData.name + " " + userData.lastName,
        email: userData.email,
      },
    },
  };

  const fetchUpdatePaymentMethod = async (result: SetupIntentResult) => {
    try {
      await paymentRepository.updatePaymentMethod({
        ulid: leadId,
        paymentMethodId: result.setupIntent?.payment_method!,
        typePaymentMethod: PaymentMethod[paymentMethod],
      });
      await fetchGetDataCard();
      setNotification({
        message: translate("dashboard.policy.alert.paymentMethod.success"),
        type: "success",
        time: 5,
      });
    } catch (error) {
      throw error;
    }
  };

  const handleClickSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const stripeSetUpElement = paymentMethodSelected[paymentMethod!];
    try {
      const result: SetupIntentResult = await stripe[
        stripeSetUpElement.stripeFunction
      ](clientSecret, {
        payment_method: {
          [paymentMethod]: elements.getElement(stripeSetUpElement.element),
          billing_details: stripeSetUpElement.billing_details,
        } as any,
      });

      if (result.error) {
        setNotification({
          message: translate("dashboard.policy.alert.paymentMethod.error"),
          type: "error",
          time: 5,
        });
      } else {
        await fetchUpdatePaymentMethod(result);
      }
    } catch (error) {
      setNotification({
        message: translate("dashboard.policy.alert.paymentMethod.error"),
        type: "error",
        time: 5,
      });
    }
    nodeElementAlert.current.handleShowPortal();
  };

  return {
    isButtonDisabled,
    handleClickSubmit,
    handleStripeStatus,
    IBAN_ELEMENT_OPTIONS,
    paymentMethod,
    setPaymentMethod,
    stripeInputStatus,
    translate,
  };
};

export default ModalPolicyController;
