import { DOMAIN, ENV } from "../constants/Global";
import { UserStatusT } from "../context/GetLifeContext.context";
import USER_STATUS from "./RecoverUserStatus";

export const redirect = (userStatus: UserStatusT) => {
    if (DOMAIN === "it" && ENV === "PRD" && (userStatus === "payment_pending" || userStatus === "paymentmethodpending_pending")) {
        return "/agent-confirmation";
    } else {
        const getroute = window.location.pathname;
        if (USER_STATUS[userStatus].includes(getroute)) {
            return getroute;
        } else return USER_STATUS[userStatus][0]
    }
} 