import React, { useEffect, useState } from "react";
import "./AccordionCustom.scss";
import { GlobalIcon, Typography } from "get-life-storybook-ts";

interface AccordionCustomI {
  accordionContent: React.ReactNode | string;
  expanded?: boolean;
  handleOnClick?: () => void;
  headerContent: string;
  id?: string;
}

const AccordionCustom: React.FC<AccordionCustomI> = ({
  accordionContent,
  expanded,
  headerContent,
}) => {
  const [active, setActive] = useState(expanded);

  useEffect(() => {
    if (active !== expanded) setActive(expanded);
  }, [expanded]);

  return (
    <div className={`accordionCustom-wrapper ${active ? "active" : ""}`}>
      <div className="accordionCustom-title" onClick={() => setActive(!active)}>
        <Typography text={headerContent} variant="BodyLarge" weight="700" />
        <GlobalIcon iconName={active ? "MinusIcon" : "PlusIcon"} size="XXS" color="var(--primary-color)" />
      </div>
      {active && <div className="accordionCustom-content">{accordionContent}</div>}
    </div>
  );
};

AccordionCustom.defaultProps = {
  id: "",
  expanded: false,
  headerContent: "",
  accordionContent: "",
  handleOnClick: () => null,
};

export default AccordionCustom;
