import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import "./formAdress.scss";
import { GetlifeContext } from "../../../context/GetLifeContext.context";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import { I18nContext } from "../../../context/i18n.context";
import { AutoCompleteGoogle, ButtonDS, PredictiveSearch, TextInput } from "get-life-storybook-ts";
import {
  validateZipCodeFR,
  validationsGlobal,
} from "../../../utils/validations";
import { GlobalProvinceApi } from "../../../utils/InternationlChanges";
import { StatusContext } from "../../../context/StatusContext";
import { DOMAIN } from "../../../constants/Global";

export default function FormAdress(props) {
  const { setLoading, token, leadId } =
    useContext(GetlifeContext);
  const navigate = useNavigate();
  const [first, setFirst] = useState(true);
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [province, setProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [city, setCity] = useState("");
  const [number, setNumber] = useState("");
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [disabled, setDisabled] = useState({
    address: true,
    zipCode: true,
    province: true,
    city: true,
    number: true
  });

  const {
    state: { translate },
  } = useContext(I18nContext);

  const { getStatus } = useContext(StatusContext);

  const leadRepository = new HttpLeadRepository(leadId, token);
  const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE;

  useEffect(() => {
    setLoading(true);

    leadRepository.getCheckoutData().then((data) => {
      if (data.code === 401) {
        navigate("/login");
      } else {
        data.lead.city && setCity(data.lead.city);
        data.lead.province && setProvince(data.lead.province);
        data.lead.zipcode && setZipCode(data.lead.zipcode);
        fetchRegionalData(data.lead.zipcode);
        data.lead.addressLine && setAddress(data.lead.addressLine);
      }
    });
    setLoading(false);
    getStatus(true);
  }, []);

  const sendAnswerFormAddress = async () => {
    const data = {
      addressLine: address,
      province: DOMAIN !== "it" ? province : null,
      city: city,
      zipcode: parseInt(zipCode),
      number: DOMAIN === "it" ? number : null
    };

    await leadRepository.saveData(data).then((res) => {
      navigate(`/coverage/2`);
    });
  };

  const validationsChecks = {
    zipCode: [
      (value) =>
        validationsGlobal({
          value,
          setDisabled,
          disabledData: disabled,
          translate,
          field: "zipCode",
          exactly: 5,
        }),
    ],
    province: [
      (value) =>
        validationsGlobal({
          basic: true,
          disabledData: disabled,
          field: "province",
          setDisabled,
          translate,
          value,
        }),
    ],
    city: [
      (value) =>
        validationsGlobal({
          basic: true,
          disabledData: disabled,
          field: "city",
          setDisabled,
          translate,
          value,
        }),
    ],
    number: [
      (value) => 
      DOMAIN === "it" && validationsGlobal({
        basic: DOMAIN === "it" ? true : false,
        disabledData: disabled,
        field: "number",
        setDisabled,
        translate,
        value,
      })
    ]
  };

  useEffect(() => {
    setLoading(true);
    leadRepository.getQuestionNomenclator(GlobalProvinceApi[LOCALE]).then((res) => {
      const { answers } = res;
      setProvinceList([...answers]);
    });
    setLoading(false);
  }, []);

  const fetchRegionalData = async (zipCode) => {
    if (zipCode && DOMAIN !== "it") {
      try {
        const dataRes = await leadRepository.getRegionalData(zipCode);
        setProvince(dataRes.province);
        setCity(dataRes.city);
      } catch (error) { }
    }
  };

  const fetchRegionalDataFR = async () => {
    let response;
    try {
      const res = await validateZipCodeFR({
        value: zipCode,
        errorGlobal: error,
        translate,
      });
      if (!res.error) {
        await fetchRegionalData();
      }
      response = res;
    } catch (error) { }

    setError({ ...error, zipCode: response });
    setDisabled({
      address: !address,
      province: !province,
      city: !city,
      zipCode: response.error,
    });
  };

  useEffect(() => {
    (async () => {
      if (zipCode && zipCode.length > 4) {
        if ((LOCALE === "es" || LOCALE === "pt" || LOCALE === "it")) {
          await fetchRegionalData(zipCode);
        } else {
          await fetchRegionalDataFR();
        }
      }
    })();
  }, [zipCode]);

  useEffect(() => {
    if (!first) {
      const errorZipCode = validationsChecks.zipCode[0](zipCode);
      const errorProvince = validationsChecks.province[0](province);
      const errorCity = validationsChecks.city[0](city);
      const errorNumber = validationsChecks.number[0](number);

      setError({
        zipCode: errorZipCode,
        province: errorProvince,
        city: errorCity,
      });

      setDisabled({
        ...disabled,
        address: !address,
        zipCode: errorZipCode.error,
        province: errorProvince.error,
        city: errorCity.error,
        number: DOMAIN === "it" ? errorNumber.error : false
      });
    }
    setFirst(false);
  }, [address]);

  return (
    <div className="formAdress-container">
      <div className="formAdress-GridContainer">
        <div className="row">
          <AutoCompleteGoogle
            initialVal={address}
            name={"address"}
            label={translate("dashboard.profile.address")}
            handleAddress={setAddress}
            handleZipCode={setZipCode}
            handleProvince={setProvince}
            handleNumber={setNumber}
            handleCity={DOMAIN !== "it" ? setCity : () => null}
            error={error.address}
            errorMessage={errorMessage.address}
            translate={translate}
            DOMAIN={DOMAIN}
          />
        </div>

        <div className="row">
          {DOMAIN !== "it" ? (
            <PredictiveSearch
              initialValue={province}
              onValueChange={setProvince}
              options={provinceList}
              initialError={error.province}
              label={translate("dashboard.profile.province")}
              placeholder={translate("dashboard.profile.province.placeholder")}
              validationChecks={validationsChecks.province}
            />
          ) : (
            <TextInput
              initialValue={number}
              label={translate("aggregator.personalInformation.number")}
              placeholder={translate(
                "aggregator.personalInformation.number.placeHolder"
              )}
              onValueChange={setNumber}
              initialError={error.number}
              validationChecks={validationsChecks.number}
            />
          )}
          <TextInput
            initialValue={zipCode}
            label={translate("aggregator.personalInformation.cp")}
            placeholder={translate(
              "aggregator.personalInformation.cp.placeHolder"
            )}
            onValueChange={setZipCode}
            initialError={error.zipCode}
            validationChecks={validationsChecks.zipCode}
          />
        </div>

        <div className="row">
          {
            DOMAIN === "it" ? (
              <PredictiveSearch
                initialValue={city}
                onValueChange={setCity}
                options={provinceList}
                initialError={error.city}
                label={translate("dashboard.profile.city")}
                placeholder={translate("dashboard.profile.city.placeholder")}
                validationChecks={validationsChecks.city}
              />
            ) : (
              <TextInput
                initialValue={city}
                label={translate("dashboard.profile.city")}
                placeholder={translate("dashboard.profile.city.placeholder")}
                onValueChange={setCity}
                initialError={error.city}
                validationChecks={validationsChecks.city}
              />
            )
          }

        </div>

        <div className="formAdress-buttonContainer">
          <ButtonDS
            label={translate("continuar")}
            onClick={sendAnswerFormAddress}
            disabled={!Object.values(disabled).every((item) => item === false)}
          />
        </div>
      </div>
    </div>
  );
}
