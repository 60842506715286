/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { I18nContext } from "../../../../context/i18n.context";
import { GetlifeContext } from "../../../../context/GetLifeContext.context";
import { GetLifeTrackerContext } from "../../../../context/GetLifeTracker.context";
import { useLocation, useParams } from "react-router";
import HttpCouponsRepository from "../../../../api/request/Coupons/Coupons.service";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import {
  ValidateCouponT,
  CouponInfoI,
} from "../PaymentPage/PaymentPage.controller";
import { ProductI } from "../../../../api/request/Lead/Interfaces/ProductInterface.response";
import {
  DataI,
  PurchasePeriodT,
} from "../../../../api/request/Lead/Interfaces/DataInterface.response";
import { handleHubspot } from "../../../../utils/HandleHubspot";
import apiGetDownloadableFiles from "../../../../api/request/apiGetDownloadableFiles";
import { getCookie } from "../../../../hooks/getCookie";

const PaymentGatewayController = () => {
  const [checkOut, setCheckOut] = useState<DataI | null>(null);
  const [product, setProduct] = useState<ProductI | null>(null);
  const [setupIntent, setSetupIntent] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [validateCoupon, setValidateCoupon] = useState<ValidateCouponT>();
  const [couponInfo, setCouponInfo] = useState<CouponInfoI>();
  const [purchasePeriod, setPurchasePeriod] =
    useState<PurchasePeriodT>("monthly");
  const [firstIn, setFirstIn] = useState<boolean>(true);
  const [transitionLoading, setTransitionLoading] = useState<boolean>(false);
  const [transitionPageData, setTransitionPageData] = useState<any>(null);
  const [downloadableFiles, setDownloadableFiles] = useState<any>(null);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const {
    init,
    leadId,
    loading,
    domain,
    setLoading,
    setProgressBar,
    token,
    isABtesting,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_content,
    utm_term,
    utm_test,
    gclid,
    fbclid,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion,
    nodeMaxCapitalModal
  } = useContext(GetlifeContext);
  const { handleTrackerQuestion } = useContext(GetLifeTrackerContext);

  const { stepPayment } = useParams();
  const location = useLocation();
  const couponsRepository = new HttpCouponsRepository(leadId, token);
  const leadRepository = new HttpLeadRepository(leadId, token);

  const nodeConditionsModal: any = useRef(null);

  const handleConditions = () => {
    nodeConditionsModal.current?.handleShowPortal();
  };
  const paymentLocation = location?.pathname.split("/")[1];

  const prepareDataForCampaign = (campaign: string) => {
    if (campaign === "SAVETHECHILDREN") {
      return {
        description: "Donación a Save the Children",
      };
    }
  };

  const handlePurchasePeriod = async (value: any) => {
    await leadRepository.updatePeriod(value);
    setPurchasePeriod(value);
  };

  const addedCoupon = async (code: string) => {
    try {
      await couponsRepository.applyCoupon({ promotionalCode: code });
      handleTrackerQuestion({
        type: "FS",
        event: `appliedCoupon`,
        data: {
          promotionalCode: code,
          leadId: leadId,
          market: domain
        },
      });
    } catch (error) { }
  };

  const fetchCheckPromo = async (
    code: string,
    yearlyPrice: number,
    func?: (code: string) => void
  ) => {
    const response = await couponsRepository.checkPromotionalCode({
      promotionalCode: code,
      yearlyPrice: yearlyPrice,
    });
    if (response.is_applicable_promo) {
      func && (await func(code));
      setValidateCoupon("validate");
      setCouponInfo({
        conditions: response.promo_conditions,
        exceptions: response.promo_exceptions,
        code: response.promo,
      });
    } else {
      setValidateCoupon("invalidate");
      // setCouponInfo({
      //   error: translate("coupons.alert.error"),
      //   code: response.promo,
      // });
    }
  };

  const handleAddCoupon = async (code: string, yearlyPrice: number) => {
    try {
      await fetchCheckPromo(code, yearlyPrice, addedCoupon);
    } catch (error) { }
  };

  const handleRemoveCoupon = async (code: string) => {
    try {
      setValidateCoupon(undefined);
      setCouponInfo(undefined);
      await couponsRepository.removeCoupon({
        promotionalCode: code,
      });
    } catch (error) { }
  };

  const getTransitionPageData = () => {
    const transitionData = [
      {
        illustration: "Newborn",
        title: translate("transitionPages.upsellPage.title"),
        description: translate("transitionPages.upsellPage.description1")
      },
      {
        illustration: "Graduation",
        title: translate("transitionPages.upsellPage.title"),
        description: translate("transitionPages.upsellPage.description2")
      },
      {
        illustration: "Oldmen",
        title: translate("transitionPages.upsellPage.title"),
        description: translate("transitionPages.upsellPage.description3")
      }
    ]

    return transitionData[Math.floor(Math.random() * transitionData.length)];
  }

  const fetchData = async () => {
    try {
      const data = await leadRepository.getCheckoutData();
      setCheckOut(data);
      return data;
    } catch (error) {
    }
  };

  const fetchProduct = async (checkoutData: DataI | undefined) => {
    try {
      const data: ProductI = await leadRepository.getCheckoutProduct();
      if (data.promotion?.promotionalCode && !couponInfo) {
        if (validateCoupon !== "validate") {
          setValidateCoupon("validate-null");
        }
        setCouponInfo({
          conditions: data.promotion.conditions,
          exceptions: data.promotion.exceptions,
          code: data.promotion.promotionalCode ? data.promotion.promotionalCode : "",
        });
      }
      if (data.campaign && !data.promotion) {
        const promotion: any = prepareDataForCampaign(data.campaign);
        data.promotion = promotion;
      }

      if (data.newCapital) {
        nodeMaxCapitalModal.current?.handleShowPortal();
      }
      data.campaign = "SAVETHECHILDREN";
      setProduct(data);
      const files = await apiGetDownloadableFiles(leadId, translate, data.coverage);
      setDownloadableFiles(files);
      setPurchasePeriod(data.period);
      if (stepPayment === "1") {
        const event = "finalQuoteGenerated";
        const href = window?.location?.href ?? location.pathname;
        const dataInfo = {
          email: checkoutData?.lead?.email,
          phone: checkoutData?.lead?.phone,
          postalCode: checkoutData?.lead?.zipcode,
          gender: checkoutData?.lead?.gender,
          platform: "App",
          page: href,
          leadId: leadId,
          intent: data.intention,
          calculatedCapital: data.capital,
          calculatedPremium: data.finalPrice,
          coverage: data.coverage === "premium" ? "FIPA" : "F",
          utm_source: getCookie("utm_source"),
          utm_campaign: getCookie("utm_campaign"),
          utm_medium: getCookie("utm_medium"),
          utm_content: getCookie("utm_content"),
          utm_term: getCookie("utm_term"),
          utm_test: getCookie("utm_test"),
          gclid: getCookie("gclid"),
          fbclid: getCookie("fbclid"),
          entryPage: getEntryPage(),
          referrerUrl: getReferrerUrl(),
          ABTestVersion: getABTestVersion()
        }
        handleTrackerQuestion({
          type: "FS",
          event,
          data: {
            birthDate: checkoutData?.lead?.birthdate ? new Date(checkoutData.lead.birthdate) : undefined,
            ...dataInfo
          }
        });

        handleTrackerQuestion({
          type: "GA",
          data: {
            event,
            birthDate: checkoutData?.lead?.birthdate ? checkoutData.lead.birthdate : undefined,
            ...dataInfo
          },
        });
      }
    } catch (error) { }
  };

  const fetch = async () => {
    if(isABtesting) {
      setTransitionPageData(getTransitionPageData());
      setTransitionLoading(true);
    } else {
      setLoading(true);
    }
    try {
      const data = await fetchData();

      const fetchProductData = fetchProduct(data);
      const promises = [fetchProductData];
      if(isABtesting) {
        promises.push(new Promise(resolve => setTimeout(resolve, 3000)));
      }
      Promise.all(promises).then(() => {
        firstIn && setFirstIn(false);
        setTransitionLoading(false);
        setLoading(false);
      })
    } catch (error) {
      init("catch_paymet_gateway");
    }
  };

  useEffect(() => {
    setProgressBar(false);
    setCouponInfo(undefined);
    setValidateCoupon(undefined);
    (async () => {
      await fetch();
    })();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    handleHubspot();
  }, [stepPayment]);

  useEffect(() => {
    (async () => {
      if (!firstIn) {
        await fetch();
      }
    })();
  }, [validateCoupon, purchasePeriod]);

  return {
    checkOut,
    couponInfo,
    handleAddCoupon,
    handleConditions,
    handleRemoveCoupon,
    handlePurchasePeriod,
    leadId,
    loading,
    nodeConditionsModal,
    paymentMethod,
    product,
    setPaymentMethod,
    setSetupIntent,
    setCheckOut,
    setProduct,
    setupIntent,
    stepPayment,
    token,
    translate,
    validateCoupon,
    paymentLocation,
    transitionLoading,
    transitionPageData,
    utm_campaign, utm_source, utm_medium, utm_content, utm_term, utm_test, gclid, fbclid,
    downloadableFiles
  };
};

export default PaymentGatewayController;
