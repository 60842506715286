import { ApplyCouponResponse } from "../../../hooks/Promotions/applyPromotion";
import HttpGlobal from "../Global/HttpGlobal.class";
import CouponsRequest, { CouponsRemove } from "./Request/CouponsRequest";
import CouponsCheckResponse from "./Response/CouponsCheck.response";

export default class HttpCouponsRepository extends HttpGlobal {
  private readonly API_URL_PROMO:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/promo`;

  private readonly API_URL_COUPON:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/coupon`;

  downloadConditionPDF = async (): Promise<string> => {
    const response: string = await this.generalGet(
      `${this.API_URL_PROMO}-conditions/download/${this.leadId}`
    );
    return response;
  };

  checkPromotionalCode = async ({
    promotionalCode,
    yearlyPrice,
  }: CouponsRequest): Promise<CouponsCheckResponse> => {
    const response: CouponsCheckResponse = await this.generalPost({
      url: `${this.API_URL_PROMO}/check/${this.leadId}`,
      dataRequest: {
        promotional_code: promotionalCode,
        yearly_price: yearlyPrice,
      },
    });
    return response;
  };

  removeCoupon = async ({ promotionalCode }: CouponsRemove): Promise<void> => {
    await this.generalPost({
      url: `${this.API_URL_PROMO}/remove/${this.leadId}`,
      dataRequest: { promotional_code: promotionalCode },
    });
  };

  applyCoupon = async ({ promotionalCode }: CouponsRemove): Promise<void> => {
    await this.generalPost({
      url: `${this.API_URL_PROMO}/apply/${this.leadId}`,
      dataRequest: { promotional_code: promotionalCode },
    });
  };

  applyCouponOrganic = async ({ promotionalCode }: CouponsRemove) => {
    const response: ApplyCouponResponse = await this.generalPost({
      url: `${this.API_URL_COUPON}/apply`,
      dataRequest: { code: promotionalCode, leadId: this.leadId },
    });
    return response;
  };
}
