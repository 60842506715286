import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import Add from "../../../../components/Components/Add/Add";
import Remove from "../../../../components/Components/Remove/Remove";
import "./nationality.scss";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import { I18nContext } from "../../../../context/i18n.context";
import { ButtonDS, PredictiveSearch, TextInput } from "get-life-storybook-ts";
import { validateIdNumber } from "../../../../utils/validations";
import { GlobalCitizenship, GlobalCountryApi, GlobalMinCharactersIdentityNumber, GlobalProvinceApi } from "../../../../utils/InternationlChanges";
import { DOMAIN } from "../../../../constants/Global";

const Nationality = (props) => {
  const { token, leadId } = props;
  const navigate = useNavigate();

  const [personalData, setPersonalData] = useState(false);
  const [cityItalianList, setCityItalianList] = useState(null);
  const [nationalityList, setNationalityList] = useState(null);
  const [selectedNationality, setSelectedNationality] = useState(null);
  const [selectedNationality2, setSelectedNationality2] = useState(null);
  const [citizenship, setCitizenship] = useState(null);
  const [identityN, setIdentityN] = useState("");
  const [cityOfBirth, setCityOfBirth] = useState("");
  const [showAnotherNationality, setShowAnotherNationality] = useState(false);
  const [error, setError] = useState(false);
  const [reviewButton, setReviewButton] = useState(true);

  const leadRepository = new HttpLeadRepository(leadId, token);

  const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE;

  const showInput = () => setShowAnotherNationality(true);

  const hideInput = () => {
    setShowAnotherNationality(false);
    setSelectedNationality2("");
  };

  const {
    state: { translate },
  } = useContext(I18nContext);

  const submit = () => {
    leadRepository.saveData(personalData).then((res) => {
      navigate("/coverage/1");
    });
  };

  useEffect(() => {

    const get_country = async () => {
      await leadRepository.getQuestionNomenclator(GlobalCountryApi[LOCALE]).then((res) => {
        const { answers } = res;

        setNationalityList(answers);
      });
    }

    const get_italian_cities = async () => {
      await leadRepository.getQuestionNomenclator(GlobalProvinceApi[LOCALE]).then((res) => {
        const { answers } = res;

        setCityItalianList(answers);
      });
    }

    if (nationalityList === null) {
      get_country();
    } else if (cityItalianList === null) {
      get_italian_cities();
    } else if (personalData !== undefined || personalData !== null) {
      setCitizenship(personalData.citizenship);
      setIdentityN(personalData.idNumber || "");
      setSelectedNationality(personalData.nationality);
      setSelectedNationality2(personalData.nationality2);
    }
  }, [personalData, nationalityList, cityItalianList]);


  const isButtonDisabled = async () => {
    const VALIDATEDNI = identityN.length >= GlobalMinCharactersIdentityNumber[LOCALE] && await validateIdNumber(LOCALE, identityN, translate);

    const VALIDATE_CITYBIRTH = DOMAIN === "it" ? cityOfBirth : true;

    if (
      selectedNationality &&
      citizenship && VALIDATEDNI?.error === false &&
      VALIDATE_CITYBIRTH
    ) {
      setReviewButton(false)
    } else {
      setReviewButton(true)
    }

    setError(VALIDATEDNI);
  };

  useEffect(() => {

    leadRepository.getCheckoutData().then((res) => {
      const data = res;
      const { lead } = data;

      const { nationality, nationality2, citizenship, idNumber, cityOfBirth } = lead;

      setPersonalData({
        nationality: nationality,
        nationality2: nationality2,
        citizenship:
          LOCALE === "es" ? citizenship : GlobalCitizenship[LOCALE],
        idNumber: idNumber,
        cityOfBirth: cityOfBirth
      });
    });
  }, []);

  useEffect(() => {
    setPersonalData({
      nationality: selectedNationality,
      nationality2: selectedNationality2,
      citizenship: citizenship,
      idNumber: identityN,
      cityOfBirth: cityOfBirth
    });
  }, [selectedNationality, selectedNationality2, citizenship, identityN, cityOfBirth]);

  useEffect(() => {
    (
      async () => await isButtonDisabled()
    )()
  }, [selectedNationality, citizenship, identityN, cityOfBirth])

  return (
    <>
      {nationalityList !== null && personalData !== false && (
        <div className="nationality-container">
          <div className="nationality-GridContainer">
            <div className="row">
              <PredictiveSearch
                initialValue={selectedNationality}
                onValueChange={setSelectedNationality}
                options={nationalityList}
                placeholder={translate("nationality.nationalityPlaceholder")}
                label={translate("nationality.nationalityContent")}
              />
              {
                DOMAIN === "it" && selectedNationality !== "IT" && (
                  <TextInput
                    initialValue={cityOfBirth}
                    label={translate("nationality.cityOfBirthLabel")}
                    placeholder={translate("nationality.cityOfBirthPlaceholder")}
                    onValueChange={setCityOfBirth}
                    validationChecks={[]}
                  />
                )
              }

              {
                DOMAIN === "it" && selectedNationality === "IT" && (
                  <PredictiveSearch
                    initialValue={cityOfBirth}
                    onValueChange={setCityOfBirth}
                    options={cityItalianList}
                    label={translate("nationality.cityOfBirthLabel")}
                    placeholder={translate("nationality.cityOfBirthPlaceholder")}
                  />
                )
              }
            </div>

            {!showAnotherNationality && DOMAIN !== "fr" && (
              <div className="nationality-add">
                <Add
                  content={translate("nationality.add")}
                  onClick={showInput}
                />
              </div>
            )}

            {(showAnotherNationality === true ||
              (selectedNationality2 !== "" &&
                selectedNationality2 !== null)) && (
                <>
                  <div className="row">
                    <PredictiveSearch
                      initialValue={selectedNationality2}
                      onValueChange={setSelectedNationality2}
                      options={nationalityList}
                      placeholder={translate(
                        "nationality.nationalityPlaceholder"
                      )}
                      label={translate("nationality.nationalityContent2")}
                    />
                  </div>
                  <div className="nationality-add">
                    <Remove
                      content={translate("nationality.remove")}
                      onClick={hideInput}
                    />
                  </div>
                </>
              )}

            <div className="row">
              <PredictiveSearch
                initialValue={citizenship}
                onValueChange={setCitizenship}
                options={nationalityList}
                placeholder={translate("nationality.nationalityPlaceholder")}
                label={translate("nationality.residenceContent")}
                disabled={citizenship !== null && citizenship}
              />
              <TextInput
                initialValue={identityN}
                label={translate("nationality.dniLabel")}
                placeholder={translate("nationality.dniPlaceholder")}
                onValueChange={setIdentityN}
                validationChecks={[(value) => validateIdNumber(LOCALE, value, translate)]}
                initialError={error}
              />
            </div>

            <div className="nationality-buttonContainer">
              <ButtonDS
                label={translate("continuar")}
                onClick={submit}
                disabled={reviewButton}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Nationality;
