/* eslint-disable no-template-curly-in-string */
import {
  ErrorI,
  ErrorResponse,
  isBetween,
  isEmail,
  isExactly,
  isITPhone,
  isLongerThan,
  isMinusCapital,
  isMultipleOf,
  isNotNull,
  isOver,
  isPhone,
  isPlusCapital,
  isPostalCode,
  isUnder,
} from "get-life-storybook-ts/lib/components/Validations";

import HttpLocationRepository from "../api/request/Location/Location.service";
import getUsedCapital from "./GetUsedCapital";
import manualValidations from "./ManualValidations";
import numberFormat from "./Utils";

export type FieldT =
  | "capital"
  | "dateOfBirth"
  | "email"
  | "lastName"
  | "name"
  | "phone"
  | "zipCode"
  | "height"
  | "weight";

interface Validations extends ErrorI<string | number> {
  errorGlobal: ErrorResponse;
  field?: FieldT;
  translate: (key: string) => string;
  checked?: boolean;
}

type disabledDataT = {
  [key in keyof FieldT]: boolean;
};
interface ValidationsGlobal extends Omit<Validations, "errorGlobal"> {
  disabledData?: disabledDataT;
  basic?: boolean;
  setDisabled?: (data: disabledDataT) => void;
  checked?: boolean;
}

const locationRepository = new HttpLocationRepository();

const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE;

export const validationCapital = ({
  value,
  translate,
  min,
  max,
  errorGlobal,
}: Validations) => {
  const errorMinusCapital = isMinusCapital({
    value: value as number,
    message: translate("error.notMinusCapital").replace("$", min!.toString()),
    min: min,
  });
  const errorPlusCapital = isPlusCapital({
    value: value as number,
    message: translate("error.notPlusCapital").replace("$", max!.toString()),
    max: max,
  });
  if(LOCALE === "it") {
    const multiple = 5000;
    const errorNotMultiple = isMultipleOf({
      value: value as number,
      message: translate("error.notMultipleOf").replace("${multiple}", multiple.toString()),
      multiple: multiple
    })
    if (errorNotMultiple.error) return errorNotMultiple;
  }
  if (errorMinusCapital.error) {
    return errorMinusCapital;
  } else if (errorPlusCapital.error) {
    return errorPlusCapital;
  }

  return errorGlobal;
};

const validationDateOfBirth = ({
  value,
  translate,
  min,
  max,
  errorGlobal,
}: Validations) => {
  const errorYoung = isOver({
    value: value as string,
    message: translate("error.isYounger").replace("$", min!.toString()),
    min,
  });
  const errorElder = isUnder({
    value: value as string,
    message: translate("error.isElder").replace("$", (max! + 1).toString()),
    max,
  });

  if (errorYoung.error) {
    return errorYoung;
  } else if (errorElder.error) {
    return errorElder;
  }

  return errorGlobal;
};

const validationZipCode = ({
  value,
  translate,
  exactly,
  errorGlobal,
}: Validations) => {
  const errorExactly = isExactly({
    value,
    message: translate("error.notExactly").replace("$", exactly!.toString()),
    exactly,
  });
  const errorPostalCode = isPostalCode({
    value: value as string,
    message: translate("questions.zipcode.error"),
  });

  if (errorExactly.error) {
    errorGlobal = errorExactly;
  } else {
    errorGlobal =
      (LOCALE === "es" || LOCALE === "pt" || LOCALE === "it")
        ? errorPostalCode
        : errorGlobal;
  }

  return errorGlobal;
};

export const validatePhoneFR = async ({
  value,
  translate,
  errorGlobal,
}: Validations) => {
  let response: ErrorResponse = { error: false, message: "" };
  try {
    const res: any = await locationRepository.getValidatePhoneNumber(
      value as string
    );
    if (!res.isValidPhoneFR) {
      response = {
        error: true,
        message: translate("error.notPhone"),
      };
    }
  } catch (error) {
    response = {
      error: true,
      message: translate("error.connexion"),
    };
  }
  errorGlobal = response;
  return errorGlobal;
};

export const validateZipCodeFR = async ({
  value,
  translate,
  errorGlobal,
}: Validations) => {
  let response: ErrorResponse = { error: false, message: "" };
  try {
    const res: any = await locationRepository.getValidateZipCode(
      value as string
    );
    if (!res.isValidPostalCodeFR) {
      response = {
        error: true,
        message: translate("error.notZipCode"),
      };
    }
  } catch (error) {
    response = {
      error: true,
      message: translate("error.connexion"),
    };
  }
  errorGlobal = response;
  return errorGlobal;
};

export const validateIdNumber = async (
  market: string,
  dni: string,
  translate: (key: string) => string
) => {

  const VALIDATE = { error: false, message: "" };
  const NOT_VALIDATE = { error: true, message: translate("error.idNumber") };

  if (market === "fr") {
    return VALIDATE;
  }

  if (market === "es") {
    var number: any, lett, letter;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
      number = dni.substr(0, dni.length - 1);
      number = number.replace("X", 0);
      number = number.replace("Y", 1);
      number = number.replace("Z", 2);
      lett = dni.substr(dni.length - 1, 1);
      number = number % 23;
      letter = "TRWAGMYFPDXBNJZSQVHLCKET";
      letter = letter.substring(number, number + 1);
      if (letter != lett || dni.length != 9) {
        return NOT_VALIDATE;
      } else {
        return VALIDATE;
      }
    } else {
      return NOT_VALIDATE;
    }
  }

  if (market === "it") {
    let formatCodice = dni?.replace(/\s/g, '');
    if (dni) {
      try {
        const res: any = await locationRepository.getValidateCodiceFiscale(formatCodice)
        if (res.status) {
          return VALIDATE;
        } else {
          return NOT_VALIDATE;
        }
      } catch (error) {
        return NOT_VALIDATE;
      }
    }
  }
};

const validations = ({
  value,
  translate,
  min,
  max,
  errorGlobal,
  exactly,
  field,
  checked
}: Validations) => {
  const validations = {
    capital: () =>
      validationCapital({
        value,
        translate,
        min,
        max,
        errorGlobal,
      }),
    dateOfBirth: () =>
      validationDateOfBirth({
        value,
        translate,
        min,
        max,
        errorGlobal,
      }),
    email: () =>
      isEmail({ value: value as string, message: translate("error.notEmail") }),
    lastName: () =>
      isLongerThan({
        value,
        message: translate("error.notLonger").replace("$", min!.toString()),
        min,
      }),
    name: () =>
      isLongerThan({
        value,
        message: translate("error.notLonger").replace("$", min!.toString()),
        min,
      }),
    phone: () => {
      if (LOCALE === "it") return isITPhone({ value: value as string, message: translate("error.notPhone") });
      return isPhone({ value: value as string, message: translate("error.notPhone") })
    },
    zipCode: () =>
      validationZipCode({ value, translate, exactly, errorGlobal }),
    weight: () =>
      isBetween({
        value,
        message: translate("error.input.weight")
          .replace("$", min!.toString())
          .replace("&", max!.toString()),
        min,
        max,
      }),
    height: () =>
      isBetween({
        value,
        message: translate("error.input.height")
          .replace("$", min!.toString())
          .replace("&", max!.toString()),
        min,
        max,
      }),
    number: () => isLongerThan({
      value,
      message: translate("error.notLonger").replace("$", min!.toString()),
      min,
    }),
  };

  return !!field ? validations[field!]() : errorGlobal;
};

export const validationsGlobal = ({
  disabledData,
  basic = false,
  checked,
  exactly,
  field,
  max,
  min,
  setDisabled,
  translate,
  value,
}: ValidationsGlobal) => {
  let errorGlobal: ErrorResponse = { error: false, message: "" };
  const errorNull = isNotNull({ value, message: translate("error.isNull") });

  if (!errorNull.error && !basic) {
    errorGlobal = validations({
      value,
      translate,
      min,
      max,
      exactly,
      errorGlobal,
      field,
      checked
    });
  } else {
    errorGlobal = field === "dateOfBirth" ? errorGlobal : errorNull;
  }
  if (setDisabled && disabledData) {
    setDisabled({
      ...disabledData,
      [field!]: errorGlobal.error,
    });
  }

  return errorGlobal;
};

type PhysicalPersonKeys =
  | "capital"
  | "idNumber"
  | "lastName2"
  | "lastName"
  | "name";

type LegalPersonKeys = "businessName" | "capital" | "fiscalNumber";

type BankEntityKeys = "bankName" | "capital" | "loanMortgageNumber";

type BeneficiariesKeyT =
  | PhysicalPersonKeys[]
  | LegalPersonKeys[]
  | BankEntityKeys[];

interface BeneficiariesValidationI {
  beneficiaries: any[];
  availableCapital: number;
  validations?: any;
  translate: (key: string) => string;
  setError: (data: any) => void;
  setErrorMessage: (data: any) => void;
}

export const submitValidationError = ({
  beneficiaries,
  availableCapital,
  validations,
  translate,
  setError,
  setErrorMessage,
}: BeneficiariesValidationI) => {
  const validationErrors: any = {};
  const validationMessages: any = {};
  let overCapital: boolean;
  const usedCapital: { total: number; capitalValues: Array<number> } =
    getUsedCapital(document.querySelectorAll("input"));
  overCapital = usedCapital.total > availableCapital ? true : false;
  beneficiaries.forEach((beneficiary, index: number) => {
    if ((LOCALE === "es" || LOCALE === "pt" || LOCALE === "it")) {
      (Object.keys(beneficiary) as BeneficiariesKeyT).forEach(
        (key, indexKey) => {
          const errorValidation = manualValidations(
            validations[indexKey],
            beneficiary[key]
          );

          validationErrors[`${key}${index}`] = errorValidation.error;
          validationMessages[`${key}${index}`] = errorValidation.errorMessage;
        }
      );
    } else if(LOCALE === "fr") {
      Object.keys(beneficiary as BeneficiariesKeyT).forEach((key) => {
        const errorValidation = manualValidations(
          validations.find((v: {name: string}) => v.name === key),
          beneficiary[key]
        );

        validationErrors[`${key}${index}`] = errorValidation.error;
        validationMessages[`${key}${index}`] = errorValidation.errorMessage;
      });
    }
    if (overCapital) {
      let total = 0;
      usedCapital.capitalValues.forEach((capital, index) => {
        if (capital > availableCapital - total) {
          validationErrors["capital" + index] = true;
          validationMessages["capital" + index] =
            translate("beneficiaries.capital.error") +
            numberFormat(availableCapital - total, "capital");
        } else {
          total += capital;
        }
      });
    }
  });

  setError(validationErrors);
  setErrorMessage(validationMessages);
  let failed =
    Object.values(validationErrors).find((element) => element === true) === true
      ? true
      : false;
  return failed;
};
