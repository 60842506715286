import { GlobalIcon, Typography } from "get-life-storybook-ts";
import {
  isEmail,
  isNotNull,
  isPhone,
} from "get-life-storybook-ts/lib/components/Validations";
import { createRef } from "react";
import { convertNumberToWords } from "../../utils/Utils";

export const buttonForm: any = (translate: (key: string) => string) => ({
  label: translate("rastreator.form.button.label"),
  buttonType: "primary",
  icon: <GlobalIcon iconName="ArrowGoRightIcon" />,
  iconPosition: "right",
});

export const checkBoxQuery = (translate: (key: string) => string) => (
  <>
    <div className="flex flex-col">
      <Typography
        variant="BodyMedium"
        text={translate("rastreator.form.conditions.text")}
        color="ligth-gray"
      />
      <Typography
        variant="LinkMedium"
        text={translate("rastreator.form.conditions.link")}
        color=""
        target="_blank"
        href="https://life5.es/politica-privacidad/"
      />
    </div>
  </>
);

export const checkBoxQuery2 = (translate: (key: string) => string) => (
  <>
    <div className="flex flex-col">
    <Typography
      variant="BodyMedium"
      text=""
      className="text-body-md"
      color="ligth-gray"
    >
      <span
        dangerouslySetInnerHTML={{ __html: translate("calculatorPage.acceptDataProcessing2") as string }}
      />

    </Typography>
    </div>
  </>
);

export const dataTable = (translate: (key: string) => string) => [
  {
    beneficiary: (
      <Typography
        text={translate("rastreator.table.firstRow")}
        variant="Body"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
  {
    beneficiary: (
      <Typography
        text={translate("rastreator.table.secondRow")}
        variant="Body"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
  {
    beneficiary: (
      <Typography
        text={translate("rastreator.table.thirdRow")}
        variant="Body"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
  {
    beneficiary: (
      <Typography
        text={translate("rastreator.table.fourthRow")}
        variant="Body"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
  {
    beneficiary: (
      <Typography
        text={translate("rastreator.table.fifthRow")}
        variant="Body"
      />
    ),
    getlife: <GlobalIcon iconName="FilledCheckIcon" />,
    others: <GlobalIcon iconName="CancelIcon" />,
  },
];

export const headerTable = (translate: (key: string) => string) => [
  {
    header: (
      <Typography
        text={translate("rastreator.table.firstHeader")}
        variant="H3"
        weight="700"
        color="ligth-gray"
      />
    ),
    ref: "beneficiary",
  },
  {
    header: <GlobalIcon iconName="LogoLife5Blue" size="M" />,
    ref: "getlife",
    styles: "bg-[#ECF0FF]",
  },
  {
    header: (
      <Typography
        text={translate("rastreator.table.secondHeader")}
        variant="H3"
        weight="700"
        color="ligth-gray"
      />
    ),
    ref: "others",
  },
];

const LIST_INFO_COVER = (translate: (key: string) => string) => {
  return Array.from({ length: 8 }, (_, v) =>
    translate(`rastreator.listInfoCover.${convertNumberToWords(v + 1)}`)
  );
};

const LIST_INFO_NOT_COVER = (translate: (key: string) => string) => {
  return Array.from({ length: 2 }, (_, v) =>
    translate(`rastreator.listInfoNotCover.${convertNumberToWords(v + 1)}`)
  );
};

export const accordionData = ({ translate, capital, coverage }: any) =>
  [
    {
      headerText: translate("rastreator.boxInfo.labelOne"),
      bodyContent: `${capital} €`,
      icon: "SaveMoneyIcon",
      expanded: true,
      hasInfoIcon: true,
      hasSectionIcon: true,
      hasGlobalIcon: true,
    },
    {
      headerText: translate("rastreator.boxInfo.labelTwo"),
      bodyContent: translate(`rastreator.boxInfo.${coverage}`),
      icon: "UmbrellaIcon",
      expanded: true,
      hasInfoIcon: true,
      hasSectionIcon: true,
      hasGlobalIcon: true,
    },
    {
      headerText: translate("rastreator.boxInfo.labelThree"),
      listContent: LIST_INFO_COVER(translate),
      icon: "SimpleShieldIcon",
      expanded: false,
      isListType: true,
      isClickable: true,
      hasDisplayItemIcon: true,
      hasSectionIcon: true,
      hasGlobalIcon: true,
    },
    {
      headerText: translate("rastreator.boxInfo.labelFour"),
      listContent: LIST_INFO_NOT_COVER(translate),
      icon: "NotCoverIcon",
      expanded: false,
      isListType: true,
      isClickable: true,
      hasDisplayItemIcon: true,
      hasSectionIcon: true,
      hasGlobalIcon: true,
    },
  ] as any;

export const faqAccordionData = (translate: any) =>
  [
    {
      headerText: translate("rastreator.FAQ.first.title"),
      bodyContent: translate("rastreator.FAQ.first.description"),
      expanded: true,
      hasDisplayItemIcon: true,
      isClickable: true,
    },
    {
      headerText: translate("rastreator.FAQ.second.title"),
      bodyContent: translate("rastreator.FAQ.second.description"),
      expanded: false,
      hasDisplayItemIcon: true,
      isClickable: true,
    },
    {
      headerText: translate("rastreator.FAQ.third.title"),
      bodyContent: translate("rastreator.FAQ.third.description"),
      expanded: false,
      hasDisplayItemIcon: true,
      isClickable: true,
    },
  ] as any;

export const translations = (translate: (key: string) => string) => ({
  capitalModal: {
    bodyDescriptionText: translate(
      "rastreator.boxInfo.modals.capital.description"
    ),
    titleText: translate("rastreator.boxInfo.modals.capital.title"),
  },
  coverModal: {
    deathProtection: {
      titleText: translate("rastreator.boxInfo.modals.coverage.death.title"),
      descriptionText: translate(
        "rastreator.boxInfo.modals.coverage.death.description"
      ),
    },
    ipaProtection: {
      titleText: translate("rastreator.boxInfo.modals.coverage.ipa.title"),
      descriptionText: translate(
        "rastreator.boxInfo.modals.coverage.ipa.description"
      ),
    },
    titleText: translate("rastreator.boxInfo.modals.coverage.title"),
    bodyDescriptionText: translate(
      "rastreator.boxInfo.modals.coverage.description"
    ),
  },
});

export const arrayForm: any = (translate: (key: string) => string) => [
  {
    inputType: "input",
    placeholder: translate("rastreator.form.email.placeholder"),
    label: translate("rastreator.form.email.label"),
    field: "email",
    validation: (value: string) =>
      isEmail({ value, message: translate("rastreator.form.error.email") }),
    ref: createRef(),
  },
  {
    inputType: "input",
    placeholder: translate("rastreator.form.phone.placeholder"),
    label: translate("rastreator.form.phone.label"),
    field: "phone",
    inputMode: "number",
    validation: (value: string) =>
      isPhone({ value, message: translate("rastreator.form.error.phone") }),
    ref: createRef(),
  },
  {
    inputType: "checkboxSimple",
    label: checkBoxQuery(translate),
    isLegal: true,
    validation: (value: any) =>
      isNotNull({
        value,
        message: translate("rastreator.form.error.conditions"),
      }),
    field: "conditions",
    ref: createRef(),
    className: "!p-0"
  },
  {
    inputType: "checkboxSimple",
    label: checkBoxQuery2(translate),
    isLegal: true,
    validation: (value: any) =>
      isNotNull({
        value,
        message: translate("rastreator.form.error.conditions"),
      }),
    field: "conditions2",
    ref: createRef(),
    className: "!p-0"
  }
];

export const infoItems = (translate: (key: string) => string) => [
  {
    icon: "HumourIcon",
    title: translate("rastreator.info.title.first"),
    description: translate("rastreator.info.description.first"),
  },
  {
    icon: "PriceIcon",
    title: translate("rastreator.info.title.second"),
    description: translate("rastreator.info.description.second"),
  },
  {
    icon: "SaveMoneyIcon",
    title: translate("rastreator.info.title.third"),
    description: translate("rastreator.info.description.third"),
  },
  {
    icon: "GraveIcon",
    title: translate("rastreator.info.title.fourth"),
    description: translate("rastreator.info.description.fourth"),
  },
];

export const DEATH_PROTECTION_PROPS = {
  iconName: "CandleIcon",
  fontFamily: "DM",
  titleVariant: "H3",
  titleWeight: "700",
  descriptionVariant: "Body",
  descriptionWeight: "400",
  titleColor: "purple",
  iconStrokeWidth: "1.5",
};

export const IPA_PROTECTION_PROPS = {
  iconName: "WheelChairIcon",
  fontFamily: "DM",
  titleVariant: "H3",
  titleWeight: "700",
  descriptionVariant: "Body",
  descriptionWeight: "400",
  titleColor: "purple",
  iconStrokeWidth: "1.5",
} as any; //TODO - add correct type on storybook

export const CAPITAL_MODAL_STYLES = {
  bodyIconName: "CalculatorIcon",
  bodyDescriptionVariant: "Body",
  iconStrokeWidth: "1.5",
  deathProtectionProps: DEATH_PROTECTION_PROPS,
  ipaProtectionProps: IPA_PROTECTION_PROPS,
} as any; //TODO - add correct type on storybook

export const COVER_MODAL_STYLES = {
  bodyIconName: "UmbrellaIcon",
  bodyDescriptionVariant: "Body",
  deathProtectionProps: DEATH_PROTECTION_PROPS,
  ipaProtectionProps: IPA_PROTECTION_PROPS,
  iconStrokeWidth: "1.5",
} as any; //TODO - add correct type on storybook
