import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import InLine from "./../../../../assets/img/conectado.png";
import Exit from "./../../../../assets/img/arrowSalir.png";
import GlobalPosition from "./components/GlobalPosition";
import Profile from "./components/Profile";

import Asesor from "./components/Asesor";
import Header from "./components/Header";
import PopUpAsesor from "./components/PopUpAsesor";
import "./css/dashboard.scss";
import { handleHubspot } from "../../../../utils/HandleHubspot";
import { GetlifeContext } from "../../../../context/GetLifeContext.context";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import { I18nContext } from "../../../../context/i18n.context";
import Policy from "./Policy/Policy.component";
import { GlobalUrlFaqs } from "../../../../utils/InternationlChanges";
import { StatusContext } from "../../../../context/StatusContext";
import { ButtonDS } from "get-life-storybook-ts";

export default function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [option, setOption] = useState(0);
  const [displayMenu, setDisplayMenu] = useState(false);
  const [product, setProduct] = useState(false);
  const navigate = useNavigate();
  const { leadId, setToken, setLeadId, token, setUserStatus } =
    useContext(GetlifeContext);
    const {getStatus} = useContext(StatusContext);

  const leadRepository = new HttpLeadRepository(leadId, token);
  const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE;

  const {
    state: { translate },
  } = useContext(I18nContext);

  const toMap = [
    translate("dashboard.menu.global"),
    translate("dashboard.menu.profile"),
    translate("dashboard.menu.policy"),
    translate("dashboard.menu.asesor"),
    "logout",
  ];

  const handleAsesor = () => {
    setOption(3);
  };

  useEffect(() => {
    getStatus();
    leadRepository.getCheckoutData().then((res) => {
      const data = res;
      if (data.code === 401) {
        navigate("/login");
      } else {
        setUserData([res]);
      }
    });
    leadRepository.getCheckoutProduct().then((res) => {
      const data = res;
      setProduct(data);
    });
    handleHubspot();
  }, [option]);

  useEffect(() => {
    setDisplayMenu(false);
  }, [option]);

  const logout = () => {
    setToken("");
    setLeadId("");
    setUserStatus("");
  };

  const Faqs = GlobalUrlFaqs[LOCALE];

  return (
    <div
      className="dashboard-container"
      style={{
        backgroundColor: displayMenu === true ? "rgba(65, 65, 65, 0.6)" : null,
      }}
    >
      {userData !== null && (
        <div className="dasboardMenu-container">
          <div className="dashboardInLine-container">
            <p>{userData[0].lead.name + " " + userData[0].lead.lastName}</p>
            <img src={InLine} alt="Conectado" />
          </div>
          <p style={{ paddingLeft: "39px" }}>{translate("dashboard.online")}</p>
          <div className="dashboardMenuOption-container">
            {toMap.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className="dashboard-option"
                    key={index}
                    style={{
                      backgroundColor: index === option ? "#f9f9f9" : null,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (item === "logout") {
                        logout();
                      } else {
                        setOption(index);
                      }
                    }}
                  >
                    {item === "logout" && (
                      <img
                        style={{
                          width: "12px",
                          height: "10px",
                          paddingLeft: "39px",
                        }}
                        src={Exit}
                        alt={translate("dashboard.menu.logout")}
                      />
                    )}

                    <p
                      style={{
                        paddingLeft: item === "logout" ? "0px" : null,
                      }}
                    >
                      {item === "logout"
                        ? translate("dashboard.menu.logout")
                        : item}
                    </p>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
      <div className="dashboardContent-container">
        <Header
          handleMenu={() => setDisplayMenu(!displayMenu)}
          displayMenu={displayMenu}
        />
        <div className="dashboardContent-header">
          <ButtonDS
            buttonType="secondary"
            label="FAQs"
            onClick={() => window.open(Faqs, "_blank")}
          />
        </div>

        {displayMenu && (
          <div className="dasboardMenuMobile-container">
            <div className="dashboardInLineMobile-container">
              <p>{userData[0].lead.name + " " + userData[0].lead.lastName}</p>
              <img src={InLine} alt="Conectado" />
            </div>
            <p style={{ paddingLeft: "39px" }}>
              {translate("dashboard.online")}
            </p>
            <div className="dashboardMenuOptionMobile-container">
              {toMap.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="dashboardMobile-option"
                      key={index}
                      style={{
                        backgroundColor: index === option ? "#f9f9f9" : null,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (item === "logout") {
                          logout();
                        } else {
                          setOption(index);
                        }
                      }}
                    >
                      {item === "logout" && (
                        <img
                          style={{
                            width: "12px",
                            height: "10px",
                            paddingLeft: "39px",
                          }}
                          src={Exit}
                          alt={translate("dashboard.menu.logout")}
                        />
                      )}
                      <p
                        style={{
                          paddingLeft: item === "logout" ? "7px" : null,
                        }}
                      >
                        {item === "logout"
                          ? translate("dashboard.menu.logout")
                          : item}
                      </p>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}

        {option === 0 && userData !== null && (
          <GlobalPosition
            handleAsesor={handleAsesor}
            userData={userData}
            product={product}
          />
        )}
        {option === 1 && userData !== null && (
          <Profile userData={userData} token={token} leadId={leadId} />
        )}

        {option === 2 && userData !== null && (
          <Policy
            beneficiaries={
              userData[0].lead.beneficiaries === "other"
                ? "Otros"
                : userData[0].lead.beneficiaries
            }
            beneficiariesList={product.beneficiariesList}
            product={product}
            userData={userData}
            handleAsesor={handleAsesor}
          />
        )}
        {option === 3 && userData !== null && <Asesor userData={userData} />}
        {option === 4 && userData !== null && (
          <PopUpAsesor userData={userData} />
        )}
      </div>
    </div>
  );
}
