import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/img/getlife-logo/getlife-azul.svg";
import "./css/loginPage.scss";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { I18nContext } from "../../context/i18n.context";
import {
  LOGIN_STATUS_SIGNATUREPENDING,
  LOGIN_STATUS_COMPLETE,
  LOGIN_STATUS_OPS_REVIEW,
  LOGIN_STATUS_IT_REVIEW,
  LOGIN_STATUS_INSURER_REVIEW,
} from "../../utils/staticData";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { redirect } from "../../Routes/redirect";
import { Button, ButtonDS, TextInput } from "get-life-storybook-ts";
import { GetLifeTrackerContext } from "../../context/GetLifeTracker.context";

export default function LoginPage() {
  const [userEmail, setUserEmail] = useState("");

  const [userPassword, setUserPassword] = useState("");

  const [error, setError] = useState(false);

  const {
    setLeadId,
    setOtp,
    setToken,
    setUserStatus,
    gtf_campaign,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_content,
    utm_term,
    utm_test,
    gclid,
    fbclid,
    tid,
    getEntryPage,
    getReferrerUrl,
  } = useContext(GetlifeContext);

  const { handleTrackerQuestion, identifyFS } = React.useContext(
    GetLifeTrackerContext
  );

  const leadRepository = new HttpLeadRepository();

  const { otp } = useParams();

  const navigate = useNavigate();

  const userStatusProvided = (userStatus) => {
    if (userStatus) {
      if (
        userStatus !== LOGIN_STATUS_SIGNATUREPENDING &&
        userStatus !== LOGIN_STATUS_COMPLETE &&
        userStatus !== LOGIN_STATUS_OPS_REVIEW &&
        userStatus !== LOGIN_STATUS_IT_REVIEW &&
        userStatus !== LOGIN_STATUS_INSURER_REVIEW
      ) {
        navigate("/decision");
      } else {
        navigate(redirect(userStatus));
      }
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setError(false);
    const data = {
      username: userEmail,
      password: userPassword,
    };
    leadRepository.login(data).then((response) => {
      if (response === "RQ not valid" || response.code === 401) {
        setError(true);
      } else {
        const { token, id, status, email, brokerId, redirectUrl } = response;
        //esto primero importante!
        setUserStatus(status);
        setToken(token || "");
        setLeadId(id || "");
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          userStatusProvided(status);
        }

        handleTrackerQuestion({
          type: "tracker",
          data: {
            leadUlid: id,
            campaign: gtf_campaign || "login-app",
          },
        });

        identifyFS({
          lead: id,
          data: {
            emailDomain: email && email.split("@")[1],
            email: email,
            brokerId: brokerId,
          },
        });
      }
    });
  };

  const handleClickPassword = () => {
    navigate("/reset");
  };

  useEffect(() => {
    if (otp !== undefined && otp !== null) {
      setOtp(otp);
    }
    setToken("");
    setUserStatus("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmail = (e) => setUserEmail(e);

  const handlePassword = (e) => setUserPassword(e);

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  return (
    <div className="loginPageSignin">
      <main className="loginPage--main">
        <img
          src={logo}
          alt="Logo de Getlife"
          title="Logo de Getlife"
          className="callMeBack--main__img"
        />
        <h6 className="loginPage--main__title">
          {translate("loginPage.title")}
        </h6>
        <form className="loginPage--main__form">
          <TextInput
            placeholder="E-mail"
            initialValue={userEmail}
            onValueChange={handleEmail}
          />
          <TextInput
            placeholder={translate("loginPage.placeholderPass")}
            initialValue={userPassword}
            onValueChange={handlePassword}
            type="password"
          />
          {error && (
            <span className="error-message">
              {translate("loginPage.errorMessage")}
            </span>
          )}
          <div className="form--btn">
            <ButtonDS
              label={translate("continuar")}
              disabled={
                userEmail === "" ? true : userPassword === "" ? true : false
              }
              onClick={handleSubmit}
            />
            <ButtonDS
              buttonType="secondary"
              label={translate("loginPage.recover")}
              onClick={() => handleClickPassword()}
              className="text-[14px] mt-1"
            />
          </div>
        </form>
      </main>
    </div>
  );
}
