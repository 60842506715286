import { useContext, useEffect, useRef, useState } from "react";
import { loadStripe, StripeConstructorOptions } from "@stripe/stripe-js";
import { GetlifeContext, LanguageT } from "../../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../../context/i18n.context";
import numberFormat from "../../../../../utils/Utils";
import HttpPaymentRepository from "../../../../../api/request/Payment/Payment.service";
import { ProductI } from "../../../../../api/request/Lead/Interfaces/ProductInterface.response";
import {
  AvailablePaymentMethodI,
  DataCardI,
} from "../../../../../api/request/Payment/Interfaces/PaymentMethodI.response";
import HttpLeadRepository from "../../../../../api/request/Lead/Lead.service";
import { GlobalTypeFormPolicy } from "../../../../../utils/InternationlChanges";

interface PolicyControllerI {
  product: ProductI;
}

interface DocumentsI {
  contractUrl: string;
  certificateUrl: string;
}

const PolicyController = ({ product }: PolicyControllerI) => {
  const { capital } = product;
  const { leadId, token } = useContext(GetlifeContext);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const nodeElementModal = useRef<any>(null);
  const withdrawalModalRef = useRef<any>(null);
  const capitalFormatted = numberFormat(capital, "capital");
  const [availablePaymentMethod, setAvailablePaymentMethod] =
    useState<AvailablePaymentMethodI | null>(null);
  const [setupIntent, setSetupIntent] = useState<any>();
  const [documents, setDocument] = useState<DocumentsI | null>(null);
  const [dataCard, setDataCard] = useState<DataCardI | null>(null);
  const [noSubscription, setNoSubscription] = useState<boolean>(true)

  const paymentRepository = new HttpPaymentRepository(leadId, token);
  const leadRepository = new HttpLeadRepository(leadId, token);
  const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE!;

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
    {
      locale:
        (process.env
          .REACT_APP_DOMAIN_LOCALE as StripeConstructorOptions["locale"]) ||
        "es",
      stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
    }
  );

  const fetchAvailablePaymentMethod =
    async (): Promise<AvailablePaymentMethodI | null> => {
      let result: AvailablePaymentMethodI | null = null;
      try {
        const response: AvailablePaymentMethodI =
          await paymentRepository.availablePaymentMethod();
        result = response;
      } catch (error) {
        console.log("error", error);
      }
      return result;
    };

  const fetchSetup = async () => {
    let result;
    try {
      const response = await paymentRepository.setup();
      result = response;
    } catch (error) {}
    return result;
  };

  const fetchGetDataCard = async (): Promise<void> => {
    let result: DataCardI | null = null;
    try {
      const response: DataCardI = await paymentRepository.getDataCard();
      result = response;
      setNoSubscription(false)
    } catch (error) {
      setNoSubscription(true)
    }
    setDataCard(result);
  };

  const fetchDocuments = async () => {
    let result: DocumentsI | null = null;
    try {
      const response = await leadRepository.getDocuments();
      result = response;
    } catch (error) {}
    return result;
  };

  useEffect(() => {
    (async () => {
      setDocument(await fetchDocuments());
      setSetupIntent(await fetchSetup());
      await fetchGetDataCard();
      setAvailablePaymentMethod(await fetchAvailablePaymentMethod());
    })();
  }, []);

  const modify = () => window.open(GlobalTypeFormPolicy[LOCALE  as LanguageT], "_blank");

  const showTextPaymentMehtod = () => {
    const message = dataCard ? translate(dataCard.type) : "";
    const digits = dataCard ? dataCard.lastFourDigits : "";
    return translate("dashboard.policy.paymentMethodActual")
      .replace("#", message)
      .replace("$", digits);
  };

  const translateBeneficiaries = (type: string) => {
    if (type === "Orden de Prelación") {
      return translate("questions.beneficiaries.orderOfPrecedence");
    } else if (type === "Herederos Legales") {
      return translate("questions.beneficiaries.legalHeirs")
    } else {
      return translate(`dashboard.policy.Otros`)
    }
  }

  return {
    availablePaymentMethod,
    clientSecret: setupIntent?.clientSecret,
    capitalFormatted,
    dataCard,
    documents,
    fetchGetDataCard,
    modify,
    nodeElementModal,
    withdrawalModalRef,
    noSubscription,
    showTextPaymentMehtod,
    stripePromise,
    translate,
    translateBeneficiaries
  };
};

export default PolicyController;
