import "../../css/personalData.scss";
import { Button, ButtonDS, Input } from "get-life-storybook-ts";
import PersonalDataController from "./PersonalData.controller";

const PersonalData = ({ userData, saveData }: any) => {
  const {
    error,
    lastName,
    lastName2,
    name,
    phone,
    sendPersonalData,
    setLastName,
    setLastName2,
    setName,
    setPhone,
    translate,
    validations,
  } = PersonalDataController({ userData, saveData });
  return (
    <div className="personalData1-container">
      <div className="personalData1__form">
        <div className="personalData1__form__input">
          <Input
            initialValue={name}
            initialError={error.name}
            label={translate("payment.personalData.name")}
            onValueChange={setName}
            placeholder={translate("payment.personalData.namePlaceHolder")}
            validationChecks={validations.name}
          />
        </div>
        <div className="personalData1__form__input">
          <Input
            initialValue={lastName}
            initialError={error.lastName}
            label={translate("payment.personalData.surname")}
            onValueChange={setLastName}
            placeholder={translate("payment.personalData.surnamePlaceHolder")}
            validationChecks={validations.lastName}
          />
        </div>
        {process.env.REACT_APP_DOMAIN_LOCALE === "es" && (
          <div className="personalData1__form__input">
            <Input
              initialValue={lastName2}
              label={translate("payment.personalData.lastname")}
              onValueChange={setLastName2}
              placeholder="Opcional"
            />
          </div>
        )}
        <div className="personalData1__form__input">
          <Input
            label={translate("payment.personalData.phone")}
            onValueChange={setPhone}
            placeholder={translate("payment.personalData.phonePlaceHolder")}
            initialValue={phone}
            initialError={error.phone}
            validationChecks={validations.phone}
            inputMode="numeric"
          />
        </div>

        <div className="personalData1__form__submit">
          <ButtonDS
            label={translate("continuar")}
            onClick={() => sendPersonalData()}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalData;
