import HttpGlobal from "../Global/HttpGlobal.class";

export default class HttpIntegrationsRepository extends HttpGlobal {
  private readonly API_URL_TOKEN_VELOGICA:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/velogica/login`;

    private readonly API_URL_RESULT_VELOGICA:
    | string
    | undefined = `${process.env.REACT_APP_CUSTOMER_APP_URL}api/velogica/result?parameters=`;



  getToken = async (leadId: string) => {
    const response = await this.generalGet(`${this.API_URL_TOKEN_VELOGICA}/${leadId}`);

    return response;

  };

  getResult = async (token: string) => {
    const response = await this.generalGet(`${this.API_URL_RESULT_VELOGICA}${token}`);

    return response;

  };
}