import { ButtonDS, Modal } from "get-life-storybook-ts";
import { MutableRefObject } from "react";
import ModalWithdrawalController from "./ModalWithdrawal.controller";

interface WithdrawalModalProps {
  withdrawalModalRef: MutableRefObject<any>;
  userData: any;
  onTalkAgent: () => null;
  signatureDate: string;
}

const WithdrawalModal = ({
  withdrawalModalRef,
  userData,
  onTalkAgent,
  signatureDate,
}: WithdrawalModalProps) => {
  const {
    translate,
    isWithdrawalPeriod,
    isCancellationPeriod,
    displayingAdvice,
    toggleDisplayingAdvice,
    modalTitle,
    modalBody
  } = ModalWithdrawalController(userData, signatureDate);

  return (
    <Modal
      ref={withdrawalModalRef}
      ModalTitle={<h3 className="dashboard__modal--title">{modalTitle}</h3>}
      ModalBody={<div>{modalBody}</div>}
      classNameContent="dashboard__modal"
      ModalActions={
        <div className="dashboard__modal--actions">
          {(isWithdrawalPeriod || isCancellationPeriod) && !displayingAdvice ? (
            <ButtonDS
              label={translate(
                "dashboard.policy.cancellation.cancelSubscription"
              )}
              onClick={toggleDisplayingAdvice}
              buttonType="secondary"
            />
          ) : null}
          <ButtonDS
            label={translate("dashboard.policy.cancellation.talkAgent")}
            onClick={onTalkAgent}
          />
        </div>
      }
    />
  );
};

export default WithdrawalModal;
