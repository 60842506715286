import { capitalize } from "./Utils";

const prepareDataFromDataLayerUpsell = (checkOut, product, leadId) => {
  let data = {
    currencyCode: "EUR",
    impressions: [],
  };

  data.impressions[0] = {
    name: `Getlife ES ${product.intention}`,
    id: "",
    price: parseInt(product.finalPrice),
    brand: checkOut.lead.carrier === "AXA" ? "c1" : "c2",
    category: product.coverage,
    variant: product.promotion ? "Rastreator Discount" : "",
    list: "Upsell",
    position: 1,
    language: "es",
    calculatedCapital: parseInt(product.capital),
    leadId: leadId,
  };

  product.iterations.forEach((iteration, index) => {
    let dataIteration = {
      name: `Getlife ES ${product.intention}`,
      id: "",
      price: iteration.finalPrice,
      brand: checkOut.lead.carrier === "AXA" ? "c1" : "c2",
      category: iteration.coverage,
      variant: iteration.promotion ? "Rastreator Discount" : "",
      list: "Upsell",
      position: index + 2,
      language: "es",
      calculatedCapital: parseInt(iteration.capital),
      leadId: leadId,
    };

    data.impressions.push(dataIteration);
  });

  return data;
};

const prepareDataFromDataLayerUpsellClick = (checkOut, product, index) => {
  let data = {
    click: {
      actionField: { list: "Upsell" },
      products: [
        {
          name: `Getlife ES ${product.intention}`,
          id: "",
          price: product.finalPrice,
          brand: checkOut.lead.carrier === "AXA" ? "c1" : "c2",
          category: product.coverage,
          variant: product.promotion ? "Rastreator Discount" : "",
          position: index,
        },
      ],
    },
  };

  return data;
};

const prepareDataFromDataLayerPayment = (carrier, product, leadId) => {
  let data = {
    checkout: {
      actionField: { step: 2, option: "Credit Card" },
      products: [
        {
          name: `Getlife ES ${product.intention}`,
          id: "",
          price: product.finalPrice,
          brand: carrier === "AXA" ? "c1" : "c2",
          category: product.coverage,
          variant: product.promotion ? "Rastreator Discount" : "",
          quantity: 1,
          language: "es",
          calculatedCapital: parseInt(product.capital),
          leadId: leadId,
        },
      ],
    },
  };

  return data;
};

const prepareDataFromDataLayerCoverage = (product, leadId) => {
  let data = {
    purchase: {
      actionField: {
        id: "",
        affiliation: product.purchasePeriod,
        revenue: product.revenue,
        tax: product.tax,
      },
      products: [
        {
          name: `Getlife ${product.lead.nationality} ${capitalize(
            product.intention
          )}`,
          id: leadId,
          price: product.price,
          brand: product.lead.carrier,
          category: product.coverage === "premium" ? "FIPA" : "F",
          variant: product.promotion.length && product.promotion[0],
          quantity: 1,
          language: "es",
          calculatedCapital: parseInt(product.lead.capital),
          leadId: leadId,
        },
      ],
    },
  };

  return data;
};

export {
  prepareDataFromDataLayerUpsell,
  prepareDataFromDataLayerUpsellClick,
  prepareDataFromDataLayerPayment,
  prepareDataFromDataLayerCoverage,
};
