/* eslint-disable no-template-curly-in-string */
import "./css/newUpsell.scss";
import "./css/animations.scss";
import UpgradeCapitalModal from "./Components/UpgradeCapitalModal/UpgradeCapitalModal";

import {
  ListSection,
  NavbarSticky,
  CrossedOutElement,
  BannerPrice,
  UpsellIconSection,
  ResumeCart,
  RenderTrustpilot,
  UpsellCTASection,
  StaticImgText,
  Typography,
  Chip,
  ProgressiveButton,
  Alert,
  GlobalIcon,
  ButtonDS,
} from "get-life-storybook-ts";

import OverlayInfoIpaAB from "./Components/Overlays/OverlayInfoIpaAB.component";
import OverlayChangeCapital from "./Components/Overlays/OverlayChangeCapital.component";
import OverlayInfoCapital from "./Components/Overlays/OverlayInfoCapital.component";
import { numberFormatPayment } from "../../utils/Utils";

import { IconSection, stepList, Truspilot } from "../../utils/dataForNewUpsell";
import UpsellController, { UpsellControllerI } from "./Upsell.controller";
import { SwitcherProps } from "get-life-storybook-ts/lib/molecules/Switcher/Switcher";
import { CouponInfoI } from "../StepsCalculatorPage/Components/PaymentPage/PaymentPage.controller";
import { ChipI } from "get-life-storybook-ts/lib/atoms/Chip/Chip";
import { useEffect, useRef } from "react";
import MaxCapitalModal from "../../components/MaxCapitalModal/MaxCapitalModal";
import { TagFormat } from "get-life-storybook-ts/lib/molecules/RenderTags/RenderTags";

interface UpsellI extends UpsellControllerI {
  couponInfo?: CouponInfoI;
  handleConditions: () => void;
  initialDownloadables?: any;
}

const Upsell: React.FC<UpsellI> = ({
  product,
  checkOut,
  couponInfo,
  setUpsellData,
  setUpsellProduct,
  handleConditions,
  initialDownloadables
}) => {
  const {
    addCapital,
    capital,
    capitalAdded,
    capitalAddedAmount,
    changeCapitalTooltip,
    changeDone,
    correctPrice,
    datePickerProps,
    domain,
    handleCallMe,
    handleIpaChange,
    handleUpgradedClose,
    ipa,
    isMobile,
    isPosition,
    listSectionData,
    priceChange,
    refIPA,
    renderPriceValidation,
    selectPlan,
    setChangeDone,
    translate,
    upGradedOpen,
    downloadableFiles,
    isABtesting,
    downloadableLoading
  } = UpsellController({ product, checkOut, setUpsellData, setUpsellProduct });

  const alertRef = useRef<any>(null);
  const prepareSwitchers = (): SwitcherProps[] => {
    let switchData = [];

    if (process.env.REACT_APP_DOMAIN_LOCALE !== "fr" && product.boundaries.availableTraits.DISABILITY) {
      let switchIPA = {
        buttonsInfo: {
          showBoth: false,
          buttonInfo: {
            primary: { label: translate("coupons.overlay.button") },
            secondary: {},
          },
        },
        extraInfo1: `+${numberFormatPayment(
          product.modifiers?.coverages?.DISABILITY
        )}`,
        extraInfo2: translate("newUpsell.resumeCart.priceMonth"),
        initialValue: ipa === "basic" ? false : true,
        onClick: () => handleIpaChange(ipa === "basic" ? "premium" : "basic"),
        overlay: <OverlayInfoIpaAB translate={translate} />,
        overlayTitle: (
          <Typography
            variant="H3"
            weight="700"
            text={translate("newUpsell.OverlayIpa.title")}
          />
        ),
        subtext: translate("newUpsell.resumeCart.IPAQuestion"),
        text: (
          <div className="flex gap-1">
            <Typography
              text={translate("newUpsell.resumeCart.addIPA1")}
              variant={"BodySmall"}
              weight="400"
              className="flex"
              color="dark-gray-100"
            />
            <Typography
              text={translate("newUpsell.resumeCart.addIPA2")}
              variant={"BodySmall"}
              weight="700"
              color="dark-gray-100"
            />
          </div>
        ),
        isAlwaysVisible: domain === "fr",
        textValidate: (
          <div className="flex gap-1">
            <Typography
              text={translate("newUpsell.resumeCart.IPAAdded1")}
              variant={
                domain === "es" || domain === "pt" || domain === "it"
                  ? "LinkSmall"
                  : "BodySmall"
              }
              weight={
                domain === "es" || domain === "pt" || domain === "it"
                  ? "700"
                  : "400"
              }
              className={`${
                domain === "es" ||
                domain === "pt" ||
                (domain === "it" && "cursor-pointer")
              }`}
              color="dark-gray-100"
              onClick={() =>
                domain === "es" ||
                domain === "pt" ||
                (domain === "it" && refIPA.current.handleShowModal())
              }
            ></Typography>
            <Typography
              text={translate("newUpsell.resumeCart.IPAAdded2")}
              variant={"BodySmall"}
              weight={
                domain === "es" || domain === "pt" || domain === "it"
                  ? "400"
                  : "700"
              }
              color="dark-gray-100"
            />
          </div>
        ),

        disabled: priceChange,
        ref: refIPA,
        extraInfoCharge: priceChange,
      };

      switchData.push(switchIPA);
    }

    if (product.modifiers.extracapital.price) {
      let switchCapital = {
        buttonsInfo: {
          showBoth: false,
          buttonInfo: {
            primary: { label: translate("coupons.overlay.button") },
            secondary: {},
          },
        },
        extraInfo1: `+${numberFormatPayment(
          product.modifiers.extracapital.price
        )}`,
        extraInfo2: translate("newUpsell.resumeCart.priceMonth"),
        initialValue: capitalAdded,
        onClick: () => addCapital(),
        overlay: <OverlayInfoCapital translate={translate} />,
        overlayTitle: (
          <Typography
            variant="H3"
            weight="700"
            text={translate("newUpsell.OverlayInfoCapital.title")}
          />
        ),
        subtext: translate("newUpsell.resumeCart.CapitalQuestion"),
        text: (
          <div className="flex gap-1">
            <Typography
              text={`+${numberFormatPayment(
                product.modifiers.extracapital.amount,
                "capital"
              )} €`}
              weight="700"
              className="flex"
              color="dark-gray-100"
              variant="BodySmall"
            />
            <Typography
              text={`${translate("newUpsell.resumeCart.addCapital")}`}
              weight="400"
              className="flex"
              color="dark-gray-100"
              variant="BodySmall"
            />
          </div>
        ),
        textValidate: (
          <div className="flex gap-1">
            <Typography
              text={`+${numberFormatPayment(capitalAddedAmount, "capital")} €`}
              weight="700"
              color="dark-gray-100"
              variant="BodySmall"
            />
            <Typography
              text={`${translate("newUpsell.resumeCart.CapitalAdded")}`}
              weight="400"
              color="dark-gray-100"
              variant="BodySmall"
            />
          </div>
        ),
        disabled: priceChange,
        extraInfoCharge: priceChange,
      };

      switchData.push(switchCapital);
    }
    
    return switchData;
  };
  const prepareExtraSwitchers = (): SwitcherProps[] => {
    let switchData = [];

    if (process.env.REACT_APP_DOMAIN_LOCALE === "it") {
      switchData.push(
        {
          extraInfo1: "",
          extraInfo2: "",
          overlayTitle: (
            <Typography
              variant="H3"
              weight="700"
              text={translate("newUpsell.resumeCart.decease.modalTitle")}
              color="dark-gray-100"
            />
          ),
          overlay: (
            <div>
              <GlobalIcon iconName="CandleIcon" size="L" />
              <Typography
                text={translate("newUpsell.resumeCart.decease.modalContent")}
                variant="Body"
                weight="400"
                className="mt-[16px]"
                color="dark-gray-100"
              />
            </div>
          ),
          subtext: "",
          text: "",
          textValidate: (
            <span
              dangerouslySetInnerHTML={{
                __html: translate("newUpsell.resumeCart.decease.title").replace(
                  "${price}",
                  numberFormatPayment(capital, "capital")
                ),
              }}
            />
          ),
          initialValue: true,
          isAlwaysVisible: true,
        },
        {
          extraInfo1: "",
          extraInfo2: "",
          overlayTitle: (
            <Typography
              variant="H3"
              weight="700"
              text={translate(
                "newUpsell.resumeCart.terminalIllness.modalTitle"
              )}
              color="dark-gray-100"
            />
          ),
          overlay: (
            <div>
              <GlobalIcon iconName="SaveMoneyIcon" size="L" />
              <Typography
                text={translate(
                  "newUpsell.resumeCart.terminalIllness.modalContent"
                )}
                variant="Body"
                weight="400"
                className="mt-[16px]"
                color="dark-gray-100"
              />
            </div>
          ),
          subtext: "",
          text: "",
          textValidate: (
            <span
              dangerouslySetInnerHTML={{
                __html: translate(
                  "newUpsell.resumeCart.terminalIllness.title"
                ).replace(
                  "${price}",
                  numberFormatPayment(capital / 2, "capital")
                ),
              }}
            />
          ),
          initialValue: true,
          isAlwaysVisible: true,
        },
        {
          extraInfo1: "",
          extraInfo2: "",
          overlayTitle: (
            <Typography
              variant="H3"
              weight="700"
              text={translate("newUpsell.resumeCart.protect.modalTitle")}
              color="dark-gray-100"
            />
          ),
          overlay: (
            <div className="payment-newUpsellContainer__benefits-modal">
              <div>
                <GlobalIcon
                  iconName="CalendarBrandIcon"
                  size="M"
                  color="var(--primary-color)"
                  filled="#D9D8FD"
                />
                <Typography
                  text={translate("newUpsell.resumeCart.protect.modal.title1")}
                  variant="H3"
                  weight="700"
                  color="life-blue-100"
                />
                <Typography
                  text={translate(
                    "newUpsell.resumeCart.protect.modal.content1"
                  )}
                  variant="Body"
                  weight="400"
                  color="dark-gray-100"
                />
              </div>
              <div>
                <GlobalIcon
                  iconName="TransparencyIcon"
                  size="M"
                  color="var(--primary-color)"
                  filled="#D9D8FD"
                />
                <Typography
                  text={translate("newUpsell.resumeCart.protect.modal.title2")}
                  variant="H3"
                  weight="700"
                  color="life-blue-100"
                />
                <Typography
                  text={translate(
                    "newUpsell.resumeCart.protect.modal.content2"
                  )}
                  variant="Body"
                  weight="400"
                  color="dark-gray-100"
                />
              </div>
              <div>
                <GlobalIcon
                  iconName="SaveMoneyIcon"
                  size="M"
                  color="var(--primary-color)"
                  filled="#D9D8FD"
                />
                <Typography
                  text={translate("newUpsell.resumeCart.protect.modal.title3")}
                  variant="H3"
                  weight="700"
                  color="life-blue-100"
                />
                <Typography
                  text={translate(
                    "newUpsell.resumeCart.protect.modal.content3"
                  )}
                  variant="Body"
                  weight="400"
                  color="dark-gray-100"
                />
              </div>
            </div>
          ),
          subtext: "",
          text: "",
          textValidate: (
            <span
              dangerouslySetInnerHTML={{
                __html: translate("newUpsell.resumeCart.protect.title"),
              }}
            />
          ),
          subtextSwitched: translate("newUpsell.resumeCart.protect.subtitle"),
          initialValue: true,
          isAlwaysVisible: true,
        }
      );
    }

    return switchData;
  }

  const prepareTags = () => {
    let tags = [];
    let tagCapital = {
      text: `${translate(
        "newUpsell.resumeCart.tagCapital"
      )} ${numberFormatPayment(capital, "capital")} €`,
      subtext: `${numberFormatPayment(capital, "capital")} €`,
      tooltip: <OverlayInfoCapital translate={translate} />,
      tooltipButtonLabel: translate("coupons.overlay.button"),
    };
    tags.push(tagCapital);

    if (ipa === "premium") {
      let tagIPA = {
        text: `${translate("newUpsell.resumeCart.tagIPAtext")} ${translate(
          "newUpsell.resumeCart.tagIPAsubtext"
        )}`,
        subtext: translate("newUpsell.resumeCart.tagIPAsubtext"),
        tooltip: <OverlayInfoIpaAB translate={translate} />,
        tooltipButtonLabel: translate("coupons.overlay.button"),
      };
      tags.push(tagIPA);
    }

    return tags;
  };

  const parseAlertMessage = () => {
    const alertMessage = translate("newUpsell.resumeCart.alertCapital").replace(
      "{capital}",
      `${numberFormatPayment(capital, "capital")} €`
    );

    if (ipa === "premium") {
      return `${alertMessage.split("|")[0]}| ${translate("dashboard.policy.cancellation.premium")}`;
    }
    if (ipa === "basic") {
      return `${alertMessage.split("|")[0]}| ${translate("dashboard.policy.cancellation.basic")}`;
    }
    return alertMessage;
  };
  
  const ChipsData: ChipI[] = [
    {
      text: `${translate(
        "newUpsell.resumeCart.tagCapital"
      )} ${numberFormatPayment(capital, "capital")} €`,
      type: "success" as "success",
      icon: "CheckedIcon",
    },
  ];

  if (domain === "it") {
    ChipsData.push(
      {
        text: translate("newUpsell.resumeCart.death"),
        type: "success" as "success",
        icon: "CheckedIcon",
      },
      {
        text: translate("newUpsell.resumeCart.terminalIllness"),
        type: "success" as "success",
        icon: "CheckedIcon",
      }
    );
  }

  if (ipa === "premium") {
    ChipsData.push({
      text: `${translate("newUpsell.resumeCart.tagIPAtext")} ${translate(
        "newUpsell.resumeCart.tagIPAsubtext"
      )}`,
      type: "success" as "success",
      icon: "CheckedIcon",
    });
  }

  const getDiscountedMonthlyPrice = () => {
    return product.promotion
      ? `${
          checkOut.priceSummary.priceToPay
            ? numberFormatPayment(checkOut.priceSummary.priceToPay)
            : 0
        } €`
      : `${numberFormatPayment(checkOut.priceSummary.regularPrice)} €`;
  };             

  useEffect(() => {
    if (isPosition === true && !isMobile && isABtesting) {
      alertRef.current?.handleShowPortal();
    }
  }, [isPosition, isMobile, alertRef, isABtesting]);

  return (
    <>
      <div className="payment-newUpsellContainer">
        <div className="newUpsell--section1">
          <div className="newUpsell-banner--section">
            <BannerPrice
              title={translate("newUpsell.banner.title")}
              subtitle={translate("newUpsell.banner.subtitle")}
              homeImage="https://storage.googleapis.com/getlife-bucket-1/public/image/img-new-web/Illustration_woman.svg"
              background="white"
            />
          </div>
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <ResumeCart
            buttonTextCustomPolicy={translate(
              "aggregator.personalInformation.btnContract"
            )}
            onClickCP={() => selectPlan()}
            overlayButtonCP={
              <OverlayChangeCapital
                product={product}
                translate={translate}
                initialValue={capital.toString()}
                textButtonLabel={translate(
                  "newUpsell.tooltipCapital.buttonLabel"
                )}
                onClick={changeCapitalTooltip}
                changeDone={changeDone}
              />
            }
            extraFunction={() => setChangeDone(false)}
            subtitlePB={translate("newUpsell.resumeCart.addCapitalTitle")}
            switchData={prepareSwitchers()}
            extraSwitchData={prepareExtraSwitchers()}
            extraSwitchTitle={translate("newUpsell.resumeCart.switchers.title")}
            textButton={translate("newUpsell.resumeCart.addCapitalButton")}
            titleCP={translate("newUpsell.resumeCart.titleCP")}
            titlePrice={
              isMobile ? "" : translate("newUpsell.resumeCart.titlePrice")
            }
            tags={ChipsData}
            tooltipIcon="BulbIcon"
            subtitle={isABtesting ? renderPriceValidation() : undefined}
            tooltipText={
              isABtesting ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate("newUpsell.resumeCart.tooltipValidDate"),
                  }}
                />
              ) : undefined
            }
            monthlyprice={getDiscountedMonthlyPrice()}
            monthlytext={translate(
              `upsell.PricePlan.${
                checkOut.lead.purchasePeriod === "monthly" ||
                product.period === "monthly"
                  ? "monthlyPrice"
                  : "annualPrice"
              }`
            )}
            oldPrice={
              !!product.promotion &&
              checkOut?.priceSummary.regularPrice !==
                checkOut?.priceSummary.priceToPay
                ? `${numberFormatPayment(checkOut.priceSummary.regularPrice)} €`
                : undefined
            }
            step={stepList(translate)}
            priceChange={priceChange}
            conditionsLabel={
              couponInfo && translate("payment.conditionsModal.title")
            }
            onConditionsLabelClick={couponInfo && handleConditions}
            datePickerProps={datePickerProps()}
            overlayButtonLabelCP={""}
            overlayTitleCP={
              <Typography
                variant="H3"
                weight="700"
                className="text-[#424242]"
                text={translate("newUpsell.tooltipCapital.title")}
              />
            }
            downloadableFilesTitle={translate("downloadableFiles.title")}
            downloadableFiles={downloadableFiles || initialDownloadables}
            downloadableLoading={downloadableLoading}
          />
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <div className="containerSection">
            <ListSection
              title={translate("newUpsell.listSection.title")}
              list={listSectionData}
            />
          </div>
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <UpsellIconSection
            title={translate("newUpsell.iconSection.title")}
            data={IconSection(translate) as any}
          />
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <StaticImgText
            image={translate("newUpsell.staticImgText.img")}
            title={translate("newUpsell.staticImgText.title")}
            titleMobile={translate("newUpsell.staticImgText.titleMobile")}
            paragraphFirst1={translate(
              "newUpsell.staticImgText.paragraphFirst1"
            )}
            paragraphFirst2={translate(
              "newUpsell.staticImgText.paragraphFirst2"
            )}
            paragraphFirst3={translate(
              "newUpsell.staticImgText.paragraphFirst3"
            )}
            paragraphFirst4={translate(
              "newUpsell.staticImgText.paragraphFirst4"
            )}
            paragraphSecond1={translate(
              "newUpsell.staticImgText.paragraphSecond1"
            )}
            paragraphSecond2={translate(
              "newUpsell.staticImgText.paragraphSecond2"
            )}
            paragraphSecond3={translate(
              "newUpsell.staticImgText.paragraphSecond3"
            )}
            paragraphSecond4={translate(
              "newUpsell.staticImgText.paragraphSecond4"
            )}
            paragraphSecond5={translate(
              "newUpsell.staticImgText.paragraphSecond5"
            )}
          />
        </div>

        <div className="payment-newUpsellContainer__newsection">
          <RenderTrustpilot
            comments={Truspilot(translate)}
            excelentText={translate("newUpsell.truspilotSection.excelentText")}
            rating={4.8}
            title={translate("newUpsell.truspilotSection.title")}
            trustpilotText={translate(
              "newUpsell.truspilotSection.trustpilotText"
            )}
          />
        </div>
      </div>
      <div className="payment-newUpsellContainer__newsection-upsellCTA">
        <UpsellCTASection
          buttonTextCustomPolicy={translate(
            "aggregator.personalInformation.btnContract"
          )}
          monthlyprice={getDiscountedMonthlyPrice()}
          monthlytext={translate("upsell.PricePlan.monthlyPrice")}
          oldPrice={
            product.promotion?.price
              ? `${numberFormatPayment(product.finalPrice)} €`
              : undefined
          }
          onClick={() => handleCallMe()}
          onClickCP={() => selectPlan()}
          subtitlePB={isABtesting ? renderPriceValidation() : undefined}
          paragraph1CTA={translate("newUpsell.ctaSection.paragraph1CTA")}
          paragraph2CTA={translate("newUpsell.ctaSection.paragraph2CTA")}
          phone={process.env.REACT_APP_TEL!}
          tags={ChipsData as unknown as TagFormat[]}
          textButton={translate("newUpsell.ctaSection.textButton")}
          title2CTA={translate("newUpsell.ctaSection.title2CTA")}
          titleCTA={translate("newUpsell.ctaSection.titleCTA")}
          titlePrice={
            isMobile
              ? translate("newUpsell.ctaSection.titlePrice")
              : translate("newUpsell.resumeCart.titlePrice")
          }
          showCustomText={true}
          customText={translate("newUpsell.resumeCart.titleCP")}
          onClickCustomText={() =>
            window.scrollTo({ top: 500, left: 0, behavior: "smooth" })
          }
          conditionsLabel={
            couponInfo && translate("payment.conditionsModal.title")
          }
          onConditionsLabelClick={couponInfo && handleConditions}
          tooltipIcon="BulbIcon"
          tooltipText={
            isABtesting ? (
              <span
                className={"payment-newUpsellContainer__newsection--tooltip"}
                dangerouslySetInnerHTML={{
                  __html: translate("newUpsell.resumeCart.tooltipValidDate"),
                }}
              />
            ) : undefined
          }
        />
      </div>
      <Alert
        ref={alertRef}
        message={
          <div>
            <Typography
              variant="Body"
              weight="700"
              text={translate("newUpsell.resumeCart.alertMonthlyPrice").replace(
                "{price}",
                `${
                  product.promotion
                    ? `${
                        checkOut.priceSummary.priceToPay
                          ? numberFormatPayment(
                              checkOut.priceSummary.priceToPay
                            )
                          : 0
                      } €`
                    : `${numberFormatPayment(
                        checkOut.priceSummary.regularPrice
                      )} €`
                }`
              )}
            />
            <Typography
              variant="BodyMedium"
              weight="400"
              text={parseAlertMessage()}
            />
          </div>
        }
        iconName="none"
        type="info"
        close={true}
        closeColor="white"
        buttonAlertConfig={{
          show: true,
          config: {
            onClick: () => {
              selectPlan();
            },
            buttonType: "text",
            className: "!text-white w-fit",
            label: translate("newUpsell.resumeCart.alertContinue"),
            noHover: true,
            iconPosition: "right",
            icon: <GlobalIcon iconName="ArrowRightIcon" />,
          },
        }}
        positionY="bottom"
      />
      {isMobile && isPosition && (
        <NavbarSticky
          position="bottom"
          content={
            <div className="payment-newUpsellContainer__buyCard--content">
              <div className="flex flex-col">
                {!!product.promotion &&
                  checkOut?.priceSummary.regularPrice !==
                    checkOut?.priceSummary.priceToPay && (
                    <Typography
                      variant="SpanMedium"
                      weight="400"
                      text={`${numberFormatPayment(
                        correctPrice("finalPrice")
                      )}€`}
                      className="payment-newUpsellContainer__buyCard--discounted"
                    />
                  )}
                <div className="payment-newUpsellContainer__buyCard--price">
                  <Typography
                    variant="H2"
                    text={getDiscountedMonthlyPrice()}
                  />
                  <Typography
                    variant="Span"
                    weight="400"
                    text={`/${translate("upsell.BuyCard.month")}`}
                  />
                </div>
              </div>
              <div className="payment-newUpsellContainer__chips">
                {ChipsData.map((d, idx) => {
                  const { text, type } = d;
                  return (
                    <Chip
                      text={text}
                      type={type}
                      icon={"CheckedIcon"}
                      key={`${text}_${idx}`}
                    />
                  );
                })}
              </div>
            </div>
          }
          actions={
            isABtesting ? (
              <div className="payment-newUpsellContainer__buyCard--actions">
                <ProgressiveButton
                  onClick={() => selectPlan()}
                  text={"Protégete"}
                />
              </div>
            ) : (
              <ButtonDS
                label={translate("aggregator.personalInformation.btnContract")}
                onClick={() => selectPlan()}
                className="w-full"
              />
            )
          }
        />
      )}

      <UpgradeCapitalModal
        upGradedOpen={upGradedOpen}
        handleUpgradedClose={handleUpgradedClose}
        capitalUpgrade={product.upgraded.capital}
      />

      <MaxCapitalModal capital={product.newCapital}/>
    </>
  );
};

export default Upsell;
