import { useContext } from "react";
import { InlineWidget } from 'react-calendly';
import Chat from './../../../../../assets/img/chatWithTeam/Group 42@3x.png';
import Phone from './../../../../../assets/img/phone_contact.png';
import Email from './../../../../../assets/img/email.png';
import Time from './../../../../../assets/img/time_icon.png';
import './../css/asesor.scss';
import { I18nContext } from '../../../../../context/i18n.context';
import { GlobalEmails, GlobalHubspotLinks } from "../../../../../utils/InternationlChanges";
import { ButtonDS } from "get-life-storybook-ts";

export default function Asesor() {

    const LOCALE = process.env.REACT_APP_DOMAIN_LOCALE;

    const Hubspot = GlobalHubspotLinks[LOCALE];
    const EMAIL = GlobalEmails[LOCALE];

    const talkAsesor = () => {
        window.open(`mailto:${EMAIL}`);
    };

    const {
        state: { translate },
    } = useContext(I18nContext);

    return (
        <div className="asesor-container">
            <p className="asesor-title">{translate("dashboard.policy.asesor.title")}</p>

            <div className="globalPosition-resume">
                <div
                    className="globalPosition-resumeBox"
                    style={{ paddingLeft: '0px' }}
                >
                    <p
                        className="globalPosition-resumeTitle"
                        style={{
                            borderBottom: ' 2px solid #ededed',
                            width: '100%',
                            paddingLeft: '29px',
                        }}
                    >
                        {translate("dashboard.policy.asesor.talkAsesor")}
                    </p>
                </div>
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.policy.asesor.text")}
                        </p>
                    </div>
                    <img
                        src={Chat}
                        alt="contacta con tu asesor y te ayudaremos"
                        style={{ width: '39.9px', height: '34.9px' }}
                    />
                </div>
                <div
                    className="globalPosition-resumeBoxContainer"
                    style={{ backgroundColor: 'rgba(245,196,220, 0.5)' }}
                >
                    <div className="globalPosition-resumeBox">
                        <img src={Time} alt="horario de contacto" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.policy.asesor.contactHoursTitle")}
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            {' '}
                            &nbsp;{translate("dashboard.policy.asesor.contactHoursText")}
                        </p>
                    </div>
                </div>
                <div
                    className="globalPosition-resumeBoxContainer"
                    style={{ backgroundColor: 'rgba(245,196,220, 0.5)' }}
                >
                    <div className="globalPosition-resumeBox">
                        <img src={Phone} alt="horario de contacto" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.policy.asesor.contactPhoneTitle")}
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            {' '}
                            &nbsp; {process.env.REACT_APP_TEL_POST}
                        </p>
                    </div>
                </div>
                <div
                    className="globalPosition-resumeBoxContainer"
                    style={{ backgroundColor: 'rgba(245,196,220, 0.5)' }}
                >
                    <div className="globalPosition-resumeBox">
                        <img src={Email} alt="teléfono de contacto" />
                        <p className="globalPosition-resumeContent">
                            {translate("dashboard.policy.asesor.contactEmailTitle")}
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '12px' }}>
                            &nbsp; {EMAIL}
                        </p>
                    </div>
                </div>
            </div>

            <div
                className="globalPosition-talkTeamContainer"
                style={{ marginTop: '69px' }}
            >
                <div className="globalPosition-resumeBoxContainer">
                    <div className="globalPosition-resumeBox">
                        <p className="globalPosition-resumeTitle">
                            {translate("dashboard.policy.asesor.talkUsTitle")}
                        </p>
                    </div>
                </div>
                <div
                    className="globalPosition-resumeBoxContainer"
                    style={{ borderBottom: 'none ' }}
                >
                    <div className="globalPosition-resumeBox">
                        <p className="globalPosition-resumeTitle">
                            {translate("dashboard.policy.asesor.talkUsText")}
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        paddingLeft: '44px',
                        paddingTop: '39px',
                        paddingBottom: '41px',
                        width: "241px"
                    }}
                >
                    <ButtonDS
                        buttonType="secondary"
                        label={translate("dashboard.policy.asesor.talkUsBtn")}
                        onClick={talkAsesor}
                        className="w-full"
                    />
                </div>
            </div>

            <div className="asesor-date">
                <p>
                    {translate("dashboard.policy.asesor.asesorDate1")}
                </p>

                <p>
                    {translate("dashboard.policy.asesor.asesorDate2")}
                </p>
                <div>
                    <InlineWidget
                        url={Hubspot}
                        styles={{
                            height: '900px',
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
