/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import {
  GetlifeContext,
  LanguageT,
  useQuery,
} from "../../../context/GetLifeContext.context";
import { useNavigate } from "react-router";
import { GetLifeTrackerContext } from "../../../context/GetLifeTracker.context";
import { GetLifeCalculatorContext } from "../../../context/GetLifeCalculator.context";
import {
  GlobalAllowTelesubscription, GlobalPromoCodesToApply
} from "../../../utils/InternationlChanges";
import { DataPublicI } from "../../../api/request/Lead/Interfaces/DataInterface.response";
import { applyPromotion } from "../../../hooks/Promotions/applyPromotion";
import { I18nContext } from "../../../context/i18n.context";
import { CALENDAR_MEET, DOMAIN } from "../../../constants/Global";
import { useDevice } from "get-life-storybook-ts";
import { CoverageT } from "../../../api/request/Lead/Interfaces/ProductInterface.response";
import { InsuranceCalculateDataI } from "../../../api/request/Lead/Interfaces/InsuranceCalculate";
import useGetDownloadFiles from "../../../hooks/useGetDownloadFiles";

const QuotePageController = () => {
  const {
    init,
    leadId,
    setBrokerId,
    setIsABtesting,
    setLeadId,
    setToken,
    token,
    setLoading
  } = useContext(GetlifeContext);
  const { handleTrackerQuestion, identifyFS } = useContext(
    GetLifeTrackerContext
  );
  const {
    state: { translate },
  } = useContext(I18nContext);
  const {
    navigateBack,
  } = useContext(GetLifeCalculatorContext);

  const leadRepository = new HttpLeadRepository(leadId);
  let query = useQuery();
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const lead = query.get("lead");

  const [basicFrom, setBasicFrom] = useState<number>(0);
  const [coverage, setCoverage] = useState<string>("");

  const [insuranceCover, setInsuranceCover] = useState<string>("");
  const [intention, setIntention] = useState<string>("");
  const [ipaToggle, setIpaToggle] = useState<boolean>(true);
  const [isCouponApplied, setIsCouponApplied] = useState<boolean>(false);
  const [maxCapital, setMaxCapital] = useState<number>(0);
  const [minCapital, setMinCapital] = useState<number>(0);
  const [premiumFrom, setPremiumFrom] = useState<number>(0);
  const [pricePromotion, setPricePromotion] = useState<any>();
  const [hasTelesubscription, setHasTelesubscription] =
    useState<boolean>(false);
  const [btnContractLabel, setBtnContractLabel] = useState<string>(
    translate("calculatorPage.question.continue")
  );

  const [flagLink, setFlagLink] = useState<boolean>(false);

  const linkRefCall = useRef<any>();

  const prepareLeadForQuestions = (route: string) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (token) {
      navigate(route);
    } else {
      leadRepository.getToken(lead || leadId).then((res) => {
        const { token, brokerId, email, isAbTest } = res;
        setToken(token);
        setBrokerId(brokerId);
        identifyFS({
          lead: leadId,
          data: {
            emailDomain: email?.split("@")[1],
            email: email,
            brokerId: brokerId,
          },
        });

        setIsABtesting(isAbTest);

        navigate(route);
      });
    }
  };

  const navigateToQuestions = () =>
    prepareLeadForQuestions("/productQuestions");

  const handleButtonQuote = () => {
    if (
      hasTelesubscription &&
      GlobalAllowTelesubscription[DOMAIN! as LanguageT]
    ) {
      window.open(CALENDAR_MEET);
    } else {
      navigateToQuestions();
    }
  };



  const getBtnContractButtonLabel = (telesubscription: boolean) => {
    if (telesubscription) {
      setBtnContractLabel(
        translate(translate("telesubscription.button.calendar"))
      );
    } else {
      setBtnContractLabel(translate("calculatorPage.question.continue"));
    }
  };

  const updatePrices = (price: number) => {
    setLoading(true);
    leadRepository
      .getTarrif({ capital: price, insuranceType: coverage as CoverageT })
      .then((data: InsuranceCalculateDataI) => {
        fetQuoteData(lead || leadId);
      });
  };

  const updateIpa = (ipa: boolean) => {
    setIpaToggle(ipa);
    setLoading(true);
    let NewIpa = ipa ? "premium" : "basic";
    leadRepository
      .getTarrif({
        capital: parseFloat(insuranceCover),
        insuranceType: NewIpa as CoverageT,
      })
      .then((data: any) => {
        setBasicFrom(data.basicPrice);
        setPremiumFrom(data.premiumPrice);
        setInsuranceCover(data.capital);
        setCoverage(ipa ? "premium" : "basic");
        setHasTelesubscription(data.undeterminedTarification);
        getBtnContractButtonLabel(data.undeterminedTarification);
        setLoading(false);
      });
  };

  const fetchApplyCoupon = async (data: DataPublicI) => {
    try {
      const response = await applyPromotion({
        leadId: lead!,
        price: data.basicPrice * 12,
        promotion: GlobalPromoCodesToApply[DOMAIN as LanguageT]!,
      });
      handleTrackerQuestion({
        type: "FS",
        event: `appliedCoupon`,
        data: {
          promotionalCode: GlobalPromoCodesToApply[DOMAIN as LanguageT]!,
          leadId: lead,
          market: DOMAIN,
        },
      });
      setIsCouponApplied(response!.promotionalCodeHasApplied);
    } catch (error) {
      setIsCouponApplied(false);
    }
  };

  const fetQuoteData = async (newLead: string) => {
    try {
      setLoading(true);
      const response: DataPublicI = await leadRepository.getQuoteData(newLead);
      setLeadId(newLead);
      setHasTelesubscription(response.hasTelesubscription);
      getBtnContractButtonLabel(response.hasTelesubscription);
      setMinCapital(response.minCapital);
      setMaxCapital(response.maxCapital);
      setInsuranceCover(response.capital.toString());
      setCoverage(response.coverage);
      setIpaToggle(response.coverage === "basic" ? false : true);
      setIntention(response.intention);
      setBasicFrom(response.basicPrice);
      setPremiumFrom(response.premiumPrice);
      if (response?.priceSummary?.hasDiscount) {
        if (response.priceSummary.priceToPay === 1.01) {
          setPricePromotion(1.00)
        } else {
          setPricePromotion(response.priceSummary.priceToPay);
        }
        
      }
    
      if (GlobalPromoCodesToApply[DOMAIN as LanguageT] &&
        !response.priceSummary.hasDiscount && !response.hasTelesubscription
      ) {
        await fetchApplyCoupon(response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const { downloadableFiles, downloadableLoading } = useGetDownloadFiles({
    leadId,
    translate,
    coverage: coverage as CoverageT,
    price: basicFrom
  });

  useEffect(() => {
    (async () => {
      if (lead) {
        init("lead_already_exist", true);
        await fetQuoteData(lead);
      }
    })();
  }, [lead, isCouponApplied]);

  useEffect(() => {
    if (flagLink) {
      linkRefCall.current?.click();
      setFlagLink(false);
    }
  }, [flagLink]);

  useEffect(() => {
    setHasTelesubscription(hasTelesubscription);
  }, [hasTelesubscription]);

  return {
    basicFrom,
    btnContractLabel,
    coverage,
    downloadableFiles,
    downloadableLoading,
    handleButtonQuote,
    hasTelesubscription,
    insuranceCover,
    intention,
    ipaToggle,
    isMobile,
    linkRefCall,
    maxCapital,
    minCapital,
    navigateBack,
    navigateToQuestions,
    premiumFrom,
    pricePromotion,
    setFlagLink,
    setInsuranceCover,
    translate,
    updateIpa,
    updatePrices,
  };
};

export default QuotePageController;
