import { useState, useEffect } from 'react';
import DinamicCheckBox from '../../../components/Components/CheckBox/DinamicCheckBox';
import TextField from '@material-ui/core/TextField';
import PhoneImg from './../../../assets/img/phoneCall.svg';
import './css/callMe.scss';
import { ButtonDS } from 'get-life-storybook-ts';

export default function CallMe(props) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [confirmed, setConfirmed] = useState(false);

    const handleChangePhoneNumber = (e) => {
        setPhoneNumber(e);
    };
    const handleChangeConfirmed = (e) => {
        setConfirmed(e);
    };

    useEffect(() => {}, [confirmed]);

    return (
        <div className="callMe-container">
            <p
                style={{
                    textAlign: 'right',
                    fontSize: '26px',
                    cursor: 'pointer',
                }}
                onClick={() => props.handlePopUp()}
            >
                x
            </p>
            <div className="callMe-header">
                <img src={PhoneImg} alt="Te llamaremos" />
                <div>
                    <h1>Te llamamos con brevedad</h1>
                </div>
            </div>

            <div className="callMe-bodyContainer">
                <p>Número de teléfono</p>
                <TextField
                    onChange={(e) => handleChangePhoneNumber(e.target.value)}
                    handleValues={handleChangePhoneNumber}
                    type="number"
                    variant="outlined"
                    placeholder="456 345 345"
                />

                <div className="callMe-checkBoxContainer">
                    <DinamicCheckBox
                        value={confirmed}
                        handleOnChange={handleChangeConfirmed}
                        backgroundColor={confirmed ? '#f6f6f6' : null}
                    />
                </div>
                <div style={{ marginTop: '52px' }}>
                    <ButtonDS
                        className="w-full"
                        label="Llámame ahora"
                        disabled={!confirmed}
                    />
                </div>
            </div>
        </div>
    );
}
