import { Accordion, Typography } from "get-life-storybook-ts";
import CrossIcon from "get-life-storybook-ts/lib/components/Icons/CrossIcon";
import TickIcon from "get-life-storybook-ts/lib/components/Icons/TickIcon";
import {
  CouponInfoI,
  ValidateCouponT,
} from "../PaymentPage/PaymentPage.controller";
import CouponInfo from "./CouponInfo.component";

import "./Coupon.styles.scss";
import ArrowDownIcon from "get-life-storybook-ts/lib/components/Icons/ArrowDownIcon";
import ArrowUpIcon from "get-life-storybook-ts/lib/components/Icons/ArrowUpIcon";

interface CouponAlertI {
  couponInfo: CouponInfoI;
  couponName: string;
  translate: (key: string) => string;
  validateCoupon: ValidateCouponT;
}

const CouponAlert: React.FC<CouponAlertI> = ({
  couponInfo,
  couponName,
  translate,
  validateCoupon,
}) => {
  return (
    <div className={`couponAlert bg-${validateCoupon}`}>
      <Accordion
        icons={[<ArrowDownIcon />, <ArrowUpIcon />]}
        classNameContainerAccodrionHeader={`couponAlert__header border-${validateCoupon} px-[10px]`}
        headerContent={
          <div className={`couponAlert__title`}>
            {validateCoupon === "validate" || validateCoupon === "validate-null" ? (
              <TickIcon width="32px" height="32px" />
            ) : (
              <CrossIcon width="32px" height="32px" />
            )}
            <div className="couponAlert__title__container">
              <div className="couponAlert__title__first"></div>
              <div className="couponAlert__title__second">
               {validateCoupon !== "validate-null" && <Typography
                  text={translate(`coupon.alert.text.${validateCoupon}`)}
                  variant="Body"
                />}
              </div>
              <div className="couponAlert__title__second mobileCouponText">
                <Typography
                  text={couponName}
                  variant="Body"
                  className="couponText"
                />
                {validateCoupon !== "validate-null" && (<Typography
                  text={translate(`coupon.alert.${validateCoupon}`)}
                  variant="Body"
                  className="validate"
                />)}
              </div>
            </div>
          </div>
        }
        bodyContent={
          couponInfo && (
            <div className="couponAlert__body">
              {" "}
              <CouponInfo
                translate={translate}
                couponInfo={couponInfo}
                couponName={couponName}
              />
            </div>
          )
        }
      />
    </div>
  );
};

export default CouponAlert;
