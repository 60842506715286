import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import HttpLeadRepository from "../../../../../api/request/Lead/Lead.service";
import { GetlifeContext } from "../../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../../context/i18n.context";
import { beneficiariesQuestions } from "../questionsPostPayment";

const BeneficiariesFreeChoiceController = () => {
  const { leadId, token, setLoading, loading, setProgressBar } =
    useContext(GetlifeContext);

  const { step } = useParams();
  const {
    state: { translate },
  } = useContext(I18nContext);

  const navigate = useNavigate();
  const location = useLocation();
  const actualUrl = location.pathname;
  const [question, setQuestion] = useState<any>(false);
  const [beneficiaries, setBeneficiaries] = useState([]);

  const leadRepository = new HttpLeadRepository(leadId, token);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setProgressBar(85);
    setLoading(true);
    setQuestion(beneficiariesQuestions(translate)[Number(step) - 1]);
    setLoading(false);
  }, [step]);

  function checkUssedCapital() {
    leadRepository.getBeneficiaries().then((response) => {
      if (response.availableCapital === 0) {
        navigate("/coverage/7");
      } else {
        navigate("/beneficiaries/5");
      }
    });
  }

  const sendAnswer = async () => {
    let beneficiaryType;
    setLoading(true);
    if (step === "1") {
      await leadRepository.saveBeneficiariesTypes(beneficiaries).then((res) => {
        if (res.code === 401) {
          navigate("/login");
        }
      });
      await leadRepository
        .getBeneficiariesTypes()
        .then((dataGetBeneficiaries) => {
          if (dataGetBeneficiaries.includes("physicalPerson")) {
            navigate("/beneficiaries/2");
          } else if (dataGetBeneficiaries.includes("bankingEntity")) {
            navigate("/beneficiaries/3");
          } else if (dataGetBeneficiaries.includes("legalPerson")) {
            navigate("/beneficiaries/4");
          } else {
            navigate("/coverage/7");
          }
          setLoading(false);
        });
    } else if (step === "2") {
      setProgressBar(90);
      beneficiaryType = "physicalPerson";
      await leadRepository
        .saveBeneficiaries({ types: beneficiaryType, beneficiaries })
        .then((res) => {
          if (res.code === 401) {
            navigate("/login");
          }
        });
      await leadRepository
        .getBeneficiariesTypes()
        .then((dataGetBeneficiaries) => {
          if (dataGetBeneficiaries.includes("bankingEntity")) {
            navigate("/beneficiaries/3");
          } else if (dataGetBeneficiaries.includes("legalPerson")) {
            navigate("/beneficiaries/4");
          } else {
            checkUssedCapital();
          }
          setLoading(false);
        });
    } else if (step === "3") {
      setProgressBar(95);
      beneficiaryType = "bankingEntity";
      await leadRepository
        .saveBeneficiaries({ types: beneficiaryType, beneficiaries })
        .then((res) => {
          if (res.code === 401) {
            navigate("/login");
          }
        });
      await leadRepository
        .getBeneficiariesTypes()
        .then((dataGetBeneficiaries) => {
          if (dataGetBeneficiaries.includes("legalPerson")) {
            navigate("/beneficiaries/4");
          } else {
            checkUssedCapital();
          }
          setLoading(false);
        });
    } else if (step === "4") {
      setProgressBar(98);
      beneficiaryType = "legalPerson";
      await leadRepository
        .saveBeneficiaries({ types: beneficiaryType, beneficiaries })
        .then((res) => {
          checkUssedCapital();
          setLoading(false);
        });
    }
  };
  return {
    actualUrl,
    beneficiaries,
    loading,
    question,
    sendAnswer,
    setBeneficiaries,
    step,
    translate,
  };
};

export default BeneficiariesFreeChoiceController;
