
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../context/i18n.context";
import HttpIdCardRepository from "../../api/request/IdCard/IdCard.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";

const IdCardBiometryController = () => {

    const [neededDocuments, setNeededDocuments] = useState<string[]>([]);
    const [isDocumentSelected, setIsDocumentSelected] = useState<boolean>(false);
    const [urlBiometry, setUrlBiometry] = useState<string>("");

    const {
        leadId,
        setLoading,
        token
      } = useContext(GetlifeContext);

    const {
        state: { translate },
    } = useContext(I18nContext);

    const idCardRepository = new HttpIdCardRepository(leadId, token);

    useEffect(() => {
        const getDocuments = async () => {
            setLoading(true);
            await idCardRepository.getDocumentsBiometry().then((response) => {
                setNeededDocuments(response);
                setLoading(false);
            })
        };

        getDocuments();

    }, []);

    const selectDocument = (DOC: string) => {
        setLoading(true);
        setIsDocumentSelected(true);
        idCardRepository.getUrlBiometry(DOC).then((response) => {
            setUrlBiometry(response.externalUrl);
            setLoading(false);
        })
    }

    return {
        isDocumentSelected,
        neededDocuments,
        selectDocument,
        translate,
        urlBiometry
    }

}

export default IdCardBiometryController;