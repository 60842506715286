/* eslint-disable jsx-a11y/anchor-has-content */
import numberFormat from "../../../utils/Utils";
import QuotePageController from "./QuotePage.controller";
import {
  AdditionalProtection,
  BoxColor,
  ButtonDS,
  CTABanner,
  DownloadableFiles,
  GlobalIcon,
  QuoteBanner,
  Slider,
  TextWithInfoIcon,
  Typography,
} from "get-life-storybook-ts";
import { DOMAIN, PHONE_NOT_COVER } from "../../../constants/Global";
import { LanguageT } from "../../../context/GetLifeContext.context";
import { GlobalIllnes, GlobalOnlyPremium, GlobalCapitalStepper, GlobalIllnesTitle, GlobalIllnesIcon, GlobalIllnesContent, QuotePageBenefitsList } from "../../../utils/InternationlChanges";

import "./QuotePage.styles.scss";
import { showPriceDaily } from "../../../utils/ShowDailyPrice/ShowDailyprice";
import { CoverageT } from "../../../api/request/Lead/Interfaces/ProductInterface.response";

const listPoints = (translate: (key: string) => string): string[] => {
  const points = QuotePageBenefitsList[DOMAIN!] || QuotePageBenefitsList["default"];
  return points.map(p => translate(p));
}

const QuotePage = () => {
  const {
    basicFrom,
    btnContractLabel,
    coverage,
    downloadableFiles,
    downloadableLoading,
    handleButtonQuote,
    hasTelesubscription,
    insuranceCover,
    ipaToggle,
    isMobile,
    linkRefCall,
    maxCapital,
    minCapital,
    navigateBack,
    premiumFrom,
    pricePromotion,
    setFlagLink,
    setInsuranceCover,
    translate,
    updateIpa,
    updatePrices,
  } = QuotePageController();

  return (
    <main className="QuotePage">
      <section className="QuotePage__journeyquestion">
        <QuoteBanner
          containerStyle="heroBannerStyle w-full"
          subtitleTextStyle="subtitleTextStyle"
          title={""}
          subtitle={translate("calculatorPage.textYourPrice")}
          btnLabel={btnContractLabel}
          btnOnClick={handleButtonQuote}
          callmeLabel={
            hasTelesubscription
              ? translate("calculatorPage.QuotePage.maxCapital.button").replace(
                  "$",
                  `${PHONE_NOT_COVER}`
                )
              : ""
          }
          callmeLabelOnClick={() => setFlagLink(true)}
          content={
            <>
              {" "}
              {typeof pricePromotion !== "number" || pricePromotion < 0 ? (
                <p className="priceTextStyle">
                  <span className="textFromStyle">
                    {translate("calculatorPage.textFrom")}
                  </span>{" "}
                  {ipaToggle
                    ? premiumFrom.toFixed(2).replaceAll(".", ",")
                    : basicFrom.toFixed(2).replaceAll(".", ",")}
                  <span className="currencyStyle">€</span>
                </p>
              ) : (
                <>
                  <p className="crossedPriceText">
                    {`${
                      ipaToggle
                        ? premiumFrom.toFixed(2).replaceAll(".", ",")
                        : basicFrom.toFixed(2).replaceAll(".", ",")
                    } €`}
                  </p>
                  <p className="priceTextStyle">
                    {pricePromotion !== 0 && (
                      <span className="textFromStyle">
                        {translate("calculatorPage.textFrom")}{" "}
                      </span>
                    )}
                    {pricePromotion !== 0
                      ? pricePromotion.toFixed(2).replaceAll(".", ",")
                      : 0}
                    <span className="currencyStyle">€</span>
                  </p>
                </>
              )}
            </>
          }
          subContent={
            <p className="subtextPriceStyle">
              <span className="textMonthlyStyle">
                {translate("calculatorPage.textMonthly")}
              </span>
              <div className="separatorStyle" />
              {showPriceDaily({
                coverage: coverage as CoverageT, translate, prices: {
                premiumFrom, pricePromotion, basicFrom
              }})}
            </p>
          }
        />
        <a href={`tel:${PHONE_NOT_COVER}`} ref={linkRefCall} />
      </section>
      <section className="QuotePage__cancelanytime">
        <div className="QuotePage__cancelanytime--slider">
          <Slider
            min={minCapital}
            max={maxCapital}
            step={GlobalCapitalStepper[DOMAIN! as LanguageT]}
            initial={parseInt(insuranceCover)}
            onValueChange={(value) => setInsuranceCover(value.toString())}
            onAfterChange={() => updatePrices(parseInt(insuranceCover))}
            capitalFormat={numberFormat}
            sliderLabel={translate("confirmationPage.resume.capital")}
          />
        </div>
        {hasTelesubscription && (
          <div className="QuotePage__cancelanytime--boxMaxCapital">
            <BoxColor
              title={translate("calculatorPage.QuotePage.maxCapital.title")}
              backgroundColor="#ECECFE"
              borderColor="var(--primary-color)"
              colorTitle="#3430E3"
              description={translate("calculatorPage.QuotePage.maxCapital.description")}
              icon="HeadsetIcon"
            />
          </div>
        )}
        <div className="QuotePage__cancelanytime--products">
          <div className="w-full">
            <AdditionalProtection
              title={translate("calculatorPage.textCoverage")}
              subtitle={translate("calculatorPage.textCoverageDeath")}
              capital={
                DOMAIN === "it"
                  ? numberFormat(parseInt(insuranceCover), "capital")
                  : undefined
              }
              initialValue={ipaToggle}
              onValueChange={() => updateIpa(!ipaToggle)}
              icon={
                isMobile ? (
                  ""
                ) : (
                  <GlobalIcon iconName="CandleFilledIcon" filled="red" />
                )
              }
              included={true}
              infoText={
                <p className="flex flex-col gap-[16px] text-[#424242]">
                  <GlobalIcon iconName="CandleIcon" size="L" />
                  {translate("calculatorPage.textCoverageDeathInfo")}
                </p>
              }
              overlayTitle={
                <Typography
                  text={translate("newUpsell.resumeCart.decease.modalTitle")}
                  variant="H3"
                  weight="700"
                  className="text-[#424242]"
                />
              }
              overlayButtonLabel={translate("loginPageOtp.button.close")}
              hideSwitch={true}
            />
          </div>
          <div className="w-full">
            <AdditionalProtection
              title={translate("calculatorPage.textAdditionalProtection")}
              subtitle={translate(GlobalIllnes[DOMAIN! as LanguageT])}
              capital={
                DOMAIN === "it"
                  ? numberFormat(parseInt(insuranceCover) / 2, "capital")
                  : undefined
              }
              initialValue={ipaToggle}
              onValueChange={() => updateIpa(!ipaToggle)}
              icon={isMobile ? "" : <GlobalIcon iconName="ShieldFilledIcon" />}
              added={ipaToggle}
              included={{
                it: true,
                fr: true
              }[DOMAIN || ""] || false}
              infoText={
                <p className="flex flex-col gap-[16px] text-[#424242]">
                  <GlobalIcon iconName={GlobalIllnesIcon[DOMAIN! as LanguageT]} size="L" />
                  {translate(GlobalIllnesContent[DOMAIN! as LanguageT])}
                </p>
              }
              overlayTitle={
                <Typography
                  text={translate(GlobalIllnesTitle[DOMAIN! as LanguageT])}
                  variant="H3"
                  weight="700"
                  className="text-[#424242]"
                />
              }
              hideSwitch={GlobalOnlyPremium[DOMAIN! as LanguageT]}
              overlayButtonLabel={translate("loginPageOtp.button.close")}
            />
          </div>
        </div>
        {downloadableFiles && (
          <div className="QuotePage__cancelanytime--downloadFiles">
            <TextWithInfoIcon
              content={translate("calculatorPage.downloadableFilesTitle")}
              infoIcon={true}
              weight="400"
              variant="BodyXSmall"
              color="secondarygrey"
              iconSize="XXXS"
              iconColor="#7B7B7B"
            />

            <DownloadableFiles
              files={downloadableFiles}
              className="md:!flex-row"
              loading={downloadableLoading}
            />
          </div>
        )}
      </section>
      <section className="QuotePage__journeyquestion--cta">
        <CTABanner
          title={translate("calculatorPage.textQuestions")}
          subtitle={translate("calculatorPage.textHelp")}
          subtitleStyle="text-darkGray60"
          listPoints={listPoints(translate)}
          listStyle="text-darkGray80"
        />
      </section>

      <section className="QuotePage__buttons">
        <ButtonDS
          label={btnContractLabel}
          onClick={handleButtonQuote}
          rightIcon="ArrowRightIcon"
          className="QuotePage__buttons--primary"
        />
        <ButtonDS
          ghost
          buttonType="secondary"
          label={translate("back")}
          onClick={() => navigateBack()}
          leftIcon="ArrowBackIcon"
          className="QuotePage__buttons--secondary"
        />
      </section>
      <div className={`${isMobile ? "QuotePage__buttons--sticky" : "hidden"}`}>
        {hasTelesubscription && (
          <ButtonDS
            buttonType="secondary"
            ghost
            label={translate("calculatorPage.QuotePage.maxCapital.button.sticky")}
            onClick={() => setFlagLink(true)}
          />
        )}
        <ButtonDS className="!w-full" label={btnContractLabel} onClick={handleButtonQuote} />
      </div>
    </main>
  );
};

export default QuotePage;
