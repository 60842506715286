import Logo from './../../../../../assets/img/getlife-logo/getlife-azul.svg';
import Menu from './../../../../../assets/img/menu.png';
import './../css/header.scss';
import { ButtonDS } from 'get-life-storybook-ts';

export default function Header(props) {
    return (
        <div className="dashboard-headerContainer">
            <img src={Logo} alt="getlife" />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    paddingRight: '50px',
                    alignItems: 'center',
                }}
            >
                <div>
                    <ButtonDS
                        buttonType="secondary"
                        label="FAQ´s"
                        style={{ width: "78px" }}
                    />
                </div>
                {props.displayMenu === false ? (
                    <img
                        src={Menu}
                        alt="menú"
                        onClick={() => props.handleMenu()}
                    />
                ) : (
                    <p
                        style={{ fontSize: '20px', color: '#15403b' }}
                        onClick={() => props.handleMenu()}
                    >
                        x
                    </p>
                )}
            </div>
        </div>
    );
}
