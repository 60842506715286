import React, { useContext } from "react";
import { useNavigate } from "react-router";

import "./css/notFoundPage.scss";

import arrow from "../../assets/img/getlife-elements/arrow.png";

import logo from "../../assets/img/getlife-logo/getlife-azul.svg";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { I18nContext } from "../../context/i18n.context";
import { getMessage } from "../StepsCalculatorPage/Components/PaymentGateway/components/PaymentCardContent/PaymentCardContent.component";

export default function LoginPage() {
  const navigate = useNavigate();

  const { token } = useContext(GetlifeContext);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const composeMessage = (key, comp) => {
    const Component = comp;
    return <Component>{translate(key)}</Component>;
  };

  const handleClick = () => {
    navigate("/login");
  };

  const handleClickInit = () => {
    navigate("/info");
  };

  const message = token
    ? translate("notFound.continueProcess")
    : translate("notFound.backToLogin");

  return (
    <div className="NotFound">
      <main className="main">
        <h1 className="main--title">¡Ups!</h1>
        <p className="main--news">
          {getMessage({
            message: translate("notFound.subtitle"),
            replace: composeMessage("notFound.subtitle.strong", "strong"),
          })}
        </p>
        <p className="main--subtitle">
          <strong> {translate("notFound.badNews.title")}</strong>
        </p>
        <p className="main--text">{translate("notFound.badNews.text")}</p>
        <p className="main--subtitle">
          <strong>{translate("notFound.goodNews.title")}</strong>
        </p>
        <p className="main--text">
          {translate("notFound.goodNews.text")}
          <a
            href={process.env.REACT_APP_DOMAIN_URL}
            className="main--text__link"
          >
            {process.env.REACT_APP_DOMAIN_URL}
          </a>
        </p>
        <nav className="main--display">
          <img
            src={arrow}
            alt="flecha que señala el elemento sobre el que estás"
            className="main--imgArrow"
          />
          <ul className="main--nav">
            <li className="main--nav__link" onClick={handleClick}>
              {message}
            </li>
            <li className="main--nav__link" onClick={handleClickInit}>
              {translate("notFound.newProcess")}
            </li>
            <li>
              <a href="https://life5.es/ayuda/" className="main--nav__link">
                {translate("notFound.help")}
              </a>
            </li>
            <li>
              <a href="https://life5.es/unete/" className="main--nav__link">
                {translate("notFound.aboutGetlife")}
              </a>
            </li>
          </ul>
        </nav>
      </main>
    </div>
  );
}
