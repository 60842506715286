import { useEffect, useContext } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { Route, Routes } from "react-router-dom";
import "material-design-icons/iconfont/material-icons.css";
import { GetlifeContext } from "./context/GetLifeContext.context";
import PublicRoute from "./Routes/PublicRoutes.routes";
import {
  privateRoutes,
  protectedRoutes,
  publicRoutes,
  redirects,
} from "./Routes/routes";
import PrivateRoute from "./Routes/PrivateRoutes.routes";
import Layout from "./views/Layout/Layout.component";
import { StatusContext } from "./context/StatusContext";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_OAUTH_ID } from "./constants/Global";

const getRoutes = (routes) => {
  return routes.map((route, index) => (
    <Route exact key={index} path={route.path} element={route.component}>
      {route.children && getRoutes(route.children)}
    </Route>
  ));
};
export default function App() {
  const {
    token,
    userStatus,
    leadId
  } = useContext(GetlifeContext);

  const {getStatus} = useContext(StatusContext);

  const theme = createTheme({
    palette: {
      tooltip: "#339dff",
    },
    breakpoints: {
      values: {
        xs: 414,
        md: 720,
        xl: 1440,
      },
    },
    typography: {
      fontFamily: `"Montserrat", Sans-serif, serif !important;`,
    },
  });

  const setCSSTheme = () => {
    const domain = process.env.REACT_APP_DOMAIN_LOCALE;
    const themes = {
      it: {
        "--primary-color": "#003399",
        "--dark-primary-color": "#003781",
        "--light-primary-color": "#ECF0FF",
      },
    };

    if (themes[domain]) {
      const rootTheme = document.querySelector(":root");
      Object.keys(themes[domain]).forEach((property) => {
        const value = themes[domain][property];
        rootTheme.style.setProperty(property, value);
      });
    }
  };

  useEffect(() => {
    setCSSTheme();
  }, [])

  useEffect(() => {
    if (
      window.location.pathname !== "/decision" &&
      window.location.pathname !== "/info" &&
      window.location.pathname !== "/welcomeLead" &&
      window.location.pathname !== "/calculator/1" &&
      window.location.pathname !== "/card-validation" &&
      leadId
    )
      getStatus();
  }, [token]);
  
    return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_ID}>
      <MuiThemeProvider theme={theme}>
        <Layout>
          <Routes>
            {getRoutes(redirects)}
            {getRoutes(publicRoutes)}
            <Route
              element={<PublicRoute token={token} userStatus={userStatus} />}
            >
              {getRoutes(protectedRoutes)}
            </Route>
            <Route
              element={<PrivateRoute token={token} userStatus={userStatus} />}
            >
              {getRoutes(privateRoutes)}
            </Route>
          </Routes>
        </Layout>
      </MuiThemeProvider>
    </GoogleOAuthProvider>
  );
}
