import React, { Dispatch, ReactNode, useRef, useState } from "react";
import * as FullStory from "@fullstory/browser";
import useCache from "../hooks/useCache";
import { useLocation } from "react-router";
import { setCookie } from "../hooks/setCookie";
import { getCookie } from "../hooks/getCookie";
import { threeMonthsDate } from "../utils/Dates.utils";
import { DOMAIN } from "../constants/Global";
import { GlobalPromoCodesToApply } from "../utils/InternationlChanges";
import { NotificationI } from "../constants/Notification";
import { ToastValues } from "get-life-storybook-ts/lib/atoms/Toast/Toast";

interface ContextProps {
  children: React.ReactChildren;
}

export type AlertT = "success" | "error" | "info";

export type LanguageT = "es" | "fr" | "en" | "pt" | "it";

export type UserStatusT =
  | "product_questions_pending"
  | "questions_pending"
  | "rejected"
  | "nationality_pending"
  | "upsell_pending"
  | "payment_pending"
  | "paymentmethodpending_pending"
  | "address_pending"
  | "beneficiaries_pending"
  | "idcard_pending"
  | "beneficiariestypes_pending"
  | "physicalperson_pending"
  | "bankingentity_pending"
  | "legalperson_pending"
  | "legalnote_pending"
  | "signature_pending"
  | "complete"
  | "ops_review"
  | "it_review"
  | "insurer_review"
  | "claim"
  | "docs_pending"
  | "csv_pending"
  | "validation_pending"
  | "initial_status"
  | "prestack_pending"
  | "telesubscription";


interface NavbarStickyInfoI {
  actions?: React.ReactNode;
  content?: React.ReactNode;
  position?: "top" | "bottom";
  show?: boolean;
}

interface GetlifeContextI {
  backFlag: boolean;
  brokerId: string;
  domain: LanguageT;
  handleCallMe: () => void;
  init: (entry_point?: string, loading?: boolean) => void;
  isABtesting: boolean;
  lang: LanguageT;
  leadId: string;
  loading: boolean;
  navbarStickyInfo: NavbarStickyInfoI;
  next: string;
  nodeElementAlert: any;
  nodeWeCallYou: any;
  nodeMaxCapitalModal: any;
  notification: NotificationI | undefined;
  openModal: boolean;
  otp: string;
  progressBar: number | boolean;
  showHeader: boolean;
  showFooter: boolean;
  question: string;
  setBackFlag: (value: boolean) => void;
  setBrokerId: (value: string) => void;
  setDomain: (value: LanguageT) => void;
  setIsABtesting: (value: boolean) => void;
  setLang: Dispatch<LanguageT>;
  setLeadId: (value: string) => void;
  setLoading: (value: boolean) => void;
  setNavbarStickyInfo: (value: NavbarStickyInfoI) => void;
  setNext: (value: string) => void;
  setNotification: (value: NotificationI) => void;
  setOpenModal: (value: boolean) => void;
  setOtp: (value: string) => void;
  setProgressBar: (value: number | boolean) => void;
  setQuestion: (value: {}) => void;
  setShowHeader: (value: boolean) => void;
  setShowFooter: (value: boolean) => void;
  setToken: (value: string) => void;
  setTokenVelogica: (value: string) => void;
  setUserStatus: (value: UserStatusT) => void;
  token: string;
  tokenVelogica: string;
  userStatus: UserStatusT;
  gtf_campaign: string | null;
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
  utm_content: string | null;
  utm_term: string | null;
  utm_test: string | null;
  tid: string | null;
  gclid: string | null;
  fbclid: string | null;
  setGtf_campaign: (value: string | null) => void;
  setUtm_source: (value: string | null) => void;
  setUtm_medium: (value: string | null) => void;
  setUtm_campaign: (value: string | null) => void;
  setUtm_content: (value: string | null) => void;
  setUtm_term: (value: string | null) => void;
  setUtm_test: (value: string | null) => void;
  setTid: (value: string | null) => void;
  setGclid: (value: string | null) => void;
  setFbclid: (value: string | null) => void;
  getEntryPage: () => string | null;
  getReferrerUrl: () => string | null;
  getABTestVersion: () => string | null;
  sendEventsNewLead: string | null;
  setSendEventsNewLead: (value: string) => void;
  toast: ToastValues;
  setToast: (values: ToastValues) => void;
  toastIsOpen: boolean;
  setToastIsOpen: (value: boolean) => void;
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const GetlifeContext = React.createContext({} as GetlifeContextI);

export const GetlifeProvider = ({ children }: ContextProps) => {
  const [leadId, setLeadId] = useCache("lead");
  const [token, setToken] = useCache("token");
  const [userStatus, setUserStatus] = useCache("userStatus");
  const [brokerId, setBrokerId] = useCache("brokerId");
  const [ABtesting, setIsABtesting] = useCache("isABtesting");
  const [backFlag, setBackFlag] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [domain, setDomain] = useState<LanguageT>(
    DOMAIN as LanguageT
  );
  const [lang, setLang] = useState<LanguageT>("es");
  const [otp, setOtp] = useState<string>("");
  const [question, setQuestion] = useCache("question");
  const [tokenVelogica, setTokenVelogica] = useCache("tokenVelogica");
  const [loading, setLoading] = useState<boolean>(false);
  const [next, setNext] = useState<string>("");
  const [notification, setNotification] = useState<NotificationI>({
    message: "",
  });
  const [toastIsOpen, setToastIsOpen] = useState<boolean>(false);
  const [toast, setToast] = useState<ToastValues>({
    onClose: () => setToastIsOpen(false)
  })
  const [progressBar, setProgressBar] = useState<number | boolean>(false);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [navbarStickyInfo, setNavbarStickyInfo] = useState<NavbarStickyInfoI>({
    show: false,
    content: <></>,
    actions: <></>,
    position: "bottom",
  });

  const [gtf_campaign, setGtf_campaign] = useState<string | null>(GlobalPromoCodesToApply[DOMAIN as LanguageT]);
  const [utm_source, setUtm_source] = useState<string | null>(getCookie("utm_source"));
  const [utm_medium, setUtm_medium] = useState<string | null>(getCookie("utm_medium"));
  const [utm_campaign, setUtm_campaign] = useState<string | null>(getCookie("utm_campaign"));
  const [utm_content, setUtm_content] = useState<string | null>(getCookie("utm_content"));
  const [utm_term, setUtm_term] = useState<string | null>(getCookie("utm_term"));
  const [utm_test, setUtm_test] = useState<string | null>(getCookie("utm_test"));
  const [tid, setTid] = useState<string | null>(getCookie("tid"));
  const [gclid, setGclid] = useState<string | null>(getCookie("gclid"));
  const [fbclid, setFbclid] = useState<string | null>(getCookie("fbclid"));
  const getEntryPage = () => getCookie("entryPage");
  const getReferrerUrl = () => {
    const referrerUrl = getCookie("referrerUrl");
    if (referrerUrl === "defined") return null;
    return referrerUrl;
  };
  const getABTestVersion = () => getCookie("ABTestVersion");
  const [sendEventsNewLead, setSendEventsNewLead] = useCache("sendEventsNewLead");
  const nodeElementAlert = useRef();
  const nodeWeCallYou = useRef<any>(null);
  const nodeMaxCapitalModal = useRef<any>(null);

  const isABtesting = ABtesting === "true" || ABtesting === true;
  const utms: string[] = [
    "gtf_campaign",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
    "utm_test",
    "tid",
    "gclid",
    "fbclid"
  ]

  const handleCallMe = () => {
    nodeWeCallYou.current?.handleShowPortal();
  };

  const init = (entry_point?: string, loading = false) => {
    setToken("");
    setLeadId("");
    setUserStatus("initial_status");
    setTokenVelogica("");
    setBrokerId("");
    setLoading(loading);
    setIsABtesting(false);
    setGtf_campaign(getCookie("gtf_campaign"));
    setUtm_source(getCookie("utm_source"));
    setUtm_medium(getCookie("utm_medium"));
    setUtm_campaign(getCookie("utm_campaign"));
    setUtm_content(getCookie("utm_content"));
    setUtm_term(getCookie("utm_term"));
    setUtm_test(getCookie("utm_test"));
    setTid(getCookie("tid"));
    setGclid(getCookie("gclid"));
    setFbclid(getCookie("fbclid"));
    setSendEventsNewLead(false);

    FullStory.event("init", {
      entry_point: entry_point
    });
  };
  
  const saveNewEntryPage = () => setCookie("entryPage", window.location.href)

  const saveNewReferrerUrl = () => setCookie("referrerUrl", document.referrer)

  const saveNewFbclid = (newFbclid: string) => {
    setCookie("fbclid", newFbclid, threeMonthsDate);
    setFbclid(newFbclid);
  }

  const saveNewGclid = (newGclid: string) => {
    setCookie("gclid", newGclid, threeMonthsDate);
    setGclid(newGclid);
  }

  const resetCookiesIfNewQS = (query: URLSearchParams) => {
    const QueryStringUTMs = Object.fromEntries(query.entries());
    const hasNewUTMs = utms.some((utm) => QueryStringUTMs.hasOwnProperty(utm));

    if (hasNewUTMs) {
      const utmsToBeKeeped = ["gclid", "fbclid"];
      utms.forEach((utm) => {
        if (utmsToBeKeeped.includes(utm)) return;
        setCookie(utm, null);
      });
      saveNewEntryPage();
      saveNewReferrerUrl();
    }
  };

  React.useEffect(() => {
    const newQuery = new URLSearchParams(window.location.search);

    resetCookiesIfNewQS(newQuery);

    if(getEntryPage() === null) saveNewEntryPage();
    if(getCookie("referrerUrl") === null) saveNewReferrerUrl();

    let query_gtf_campaign = newQuery.get("gtf_campaign");
    if (query_gtf_campaign) {
      setCookie("gtf_campaign", query_gtf_campaign);
      setGtf_campaign(query_gtf_campaign);
    } else {
      setCookie("gtf_campaign", GlobalPromoCodesToApply[DOMAIN as LanguageT]);
    }

    let query_utm_source = newQuery.get("utm_source");
    if (query_utm_source) {
      setCookie("utm_source", query_utm_source);
      setUtm_source(query_utm_source);
    }

    let query_utm_medium = newQuery.get("utm_medium");
    if (query_utm_medium) {
      setCookie("utm_medium", query_utm_medium);
      setUtm_medium(query_utm_medium);
    }

    let query_utm_campaign = newQuery.get("utm_campaign");
    if (query_utm_campaign) {
      setCookie("utm_campaign", query_utm_campaign);
      setUtm_campaign(query_utm_campaign);
    }

    let query_utm_content = newQuery.get("utm_content");
    if (query_utm_content) {
      setCookie("utm_content", query_utm_content);
      setUtm_content(query_utm_content);
    }

    let query_utm_term = newQuery.get("utm_term");
    if (query_utm_term) {
      setCookie("utm_term", query_utm_term);
      setUtm_term(query_utm_term);
    }

    let query_utm_test = newQuery.get("utm_test");
    if (query_utm_test) {
      setCookie("utm_test", query_utm_test);
      setUtm_test(query_utm_test);
    }

    let query_tid = newQuery.get("tid");
    if (query_tid) {
      setCookie("tid", query_tid);
      setTid(query_tid);
    }

    let query_gclid = newQuery.get("gclid");
    if (query_gclid) saveNewGclid(query_gclid);

    let query_fbclid = newQuery.get("fbclid");
    if (query_fbclid) saveNewFbclid(query_fbclid);
  }, []);

  const values = {
    backFlag,
    brokerId,
    domain,
    lang,
    handleCallMe,
    init,
    isABtesting,
    leadId,
    loading,
    navbarStickyInfo,
    next,
    notification,
    nodeElementAlert,
    nodeWeCallYou,
    nodeMaxCapitalModal,
    openModal,
    otp,
    progressBar,
    question,
    showHeader,
    showFooter,
    sendEventsNewLead,
    setBackFlag,
    setBrokerId,
    setDomain,
    setIsABtesting,
    setLang,
    setLeadId,
    setLoading,
    setNavbarStickyInfo,
    setNext,
    setNotification,
    setOpenModal,
    setOtp,
    setProgressBar,
    setQuestion,
    setSendEventsNewLead,
    setShowHeader,
    setShowFooter,
    setToken,
    setTokenVelogica,
    setUserStatus,
    token,
    tokenVelogica,
    userStatus,
    gtf_campaign,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    utm_test,
    tid,
    gclid,
    fbclid,
    setGtf_campaign,
    setUtm_source,
    setUtm_medium,
    setUtm_campaign,
    setUtm_content,
    setUtm_term,
    setUtm_test,
    setTid,
    setGclid,
    setFbclid,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion,
    saveNewFbclid,
    saveNewGclid,
    toast,
    setToast,
    toastIsOpen,
    setToastIsOpen,
  };
  return (
    <GetlifeContext.Provider value={values}>{children as unknown as ReactNode}</GetlifeContext.Provider>
  );
};
