/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonDS, useDevice } from "get-life-storybook-ts";
import { useContext, useEffect, useState } from "react";
import { FormBuilderContext } from "../../../context/FormBuilder.context";

const FormButton = ({
  showButton,
  isLoading,
  isOneChoinceQuestion,
  componentName,
  translate,
  handleSubmit,
  question,
  answer,
  goBack,
  customDisabled,
  validateQuestionDateInput,
  questionStack
}) => {
  const { disabled } = useContext(FormBuilderContext);
  const { isMobile } = useDevice();
  const isAswerDiferentFromNone = answer && answer !== "none";
  const hasNoNoneLabelOption =
    question && question.type === "select_multiple" && !isAswerDiferentFromNone;
  const isOptionalCheckbox = question.type === "checkbox_optional";
  const continueLabel = translate("continuar");
  const [labelText, setLabelText] = useState(continueLabel);
  const [disabledButton, setDisabledButton] = useState(
    customDisabled || disabled
  );

  useEffect(() => {
    setDisabledButton(disabled);
  }, [disabled]);

  useEffect(() => {
    if (isAswerDiferentFromNone) {
      setLabelText(continueLabel);
    }

    if (hasNoNoneLabelOption) {
      setLabelText(translate(continueLabel));
    }

    if (isOptionalCheckbox && !isAswerDiferentFromNone) {
      setLabelText(translate(translate("productQuestions.label.noNone")));
    }
  }, [question.type, labelText, answer, translate]);

  return (
    <div className={isOptionalCheckbox ? "inBottomButtons" : isMobile ? "mobileShow" : "buttonsContainer"}>
      {showButton === true &&
        isLoading === false &&
        !isOneChoinceQuestion &&
        componentName !== "external_underwriting" && (
          <div
            className={
              isMobile
                ? "formBuilder-buttonContainer-mobile formBuilder-buttonContainer-mobile-primary"
                : "formBuilder-buttonContainer"
            }
          >
            <ButtonDS
              label={labelText || continueLabel}
              onClick={handleSubmit}
              disabled={disabledButton}
              className="w-full"
              rightIcon="ArrowRightIcon"
            />
          </div>
        )}
      {!!question &&
        questionStack.length > 0 &&
        componentName !== "external_underwriting" && (
          <div
            className={
              isMobile
                ? "formBuilder-buttonContainer-mobile goBackButtonWidthMobile"
                : "formBuilder-buttonContainer"
            }
          >
            <ButtonDS
              label={translate("questions.button.goBack")}
              onClick={goBack}
              buttonType="secondary"
              ghost={true}
              className="w-full"
              leftIcon="ArrowBackIcon"
            />
          </div>
        )}
    </div>
  );
};

export default FormButton;
