import { CoverageT } from "../../api/request/Lead/Interfaces/ProductInterface.response";

export interface PricesI {
    pricePromotion: number;
    premiumFrom: number;
    basicFrom: number;
}

export interface ShowDailyPriceI {
    coverage: CoverageT;
    prices: PricesI;
    translate: (key: string) => string
}

export const showPriceDaily = ({ coverage, prices, translate }: ShowDailyPriceI): string => {
  const subtextPrice = translate(
    `calculatorPage.${
      typeof prices.pricePromotion === "number" && prices.pricePromotion === 0
        ? "subtextPricePromoFree"
        : "subtextPrice"
    }`
  );
  let price =
    coverage === "premium"
      ? (prices.premiumFrom / 30).toFixed(2).replaceAll(".", ",")
      : (prices.basicFrom / 30).toFixed(2).replaceAll(".", ",");
  if (typeof prices.pricePromotion === "number") {
    if (prices.pricePromotion) {
      price = (prices.pricePromotion / 30).toFixed(2).replaceAll(".", ",");
    } else {
      price = "";
    }
  }
  if (subtextPrice.includes("$")) {
    return subtextPrice.replace("$", price?.toString());
  }
  return subtextPrice;
};
