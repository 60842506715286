import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import HttpPaymentRepository from "../../../../api/request/Payment/Payment.service";
import { GetlifeContext } from "../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../context/i18n.context";
import { StripeIntentStatusI } from "../dashboard/Policy/ModalPolicy/ModalPolicy.controller";
import { useDevice } from "get-life-storybook-ts";
import { DOMAIN } from "../../../../constants/Global";

declare global {
  interface Window {
    gtag?: any;
    ttq?: any;
  }
}

export type ValidateCouponT = "validate" | "invalidate" | "error" | "validate-null";

export interface CouponInfoI {
  conditions?: string;
  exceptions?: string;
  error?: string;
  code?: string;
}

const PaymentPageController = ({ checkOut, handleSetupIntent, handlePurchasePeriod, product }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const paymentLocation = location?.pathname.split("/")[1];
  const { isMobile } = useDevice();
  const { lead } = checkOut;
  const [payConfirm, setPayConfirm] = React.useState(false);

  const [payConfirm2, setPayConfirm2] = React.useState(false);

  const [userData, setUserData] = React.useState<any>(checkOut);
  const [isUserDataAvailable, setIsUserDataAvailable] = React.useState(false);
  const [buttonContent, setButtonContent] = React.useState<any>("Hacer pago");

  const [openBox, setOpenBox] = React.useState({
    userData: false,
    creditCardData: false,
  });

  const [stripeInputStatus, setStripeInputStatus] =
    React.useState<StripeIntentStatusI>({
      complete: false,
      error: undefined,
    });

  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const { setBackFlag, leadId, token, setLoading, loading, handleCallMe } =
    React.useContext(GetlifeContext);

  const leadRepository = new HttpLeadRepository(leadId, token);
  const paymentRepository = new HttpPaymentRepository(leadId, token);

  const handleIsUserDataAvailable = () => {
    setIsUserDataAvailable(true);
  };

  const handleOpenBox = (index: number | string, value: any) => {
    const prevValue = openBox;
    setOpenBox({ ...prevValue, [index]: value });
  };

  const goToUpsell = () => {
    setBackFlag(true);
    navigate(`/${paymentLocation}/1`);
  };

  const closeNameForm = () => handleOpenBox("userData", false);

  // TODO:
  const handleStripeStatus = (
    index: any,
    value: any,
    index2: any,
    value2: any
  ) => {
    const prevValue = stripeInputStatus;

    setStripeInputStatus({
      ...prevValue,
      [index]: value,
      [index2]: value2,
    });
  };

  // TODO:
  const handleIsLoading = (value: any) => {
    if (value === undefined) {
      setLoading(!loading);
    } else {
      setLoading(value);
    }
  };

  const handleCheckbox = () => setPayConfirm(!payConfirm);

  const handleCheckbox2 = () => setPayConfirm2(!payConfirm2);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || "",
    {
      locale: "es",
      stripeAccount: process.env.REACT_APP_STRIPE_ACCOUNT,
    }
  );

  const isButtonDisabled = () => {
    if (DOMAIN === "it") {
      if (
        stripeInputStatus.complete === true &&
        stripeInputStatus.error === undefined &&
        payConfirm === true && payConfirm2 === true
      ) {
        return false;
      }
      return true;
    }

    if (
      stripeInputStatus.complete === true &&
      stripeInputStatus.error === undefined &&
      payConfirm === true
    ) {
      return false;
    }
    return true;
    
  };

  const navigateTo = () => {
    if (lead?.brokerLead) {
      navigate("/coverage/7");
    } else {
      navigate("/confirmation");
    }
  };

  React.useEffect(() => {
    isButtonDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeInputStatus]);

  React.useEffect(() => {
    if (userData) {
      if (userData?.lead?.name === null || userData?.lead?.lastName === null) {
        handleOpenBox("userData", true);
      } else {
        setIsUserDataAvailable(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  React.useEffect(() => {
    if (isUserDataAvailable) {
      setLoading(true);
      if (product.period) {
        handlePurchasePeriod(product.period);
      }
      paymentRepository.setup().then((res) => {
        const data = res;
        handleSetupIntent(data);
        setLoading(false);
      });
      setOpenBox({ userData: false, creditCardData: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserDataAvailable]);

  React.useEffect(() => {
    if (!loading) {
      setButtonContent("Reintentar");
    }
  }, [loading]);

  const saveData = (data: any) => {
    leadRepository.saveData(data).then((res) => {
      setUserData(res);
      handleIsUserDataAvailable();
    });
  };

  return {
    buttonContent,
    closeNameForm,
    goToUpsell,
    handleCheckbox,
    handleCheckbox2,
    handleIsLoading,
    handleOpenBox,
    handleStripeStatus,
    isButtonDisabled,
    isMobile,
    loading,
    isUserDataAvailable,
    leadId,
    navigateTo,
    openBox,
    payConfirm,
    payConfirm2,
    saveData,
    handleCallMe,
    stripePromise,
    stripeInputStatus,
    token,
    translate,
    userData,
  };
};

export default PaymentPageController;
