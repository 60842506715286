import { TransitionPage } from "get-life-storybook-ts";
import CheckoutPay from "./components/CheckoutPay/CheckoutPay.component";
import CouponAlert from "../Coupon/CouponAlert.component";
import PaymentPageChallenger from "../PaymentPage/PaymentPage.component.";
import PaymentGatewayController from "./PaymentGateway.contoller";
import CheckoutSign from "./components/CheckoutSign/CheckoutSign.component";
import Upsell from "../../../NewUpsell/Upsell.component";
import ConditionsModalAB from "../../../../components/ConditionsModal/ConditionsModalAB";

export default function PaymentGateway() {
  const {
    checkOut,
    couponInfo,
    handleAddCoupon,
    handleConditions,
    handleRemoveCoupon,
    handlePurchasePeriod,
    leadId,
    loading,
    nodeConditionsModal,
    paymentMethod,
    product,
    setPaymentMethod,
    setSetupIntent,
    setCheckOut,
    setProduct,
    setupIntent,
    stepPayment,
    token,
    translate,
    validateCoupon,
    paymentLocation,
    transitionLoading,
    transitionPageData,
    downloadableFiles
  } = PaymentGatewayController();

  console.log(transitionPageData);

  const couponName =
    paymentLocation === "payment"
      ? product?.promotion?.name || ""
      : product?.promotion?.description || "";

  return (
    <>
      {checkOut !== null && (
        <>
          <div id="payment-gateway" className="h-full w-full">
            {couponInfo && (
              <CouponAlert
                couponInfo={couponInfo}
                couponName={couponName}
                translate={translate}
                validateCoupon={validateCoupon!}
              />
            )}
            {
              stepPayment === "1" && transitionLoading  && (
                <TransitionPage
                  {...transitionPageData}
                />
              )
            }
            {stepPayment === "1" && !!product && !transitionLoading && (
              <>
                <Upsell
                  product={product}
                  initialDownloadables={downloadableFiles}
                  checkOut={checkOut}
                  setUpsellData={setCheckOut}
                  setUpsellProduct={setProduct}
                  couponInfo={couponInfo}
                  handleConditions={handleConditions}
                />
              </>
            )}

            {stepPayment === "3" && !!product && (
              <>
                <PaymentPageChallenger
                  checkOut={checkOut}
                  couponInfo={couponInfo}
                  handleAddCoupon={handleAddCoupon}
                  handlePaymentMethod={setPaymentMethod}
                  handlePurchasePeriod={handlePurchasePeriod}
                  handleRemoveCoupon={handleRemoveCoupon}
                  handleSetupIntent={setSetupIntent}
                  product={product}
                  setupIntent={setupIntent}
                  validateCoupon={validateCoupon}
                  onConditionsClick={handleConditions}
                  paymentLocation={paymentLocation}
                />
              </>
            )}

            { product?.promotion && (
              <ConditionsModalAB
                couponInfo={couponInfo!}
                nodeFakeDoorModal={nodeConditionsModal}
                promotion={product.promotion}
                collection={checkOut.feeCollection}
                period={product.period as any}
                handleSubmit={() =>
                  nodeConditionsModal.current?.handleShowPortal()
                }
              />
            )}

            {stepPayment === "4" && (
              <CheckoutPay
                checkOut={checkOut}
                leadId={leadId}
                loading={loading}
                paymentMethod={paymentMethod}
                token={token}
                product={product}
              />
            )}
            {stepPayment === "5" && <CheckoutSign />}
          </div>
        </>
      )}
    </>
  );
}
