import { ButtonDS } from 'get-life-storybook-ts';
import Chat from './../../../../../assets/img/chatWithTeam/Group 42@3x.png';
import './../css/popUpAsesor.scss';

export default function PopUpAsesor() {
    return (
        <div className="popUpAsesor-boxContainer">
            <img src={Chat} alt="habla con nosotros" />
            <h1>Contacta con tu asesor para modificar la póliza</h1>
            <p>
                Te guiaremos en el proceso de modificación y te asesoramos para
                que todo esté como tu quieres
            </p>
            <p>
                <b>¡No dudes en hablar con nosotros!</b>
            </p>
            <ButtonDS label="Hablar con mi asesor" />
        </div>
    );
}
