import './add.scss';

export default function Add(props) {
    return (
        <div
            className="add-container"
            onClick={() => props.onClick()}
        >
            <p style={{ color: 'var(--primary-color)' }}>
                {props.content}
            </p>
        </div>
    );
}
