import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Box, FormControlLabel, Switch } from "@material-ui/core";

function DinamicSwitch(props) {
  const [value, setValue] = useState(false);
  useEffect(() => {
    if (value === true) {
      props.handleValues("premium");
    } else {
      props.handleValues("basic");
    }
  }, [value]);

  return (
    <Grid item xs={12} md={12}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <span className={"preLabel"}>{props.leftLabel}</span>
        <FormControlLabel
          control={
            <Switch
              className={props.className}
              checked={value}
              onChange={(e) => setValue(e.target.checked)}
              name={props.name}
              id={props.id}
            />
          }
          label={props.label}
        />
        <span className={"preLabel"}>{props.rightLabel}</span>
      </Box>
    </Grid>
  );
}
export default DinamicSwitch;
