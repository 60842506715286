import { ButtonDS, Typography } from "get-life-storybook-ts";
import IdCardBiometryController from "./idCardBiometry.controller";

const IdCardBiometry = () => {

    const {
        isDocumentSelected,
        neededDocuments,
        selectDocument,
        translate,
        urlBiometry
    } = IdCardBiometryController();

    return (
        <>
            {
                !isDocumentSelected ? (
                    <div className="flex flex-col gap-5">
                        <Typography
                            text={translate("idCard.biometry.title")}
                            variant="H3"
                            weight="700"
                        />
                        {neededDocuments && neededDocuments.length > 0 && neededDocuments.map((doc, index) => (
                            <ButtonDS
                                label={translate(`idCard.biometry.${doc}`)}
                                onClick={() => selectDocument(doc)}
                                className="w-full"
                                key={index}
                            ></ButtonDS>
                        ))}
                    </div>
                ) : (
                    <div></div>
                )
            }
        </>
    )

}

export default IdCardBiometry;