import { useContext, useState } from "react";
import {
  add365DaysToDate,
  calculateDiffDaysAndReturnDiff,
  formatDate,
} from "../../../../../../utils/Dates.utils";
import numberFormat from "../../../../../../utils/Utils";
import { I18nContext } from "../../../../../../context/i18n.context";

const ModalWithdrawalController = (userData: any, signatureDate: any) => {
  const lang = process.env.REACT_APP_DOMAIN_LOCALE!;
  const {
    state: { translate },
  } = useContext(I18nContext);
  const daysFromSignatureDay = calculateDiffDaysAndReturnDiff(
    signatureDate?.split("/")?.reverse()?.join("-") || 0
  );
  const [displayingAdvice, setDisplayingAdvice] =
    useState(false);

  const yearAfterSignatureDate = add365DaysToDate(
    signatureDate?.split("/")?.reverse()?.join("-") || 0
  );

  const getWithdrawalPeriod: { [key: string]: boolean } = {
    fr: daysFromSignatureDay < 30,
    default: false
  };

  const getCancellationPeriod: { [key: string]: boolean } = {
    fr: daysFromSignatureDay > 30 && daysFromSignatureDay < 365,
    default: false
  };

  const isWithdrawalPeriod = getWithdrawalPeriod[lang] || getWithdrawalPeriod.default;
  const isCancellationPeriod = getCancellationPeriod[lang] || getCancellationPeriod.default;

  const getPolicyDetails = (): [
    {
      label: string;
      value: string;
    }
  ] => {
    const policyDetails: any = [];
    const { coverage, policyNumber, price, lead } = userData[0];
    const { name, lastName, lastName2, birthdate, capital, purchasePeriod } = lead || {
      name: "",
      lastName: "",
      lastName2: "",
      birthdate: "",
      capital: "",
    };

    const fullName = [];
    if(name) fullName.push(name)
    if(lastName) fullName.push(lastName)
    if(lastName2) fullName.push(lastName2)

    policyDetails.push({
      label: translate("dashboard.policy.cancellation.name"),
      value: fullName.join(" "),
    });
    policyDetails.push({
      label: translate("dashboard.policy.cancellation.dateOfBirth"),
      value: formatDate(birthdate),
    });
    policyDetails.push({
      label: translate("dashboard.policy.cancellation.capital"),
      value: numberFormat(capital, "capital"),
    });
    policyDetails.push({
      label: translate("dashboard.policy.cancellation.coverage"),
      value: translate(`dashboard.policy.cancellation.${coverage}`),
    });
    policyDetails.push({
      label: translate("dashboard.policy.cancellation.policyNumber"),
      value: policyNumber,
    });
    policyDetails.push({
      label: translate("dashboard.policy.cancellation.price"),
      value: `${numberFormat(price)} ${translate(
        `dashboard.policy.cancellation.${purchasePeriod}`
      )}`,
    });

    return policyDetails;
  };

  const toggleDisplayingAdvice = () =>
    setDisplayingAdvice(!displayingAdvice);

  const getModalTitle = () => {
    if (displayingAdvice && lang === "fr" && isCancellationPeriod)
      return `${translate(
        "dashboard.policy.cancellation.withdrawalCancelation.title"
      )} ${yearAfterSignatureDate}`;

    if (displayingAdvice && lang === "fr" && isWithdrawalPeriod)
      return translate("dashboard.policy.cancellation.withdrawalPeriod.title");

    return translate("dashboard.policy.cancellation.title");
  };

  const getModalBody = () => {
    if (displayingAdvice && lang === "fr" && isCancellationPeriod)
      return (
        <p className="dashboard__modal--content">
          {translate(
            "dashboard.policy.cancellation.withdrawalCancelation.body"
          )}
          <br />
          <br />
          {translate(
            "dashboard.policy.cancellation.withdrawalCancelation.body2"
          )}
        </p>
      );

    if (displayingAdvice && lang === "fr" && isWithdrawalPeriod)
      return (
        <p className="dashboard__modal--content">
          {translate("dashboard.policy.cancellation.withdrawalPeriod.body")}
        </p>
      );

    return (
      <>
        <ul>
          {getPolicyDetails().map((pd) => {
            const { label, value } = pd;
            return (
              <li key={label + value}>
                <b>{label}:</b> {value}
              </li>
            );
          })}
        </ul>
        <p className="dashboard__modal--content">
          {translate("dashboard.policy.cancellation.warning")}
        </p>
      </>
    );
  };

  const modalTitle = getModalTitle();
  const modalBody = getModalBody();

  return {
    translate,
    isWithdrawalPeriod,
    isCancellationPeriod,
    displayingAdvice,
    toggleDisplayingAdvice,
    modalTitle,
    modalBody
  };
};

export default ModalWithdrawalController;
