import { useEffect, useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputGetLife from "../../../../../components/Components/inputGetLife/InputGetLife";
import "./../css/profile.scss";
import SelectGetlife from "../../../../../components/Components/SelectGetlife/SelectGetlife";
import HttpLeadRepository from "../../../../../api/request/Lead/Lead.service";
import { I18nContext } from "../../../../../context/i18n.context";
import { PhonePrefix } from "../../../../../utils/InternationlChanges";

const DOMAIN = process.env.REACT_APP_DOMAIN_LOCALE;

export default function Profile(props) {
  const [form, setForm] = useState({
    city: "",
    email: "",
    lastName: "",
    door: "",
    lastName2: "",
    name: "",
    number: "",
    phone: "",
    province: "",
    addressLine: "",
    zipCode: "",
  });
  const [provinceList, setProvinceList] = useState([]);
  const [error, setError] = useState({ error: false, errorLocation: [] });
  const { token, userData } = props;
  const { lead } = userData[0];

  const leadRepository = new HttpLeadRepository(undefined, token);

  const validation = (e, field) => {
    setError({ error: false, errorLocation: [] });
    const reg = /[0-9]/;

    if (reg.test(e)) {
      const prevValue = { ...error };
      let joined = error.errorLocation.concat(field);

      setError({ ...prevValue, error: true, errorLocation: joined });
    }
  };
  const handleCity = (e) => {
    const prevState = { ...form };
    validation(e, "city");
    setForm({ ...prevState, city: e });
  };
  const handleEmail = (e) => {
    const prevState = { ...form };

    setForm({ ...prevState, email: e });
  };
  const handleName = (e) => {
    const prevState = { ...form };

    // true
    validation(e, "name");

    setForm({ ...prevState, name: e });
  };
  const handleLastName = (e) => {
    const prevState = { ...form };
    validation(e, "lastName");
    setForm({ ...prevState, lastName: e });
  };

  const handleLastName2 = (e) => {
    const prevState = { ...form };
    validation(e, "lastName2");
    setForm({ ...prevState, lastName2: e });
  };

  const handlePhone = (e) => {
    const prevState = { ...form };

    setForm({ ...prevState, phone: e });
  };

  const handleProvince = (e) => {
    const prevState = { ...form };

    setForm({ ...prevState, province: e });
  };

  const handleZipCode = (e) => {
    const prevState = { ...form };

    setForm({ ...prevState, zipCode: parseInt(e) });
  };

  const handleAdress = (e) => {
    const prevState = { ...form };

    setForm({ ...prevState, addressLine: e });
  };

  useEffect(() => {
    leadRepository.getQuestionNomenclator("province_spain").then((res) => {
      const { answers } = res;

      setProvinceList([...answers]);
    });
    setInitialValues();
  }, []);

  const setInitialValues = () => {
    setForm({
      city: lead.city,
      email: lead.email,
      lastName: lead.lastName,
      door: lead.door,
      lastName2: lead.lastName2,
      name: lead.name,
      number: lead.number,
      phone: lead.phone ? lead.phone.replace(
        PhonePrefix[DOMAIN].prefix,
        PhonePrefix[DOMAIN].replace
      ) : "",
      province: lead.province,
      addressLine: lead.addressLine,
      zipCode: lead.zipcode,
    });
  };

  const showErrors = (field) => {
    if (error.errorLocation.includes(field)) {
      return true;
    } else {
      return false;
    }
  };

  const {
    state: { translate },
  } = useContext(I18nContext);

  return (
    <div className="dashboard-profileContainer">
      <p className="dashboard-title">{translate("dashboard.profile.profile")}</p>
      <div className="dashboard-formContainer">
        <div className="dashboard-headerEditContainer">
          <p>{translate("dashboard.profile.personalData")}</p>
        </div>
        <Grid
          container
          spacing={3}
          direction="row"
          style={{ paddingTop: "46px", paddingLeft: "29px" }}
          className="dashboard-profileInputContainer"
        >
          <Grid item xs={12} sm={4} md={4}>
            <InputGetLife
              className="dashboard-profileName"
              placeholder={translate("dashboard.profile.name")}
              handleValues={handleName}
              disabled={true}
              answer={form.name}
              contentType="text"
            />
            {showErrors("name") && (
              <p
                style={{
                  fontSize: "12px",
                  color: "red",
                  paddingTop: "5px",
                }}
              >
                {translate("dashboard.profile.nameError")}              
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InputGetLife
              className="dashboard-profileLastName"
              placeholder={translate("dashboard.profile.lastName")}
              handleValues={handleLastName}
              disabled={true}
              answer={form.lastName}
              contentType="text"
            />
            {showErrors("lastName") && (
              <p
                style={{
                  fontSize: "12px",
                  color: "red",
                  paddingTop: "5px",
                }}
              >
                {translate("dashboard.profile.nameError")}
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InputGetLife
              className="dashboard-profileLastName2"
              placeholder={translate("dashboard.profile.surname")}
              handleValues={handleLastName2}
              disabled={true}
              answer={form.lastName2}
              contentType="text"
            />
            {showErrors("lastName2") && (
              <p
                style={{
                  fontSize: "12px",
                  color: "red",
                  paddingTop: "5px",
                }}
              >
                {translate("dashboard.profile.nameError")}
              </p>
            )}
          </Grid>
          <Grid item xs={12} lg={12}>
            <p className="dashboard-labelProfile"> 
              {translate("dashboard.profile.email")}
            </p>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <InputGetLife
              placeholder="Email"
              contentType="text"
              handleValues={handleEmail}
              disabled={true}
              answer={form.email}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <p className="dashboard-labelProfile"> 
            {translate("dashboard.profile.address")}
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <InputGetLife
              className="dashboard-profileAdressLine"
              placeholder={translate("dashboard.profile.street")}
              handleValues={handleAdress}
              disabled={true}
              answer={form.addressLine}
              contentType="text"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <InputGetLife
              style={{ marginTop: "20px" }}
              placeholder={translate("dashboard.profile.cp")}
              handleValues={handleZipCode}
              disabled={true}
              answer={form.zipCode}
              contentType="number"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <InputGetLife
              style={{ marginTop: "20px" }}
              placeholder={translate("dashboard.profile.city")}
              handleValues={handleCity}
              disabled={true}
              answer={form.city}
              contentType="text"
            />
            {showErrors("city") && (
              <p
                style={{
                  fontSize: "12px",
                  color: "red",
                  paddingTop: "5px",
                }}
              >
                {translate("dashboard.profile.nameError")}
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={5}>
            <FormControl variant="outlined">
              <SelectGetlife
                toMap={provinceList}
                handleValues={handleProvince}
                default={form.province}
                placeholder={translate("dashboard.profile.province")}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <p className="dashboard-labelProfilePhone">
              {translate("dashboard.profile.phone")}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <InputGetLife
              style={{ marginTop: "20px" }}
              placeholder="686774643"
              handleValues={handlePhone}
              disabled={true}
              answer={form.phone}
              contentType="number"
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
