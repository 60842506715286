import React, { useState } from "react";
import { TextInput, Button as ButtonGetlife } from "get-life-storybook-ts";
import "./css/otpSent.scss";
import { FormControl } from "@material-ui/core";

import { I18nContext } from "../../context/i18n.context";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { urlsLife5 } from "../../utils/InternationlChanges";

export default function OtpDisabled() {
  const {
    state: { translate },
  } = React.useContext(I18nContext);

  const DOMAIN = process.env.REACT_APP_DOMAIN_URL;

  const [userEmail, setUserEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const leadRepository = new HttpLeadRepository();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    leadRepository.loginOtp(userEmail).then((r) => {
      setEmailSent(true);
    });
  };
  const handleEmail = (e) => setUserEmail(e);

  const closePage = () => {
    window.location.replace(`${DOMAIN}`);
  };

  return (
    <div className="loginPage">
      {!emailSent ? (
        <main className="loginPage--main--otp">
          <h1 className="loginPage--main__title">
            {translate("login.otpDisabled.title")}
          </h1>
          <h6 className="loginPage--main__subtitle">
            {translate("login.otpDisabled.subtitle")}
          </h6>
          <h6 className="loginPage--main__subtitle2">
            {translate("login.otpDisabled.text")}
          </h6>
          <form className="loginPage--main__form">
            <FormControl className="otp--form__input">
              <div class="otp--form__input">
                <TextInput
                  label="Email"
                  initialValue={userEmail}
                  onValueChange={handleEmail}
                />
              </div>
            </FormControl>
            <FormControl className="form--btn">
              <ButtonGetlife
                label={translate("login.otpDisabled.button")}
                type="submit"
                content={translate("login.otpDisabled.button")}
                disabled={userEmail === ""}
                onClick={handleSubmit}
              />
            </FormControl>
          </form>
        </main>
      ) : (
        <main className="changePassword--main">
          <div className="changePassword--main__imgElipse">
            <img
              src="https://storage.googleapis.com/getlife-bucket-1/public/image/v2/email_icon.png"
              alt="Logo de Getlife"
              title="Logo de Getlife"
              className="changePassword--main__img"
            />
          </div>
          <h1 className="changePassword--main__title">
            {translate("login.otpSent.title")}
          </h1>
          <h6 className="changePassword--main__subtitle">
            {translate("login.otpSent.subtitle")}
          </h6>
          <a href={urlsLife5[DOMAIN]} className="changePassword--main__back">
            <img
              src="https://storage.googleapis.com/getlife-bucket-1/public/image/v2/arrow-left-blue.svg"
              alt="arrow"
            ></img>
            {translate("login.otpSent.back")}
          </a>
        </main>
      )}
    </div>
  );
}
