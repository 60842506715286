import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import selectGetlifeStyle from "./SelectGetlifeStyle.js";
import numberFormat from "../../../utils/Utils";

export default function SelectGetlife(props) {
  const { limit } = props;
  const [capitalSelect, setCapitalSelect] = useState(null);
  const [selected, setSelected] = useState(0);
  let capitalRangueMin, capitalRangueMax, capitalRangueStep;
  if (props.capitalLimits !== false && props.capitalLimits !== undefined) {
    capitalRangueMin = props.capitalLimits.capitalRangueMin;
    capitalRangueMax = props.capitalLimits.capitalRangueMax;
    capitalRangueStep = props.capitalLimits.capitalRangueStep;
  }

  useEffect(() => {
    setCapitalSelect(formatCapital());
  }, [props.capitalLimits]);

  const formatCapital = () => {
    return Array.from(
      { length: (capitalRangueMax - capitalRangueMin) / capitalRangueStep + 1 },
      (_, i) => ({
        label: numberFormat(
          (capitalRangueMin + i * capitalRangueStep).toString(),
          "capital"
        ),
        value: capitalRangueMin + i * capitalRangueStep,
      })
    ).reverse();
  };

  const ValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const nbValues = getValue().length;
    setSelected(nbValues);
    if (nbValues < 2 || nbValues === limit) {
      return (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {...props}>
        {`${nbValues} seleccionadas`}
      </components.ValueContainer>
    );
  };

  return (
    <div className="inputGetLife-container">
      {props.content && (
        <label
          style={
            props.actualUrl !== "/coverage/1"
              ? { width: "640px" }
              : { width: "auto" }
          }
        >
          {props.content}
        </label>
      )}

      {props.type !== "capital" && (
        <Select
          className={
            props.className
              ? "SelectGetlife2 mediumWidth " + props.className
              : "SelectGetlife2 mediumWidth "
          }
          options={selected === limit ? [] : props.toMap}
          isMulti={props.type === "select_multiple"}
          isClearable={false}
          isSearchable
          isDisabled={props.disabled}
          value={
            props.toMap ? props.toMap.find((o) => o.value == props.default) : ""
          }
          styles={selectGetlifeStyle}
          hideSelectedOptions={false}
          components={{ ValueContainer }}
          onChange={(e) => props.handleValues(e)}
          placeholder={props.placeholder ? props.placeholder : ""}
          noOptionsMessage={() => {
            return selected === limit
              ? "Selecciona " + limit + " como máximo"
              : "No options available";
          }}
        />
      )}

      {props.type === "capital" && capitalSelect !== null && (
        <Select
          className="SelectGetlife2"
          options={capitalSelect}
          isMulti={props.type === "select_multiple"}
          isClearable={false}
          isSearchable
          value={capitalSelect.find((o) => o.value == props.default)}
          styles={selectGetlifeStyle}
          hideSelectedOptions={false}
          onChange={(e) => props.handleValues(e.value)}
          components={{ ValueContainer }}
          placeholder={props.placeholder ? props.placeholder : ""}
        />
      )}
    </div>
  );
}
