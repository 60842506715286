import { useState, useContext, useEffect } from "react";
import { I18nContext } from "../../../../../../context/i18n.context";
import {
  validatePhoneFR,
  validationsGlobal,
} from "../../../../../../utils/validations";
import { PhonePrefix } from "../../../../../../utils/InternationlChanges";
import { LanguageT } from "../../../../../../context/GetLifeContext.context";
import { ErrorResponse, isITPhone } from "get-life-storybook-ts/lib/components/Validations";
import { DOMAIN } from "../../../../../../constants/Global";

const MIN_LENGTH = 2;

type PersonalDataI = {
  name: string;
  lastName?: string;
  lastName2?: string;
  phone: string;
};

type ValidatedDataT = "name" | "lastName" | "phone";

type ErrorDataI = {
  [key in ValidatedDataT]?: ErrorResponse | undefined;
};

const PersonalDataController = ({ userData, saveData }: any) => {
  const [name, setName] = useState(userData.lead?.name);
  const [lastName, setLastName] = useState(userData.lead?.lastName);
  const [lastName2, setLastName2] = useState(userData.lead?.lastName2);
  const [phone, setPhone] = useState(
    userData.lead?.phone
      ? userData.lead?.phone.replace(
          PhonePrefix[DOMAIN as LanguageT].prefix,
          PhonePrefix[DOMAIN as LanguageT].replace
        )
      : ""
  );

  const [error, setError] = useState<ErrorDataI>({
    name: { error: false, message: "" },
    lastName: { error: false, message: "" },
    phone: { error: false, message: "" },
  });

  const validations = {
    name: [
      (value: string) =>
        validationsGlobal({
          value,
          translate,
          min: MIN_LENGTH,
          field: "name",
        }),
    ],
    lastName: [
      (value: string) =>
        validationsGlobal({
          value,
          translate,
          min: MIN_LENGTH,
          field: "lastName",
        }),
    ],
    phone:
      DOMAIN === "es" || DOMAIN === "pt"
        ? [
            (value: string) =>
              validationsGlobal({
                value,
                translate,
                field: "phone",
              }),
          ]
        : [],
  };

  const {
    state: { translate },
  } = useContext(I18nContext);

  const validData = (data: PersonalDataI): any[] => {
    const keyErrors = Object.keys(error!) as ValidatedDataT[];
    return keyErrors.map((key) => {
      if (!data[key]) {
        return {
          [key]: {
            error: true,
            message: translate(`error.personalData.${key}`),
          },
        };
      }
      return null;
    });
  };

  const sendPersonalData = () => {
    const data: PersonalDataI = {
      name: name,
      lastName: lastName,
      lastName2: lastName2,
      phone: phone,
    };

    if (validData(data).every(key => !key)) {
      saveData(data);
      setError({
        name: { error: false, message: "" },
        lastName: { error: false, message: "" },
        phone: { error: false, message: "" },
      });
    } else {
      let resultError: ErrorDataI | null = null;
      validData(data).forEach((err) => {
        resultError = { ...resultError, ...err };
      });
      setError({ ...error, ...resultError! });
    }
  };

  useEffect(() => {
    (async () => {
      if (DOMAIN === "fr" && phone.length > 8) {
        const errorPhone = await validatePhoneFR({
          value: phone,
          errorGlobal: error.phone!,
          translate,
        });
        setError({ ...error, phone: errorPhone });
      }
      if (DOMAIN === "it" && phone.length >= 6) {
        const errorPhone = isITPhone({
          value: phone,
          message: translate("error.notPhone"),
        });
        setError({ ...error, phone: errorPhone });
      }
    })();
  }, [phone]);

  return {
    error,
    lastName,
    lastName2,
    name,
    phone,
    sendPersonalData,
    setLastName,
    setLastName2,
    setName,
    setPhone,
    translate,
    validations,
  };
};


export default PersonalDataController