import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import numberFormat from "../../../../utils/Utils";
import "../beneficiariesControlCapital.scss";
import { GetlifeContext } from "../../../../context/GetLifeContext.context";
import HttpLeadRepository from "../../../../api/request/Lead/Lead.service";
import { I18nContext } from "../../../../context/i18n.context";
import { ButtonDS, TextInput } from "get-life-storybook-ts";

export default function BeneficiariesControlCapital(props) {
  const { leadId, token, setLoading, setProgressBar } =
    useContext(GetlifeContext);
  const navigate = useNavigate();

  const [beneficiaries, setBeneficiaries] = useState(null);
  const [totalCapital, setTotalCapital] = useState(null);
  const [availableCapital, setAvailableCapital] = useState(null);

  const leadRepository = new HttpLeadRepository(leadId, token);

  const {
    state: { translate },
  } = useContext(I18nContext);

  const handleBeneficiaries = (e, index) => {
    let newAvailableCapital = 0;
    const newBeneficiaries = [...beneficiaries];
    newBeneficiaries[index]["capital"] = e;
    setBeneficiaries(newBeneficiaries);
    newBeneficiaries.map((beneficiary, i) => {
      if (!isNaN(parseInt(beneficiary.capital))) {
        newAvailableCapital += parseInt(beneficiary.capital);
      }

      return newAvailableCapital;
    });
    setAvailableCapital(totalCapital - newAvailableCapital);
  };

  const isButtonDisabled = () => {
    let disabled = false;
    beneficiaries?.map((beneficiary, index) => {
      if (beneficiary.capital === "") {
        disabled = true;
      }
    });
    if (availableCapital !== 0) disabled = true;
    return disabled;
  };

  useEffect(() => {
    setLoading(true);
    setProgressBar(99);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    leadRepository.getBeneficiaries().then((response) => {
      if (response.code === 401) {
        navigate("/login");
      }
      setBeneficiaries(response.beneficiaries);
      setTotalCapital(response.totalCapital);
      setAvailableCapital(response.availableCapital);
      setLoading(false);
    });
  }, []);

  const send = async () => {
    let data = [];
    beneficiaries?.map((beneficiary, index) => {
      data.push({ id: beneficiaries.id, capital: beneficiaries.capital });
    });
    await leadRepository
      .updateBeneficiariesCapital(beneficiaries)
      .then((res) => {
        if (res.code === 401) {
          navigate("/login");
        } else if (res === "OK") {
          navigate("/coverage/7");
        }
      });
  };

  return (
    <>
      <div className={"questions-outerContainer beneficiariesControlCapital"}>
        <div className={"questions-container"}>
          <div class="formBuilder-container">
            <div className={"questions-innerContainer"}>
              <div className="title-resume">
                <p id="avatar-subtitle" style={{ paddingTop: "10px" }}>
                  {translate("beneficiaries.control.title")}
                </p>
                <p style={{ color: "#2C2302", paddingTop: "15px" }}>
                  <b id="avatar-title">
                    {translate("beneficiaries.control.subtitle")}
                  </b>
                </p>
              </div>
              {beneficiaries?.map((beneficiary, index) => (
                <>
                  <div className="beneficiary-row">
                    <div className="beneficiary-name">
                      <span className="name">
                        {beneficiary.name
                          ? beneficiary.name
                          : beneficiary.bankName
                          ? beneficiary.bankName
                          : beneficiary.businessName
                          ? beneficiary.businessName
                          : ""}
                      </span>
                      <span className="type">
                        {beneficiary.type === "physicalPerson"
                          ? translate("beneficiaries.control.physical")
                          : beneficiary.type === "legalPerson"
                          ? translate("beneficiaries.control.legal")
                          : beneficiary.type === "bankingEntity"
                          ? translate("beneficiaries.control.bank")
                          : ""}
                      </span>
                    </div>
                    <div className="beneficiary-capital">
                      <TextInput
                        initialValue={beneficiaries[index]?.capital}
                        label={translate("beneficiaries.control.capital")}
                        onValueChange={(e) => handleBeneficiaries(e, index)}
                        placeholder="50000"
                      />
                    </div>
                  </div>
                </>
              ))}
              <div className="capital-resume">
                {translate("beneficiaries.control.disponible")}
                {numberFormat(availableCapital, "capital")}{" "}
                {translate("beneficiaries.control.total")}
                {numberFormat(totalCapital, "capital")}
              </div>
              <div className="updateCapital-buttonContainer">
                <div container alignItems="center" justifyContent="left">
                  <ButtonDS
                    label={translate("continuar")}
                    onClick={send}
                    disabled={isButtonDisabled()}
                  />
                </div>
                {availableCapital !== 0 && (
                  <>
                    <div className="not-total-asigned inputGetLife-errorMessage">
                      {translate("beneficiaries.control.error")}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
