import {
  Alert,
  GlobalIcon,
  Header,
  Loader,
  PaymentFooter,
  Toast,
} from "get-life-storybook-ts";
import LayoutController from "./Layout.controller";
import WeCallYou from "../../components/WeCallYou/WeCallYou";
import BannerEnvironment from "../../components/BannerEnvironment/BannerEnvironment";
import { DOMAIN, ENV } from "../../constants/Global";


const Layout = ({ children }: any) => {
  const {
    closeButton,
    closePage,
    loading,
    multiCountries,
    nodeElementAlert,
    notification,
    progressBar,
    showFooter,
    showHeader,
    translate,
    toast,
    toastIsOpen
  } = LayoutController();

  const {
    phone,
    handleCall,
    logo,
    logoSize,
    secondaryLogo,
    logoClassname,
    secondaryLogoClassname,
  } = multiCountries();

  return (
    <>
      {process.env.REACT_APP_ENVIROMENT !== "PRD" && (
        <BannerEnvironment
          environment={ENV}
          locale={DOMAIN}
        />
      )}

      <WeCallYou />

      {notification && (
        <Alert
          buttonAlertConfig={notification.buttonAlertConfig}
          close={notification.close}
          color={notification.color}
          iconName={notification.iconName}
          message={notification.message}
          positionX={notification.positionX}
          positionY={notification.positionY}
          ref={nodeElementAlert}
          time={notification.time}
          type={notification.type}
        />
      )}
      {showHeader && (
        <Header
          closeButton={closeButton}
          progressAmount={progressBar}
          showPhoneNumber
          phoneNumber={phone}
          handleClick={handleCall}
          closeButtonText={translate("loginPageOtp.button.close")}
          closeButtonOnClick={() => closePage()}
          logoIconName={
            logo && (
              <span className={logoClassname}>
                <GlobalIcon iconName={logo} size={logoSize} />
              </span>
            )
          }
          secondaryIconName={
            secondaryLogo && (
              <span className={secondaryLogoClassname}>
                <GlobalIcon iconName={secondaryLogo} />
              </span>
            )
          }
        />
      )}
      {
        DOMAIN === "it" && (
          <Header
          closeButton={undefined}
          progressAmount={undefined}
          showPhoneNumber={false}
          phoneNumber={undefined}
          handleClick={undefined}
          closeButtonText={undefined}
          closeButtonOnClick={undefined}
          logoIconName={
           
              <span className={`${logoClassname} pt-[16px]`}>
                <GlobalIcon iconName={"LogoLife5AllianzCollaborazione"} size={"IT"} />
              </span>
           
          }
          secondaryIconName={
            undefined
          }
          showShadow={false}
          isSticky={false}
        />
        )
      }
      <Toast
        open={toastIsOpen}
        offset={{
          top: "80px"
        }}
        {...toast}
      />

      <div className="layout">
        {loading && (
          <div className="h-loader">
            <Loader loading={loading} />
          </div>
        )}
        {children}
      </div>
      {showFooter && <PaymentFooter text={translate("paymentFooter.text")} />}
      {/* {navbarStickyInfo.show && (
        <NavbarSticky
          position={navbarStickyInfo.position}
          content={navbarStickyInfo.content as any}
          actions={navbarStickyInfo.actions as any}
        />
      )} */}
    </>
  );
};

export default Layout;
