import { Elements } from "@stripe/react-stripe-js";
import {
  ButtonDS,
  CTA,
  SecureEncryptor,
  StepBar,
  Tabs,
  Typography,
} from "get-life-storybook-ts";
import CreaditCard from "get-life-storybook-ts/lib/components/Icons/CreditCard";
import { StripeProvider } from "../../../../context/StripeContext.context";
import PaymentAccordion from "../PaymentGateway/components/PaymentAccordion/PaymentAccordion.component";
import ResumePrice from "../PaymentGateway/components/ResumePrice/ResumePrice.component";
import PaymentPageController from "./PaymentPage.controller";
import "./PaymentPage.scss";
import { DOMAIN } from "../../../../constants/Global";
import { LanguageT } from "../../../../context/GetLifeContext.context";
import { GlobalYearlyPayments } from "../../../../utils/InternationlChanges";

const PaymentPageChallenger = ({
  checkOut,
  couponInfo,
  handleAddCoupon,
  handlePaymentMethod,
  handleRemoveCoupon,
  handleSetupIntent,
  handlePurchasePeriod,
  product,
  setupIntent,
  validateCoupon,
  onConditionsClick,
  paymentLocation,
}: any) => {
  const {
    closeNameForm,
    goToUpsell,
    handleCheckbox,
    handleCheckbox2,
    handleIsLoading,
    handleStripeStatus,
    isButtonDisabled,
    isMobile,
    leadId,
    navigateTo,
    openBox,
    payConfirm,
    payConfirm2,
    saveData,
    handleCallMe,
    stripePromise,
    stripeInputStatus,
    token,
    translate,
    userData
  } = PaymentPageController({
    checkOut,
    handleSetupIntent,
    handlePurchasePeriod,
    product
  });

  const listNavTabs: any = [
    {
      id: "card",
      title: translate("paymentChallenger.tab.card"),
      activeTab: "card",
      icon: <CreaditCard />,
    },
  ];

  const listTabsContent: any = [
    {
      id: "card",
      children: (
        <>
          {" "}
          {isMobile && <div className="line"></div>}
          <PaymentAccordion
            checkOut={checkOut}
            closeNameForm={closeNameForm}
            handleCheckbox={handleCheckbox}
            handleCheckbox2={handleCheckbox2}
            handleIsLoading={handleIsLoading}
            handlePaymentMethod={handlePaymentMethod}
            handleStripeStatus={handleStripeStatus}
            isButtonDisabled={isButtonDisabled()}
            leadId={leadId}
            navigateTo={navigateTo}
            openBox={openBox}
            payConfirm={payConfirm}
            payConfirm2={payConfirm2}
            product={product}
            saveData={saveData}
            setupIntent={setupIntent}
            stripeInputStatus={stripeInputStatus}
            token={token}
            translate={translate}
            userData={userData || checkOut}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="paymentChallenger">
        <div className="paymentChallenger__title">
          <Typography
            variant="H1"
            weight="700"
            color="purple"
            text={translate("paymentChallenger.title")}
          />
        </div>
        <div className="paymentChallenger__body">
          <StepBar
            step={[
              {
                active: true,
                text: translate("newUpsell.stepbar.text1"),
                activePass: true,
              },
              {
                active: true,
                text: translate("newUpsell.stepbar.text2"),
                activePass: false,
              },
              {
                active: false,
                text: translate("newUpsell.stepbar.text3"),
                activePass: false,
              },
            ]}
          />
          <div className="paymentChallenger__body__sections">
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: setupIntent.clientSecret,
              }}
            >
              <div className="paymentChallenger__body__sections--paymentMethod">
                <Typography
                  variant="H3"
                  weight="700"
                  text={translate("paymentChallenger.paymentMethod.title")}
                />
                <SecureEncryptor />
                <Tabs
                  listNavTabs={listNavTabs}
                  listTabsContent={listTabsContent}
                  onChange={() => {}}
                />

                <div className="paymentChallenger__body__sections--paymentMethod--backbutton">
                  <ButtonDS
                    label={translate("payment.goback")}
                    onClick={() => goToUpsell()}
                    buttonType="secondary"
                    ghost={true}
                    leftIcon="ArrowBackIcon"
                    className="w-full"
                  />
                </div>
                <div className="paymentChallenger__body__sections--paymentMethod--final">
                  <div className="paymentChallenger__body__sections--paymentMethod--final--line"></div>
                  <Typography
                    variant="Body"
                    weight="700"
                    text={translate("paymentChallenger.nextSteps.title")}
                  />
                  <Typography
                    variant="Body"
                    text={translate("paymentChallenger.nextSteps.text")}
                  />
                </div>
              </div>
              <div
                className={
                  validateCoupon
                    ? "paymentChallenger__body__sections--resumePrice couponTopStyle"
                    : "paymentChallenger__body__sections--resumePrice"
                }
              >
                <StripeProvider
                  handleIsLoading={handleIsLoading}
                  setupIntent={setupIntent}
                  handlePaymentMethod={handlePaymentMethod}
                  leadId={leadId}
                  token={token}
                  carrier={checkOut?.lead.carrier}
                  product={product}
                  navigate={navigateTo}
                >
                  <ResumePrice
                    product={product}
                    checkoutData={checkOut}
                    isAvailableToggle={GlobalYearlyPayments[DOMAIN as LanguageT]}
                    translate={translate}
                    validateCoupon={validateCoupon}
                    handleAddCoupon={handleAddCoupon}
                    handleRemoveCoupon={handleRemoveCoupon}
                    handlePurchasePeriod={handlePurchasePeriod}
                    couponInfo={couponInfo}
                    isButtonDisabled={isButtonDisabled()}
                    onConditionsClick={onConditionsClick}
                    paymentLocation={paymentLocation}
                  />
                </StripeProvider>
              </div>
            </Elements>
          </div>
        </div>
      </div>
      <div className="paymentChallenger__final">
        <div className="paymentChallenger__final--CTA">
          <CTA
            title2CTA={translate("newUpsell.ctaSection.title2CTA")}
            titleCTA={translate("newUpsell.ctaSection.titleCTA")}
            paragraph1CTA={translate("newUpsell.ctaSection.paragraph1CTA")}
            paragraph2CTA={translate("newUpsell.ctaSection.paragraph2CTA")}
            textButton={translate("newUpsell.ctaSection.textButton")}
            phone={process.env.REACT_APP_TEL || ""}
            onClick={() => handleCallMe()}
            classNameCTA={"paymentChallenger__final--CTA--Inside"}
          />
        </div>
      </div>
    </>
  );
};

export default PaymentPageChallenger;
