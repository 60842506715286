import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import "./dinamicCheckBox.scss";

const DinamicCheckBox = (props) => {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{
          marginTop: "25px",
          borderRadius: "8px",
          backgroundColor: props.backgroundColor ? props.backgroundColor : null,
          width: "414px",
        }}
      >
        <FormControlLabel
          style={{ marginLeft: "5px" }}
          control={
            <Checkbox
              className="greenCheckbox"
              value={props.value ? props.value : ""}
              color="default"
              onChange={(e) => props.handleOnChange(e.target.checked)}
              id={"policy"}
              name="policy"
            />
          }
          label={
            <>
              {props.type === "confirm" ? (
                props.label
              ) : (
                <p className="dinamicCheckBox-terms">
                  {props.translate("dinamicCheckBox.terms")}
                  <a
                    href="https://life5.es/politica-privacidad-resumida/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <b> {props.translate("dinamicCheckBox.terms2")}</b>
                  </a>
                </p>
              )}
            </>
          }
        />
      </Grid>
    </>
  );
};

export default DinamicCheckBox;
