/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { I18nContext } from "../../context/i18n.context";
import {
  prepareDataFromDataLayerUpsellClick,
} from "../../utils/prepareDataFromDataLayer";
import { useLocation, useNavigate } from "react-router";
import {
  CoverageT,
  Iteration,
  ProductI,
} from "../../api/request/Lead/Interfaces/ProductInterface.response";
import { GetLifeTrackerContext } from "../../context/GetLifeTracker.context";
import { DayAvailable, MonthAvailable } from "../../utils/InternationlChanges";
import { useDevice, GlobalIcon } from "get-life-storybook-ts";
import { coverList, notCoverList } from "../../utils/dataForNewUpsell";
import { datePickerI } from "get-life-storybook-ts/lib/molecules/CustomPolicy/CustomPolicy";
import { DataI } from "../../api/request/Lead/Interfaces/DataInterface.response";
import apiGetDownloadableFiles from "../../api/request/apiGetDownloadableFiles";
import { StatusContext } from "../../context/StatusContext";
import { DOMAIN, ENV } from "../../constants/Global";

const STORAGE = "https://storage.googleapis.com/getlife-bucket-1/public";

const IPID = `${STORAGE}/pdf/IPID-GTD.pdf`;

export interface UpsellControllerI {
  product: ProductI;
  checkOut: DataI;
  setUpsellData: Dispatch<SetStateAction<DataI | null>>;
  setUpsellProduct: Dispatch<SetStateAction<ProductI | null>>;
}

const UpsellController = ({
  product,
  checkOut,
  setUpsellData,
  setUpsellProduct,
}: UpsellControllerI) => {
  const {
    handleCallMe,
    leadId,
    token,
    domain,
    backFlag,
    isABtesting,
    sendEventsNewLead,
    setSendEventsNewLead,
    utm_campaign,
    utm_source,
    utm_content,
    utm_medium,
    utm_term,
    utm_test,
    gclid,
    fbclid,
    gtf_campaign,
    tid,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion,
    nodeMaxCapitalModal
  } = useContext(GetlifeContext);
  const { handleTrackerQuestion, identifyFS } = useContext(GetLifeTrackerContext);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const {getStatus} = useContext(StatusContext);

  const location = useLocation();
  const paymentLocation = location?.pathname.split("/")[1];

  const [capital, setCapital] = useState(product.capital);
  const [ipa, setIpa] = useState(product.coverage);
  const [newIpa, setNewIpa] = useState<CoverageT | boolean>(false);

  const [upGradedOpen, setUpGradedOpen] = useState(product.upgraded.upgraded);
  const [changeDone, setChangeDone] = useState(false);
  const [capitalAdded, setCapitalAdded] = useState(false);
  const [capitalAddedAmount, setCapitalAddedAmount] = useState(0);

  const [isPosition, setIsPosition] = useState(false);

  const [priceChange, setPriceChange] = useState(false);

  const [downloadableFiles, setDownloadableFiles] = useState<[{href: string, label: string}] | undefined>();
  const [downloadableLoading, setDownloadableLoading] = useState<boolean>(false);

  const { isMobile } = useDevice();

  const refIPA = useRef<any>();

  let lastKnownScrollPosition = 0;
  let ticking = false;

  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const leadRepository = new HttpLeadRepository(leadId, token);

  const today = new Date();
  const aMonthMore = new Date().setMonth(today.getMonth() + 1);

  const handleUpgradedClose = () => {
    setUpGradedOpen(false);
  };

  const handleIpaChange = (newIpa: any) => {
    setNewIpa(newIpa);
    setIpa(newIpa);
  };

  const handleProduct = async (newCapital: number, newIpa: any) => {
    setCapital(newCapital);
    setIpa(newIpa);
    setPriceChange(true);
    setDownloadableLoading(true);
    try {
      await leadRepository.updateProductUpsell({
        capital: newCapital,
        ipa,
        offer: product.offer,
      });
      const promises = await Promise.all([
        leadRepository.getCheckoutProduct(),
        leadRepository.getCheckoutData(),
      ]);

      setUpsellProduct(promises[0]);
      setUpsellData(promises[1]);
      setPriceChange(false);

      if (promises[0].newCapital) {
        nodeMaxCapitalModal.current?.handleShowPortal();
        setCapital(promises[0].newCapital);
      }
    } catch (error) {
      setDownloadableLoading(false);
    }
  };

  const navigate = useNavigate();

  const selectPlan = () => {
    leadRepository
      .updateProductUpsell({
        capital,
        ipa,
        offer: product.offer,
      })
      .then(() => {
        const dataLayerClick = prepareDataFromDataLayerUpsellClick(
          checkOut,
          product,
          0
        );
        handleTrackerQuestion({
          type: "GA",
          data: {
            event: "productClick",
            ecommerce: dataLayerClick,
          },
        });
        if (DOMAIN === "it" && ENV === "PRD") {
          navigate("/agent-confirmation")
        } else {
          navigate(`/${paymentLocation}/3`);
        }
      });
  };

  const correctPrice = (property: keyof Iteration) => {
    return product[property];
  };

  const showStickyBanner = (scrollPos: any) => {
    if (scrollPos > 1156 && scrollPos < 3775) {
      !isPosition && setIsPosition(true);
    } else {
      isPosition && setIsPosition(false);
    }
  };

  document.addEventListener("scroll", (e) => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        showStickyBanner(lastKnownScrollPosition);
        ticking = false;
      });

      ticking = true;
    }
  });

  const openLink = () => {
    linkRef.current?.click();
  };

  const externalLink = {
    showLink: domain === "fr",
    linkClick: openLink,
    linkText: translate("upsell.IPID.text"),
    linkRef,
    linkUrl: IPID,
  };

  const changeCapitalTooltip = (price: string) => {
    setChangeDone(!changeDone);
    handleProduct(Number(price), ipa);
    setCapitalAdded(false);
  };

  const addCapital = async () => {
    !capitalAdded &&
      setCapitalAddedAmount(product.modifiers.extracapital.amount);
    let newCapital = capitalAdded
      ? product.capital - capitalAddedAmount
      : product.capital + product.modifiers.extracapital.amount;
    setCapitalAdded(!capitalAdded);
    await handleProduct(newCapital, ipa);
  };

  const correctPricePromotion = (property: keyof Iteration) => {
    if (
      !!product.promotion &&
      checkOut?.priceSummary.regularPrice !== checkOut?.priceSummary.priceToPay
    ) {
      return product.promotion?.price;
    }

    return correctPrice(property);
  };

  const renderPriceValidation = () => {
    const date = new Date(aMonthMore);
    return `${translate("newUpsell.banner.priceValidate")} ${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;
  };

  const datePickerProps = (): datePickerI => {
    const today = new Date();
    const startDate = new Date(
      new Date(today).setDate(today.getDate() + DayAvailable[domain])
    );
    let finalDate = new Date(
      new Date().setMonth(today.getMonth() + MonthAvailable[domain])
    );
    const object = {
      initialDate: product.effectDate,
      locale: domain,
      title: translate("upsell.DatePicker.title"),
      subtext1: translate("upsell.DatePicker.subtitle1"),
      subtext2: translate("upsell.DatePicker.subtitle2"),
      subtext3: translate("upsell.DatePicker.subtitle3"),
      textToday: translate("upsell.DatePicker.textToday"),
      textTomorrow: translate("upsell.DatePicker.textTomorrow"),
      textAfterTomorrow: translate("upsell.DatePicker.textAfterTomorrow"),
      notShowIpa: true,
      onValueChange: changeInitDate,
      overlayButtonLabel: translate("productQuestions.overlay.button"),
      maxDate: finalDate.toString(),
      minDate: startDate,
    };

    return object;
  };

  const listSectionData = [
    {
      iconElement: <div><GlobalIcon iconName="ShieldCheckIcon" size="XS" color="#018565" /></div>,
      title: translate("newUpsell.listSection.cover"),
      list: coverList(translate, domain, ipa),
    },
    {
      iconElement: <div><GlobalIcon iconName="MoodConfuzedIcon" size="XS" color="#686868" /></div>,
      title: translate("newUpsell.listSection.notCover"),
      list: notCoverList(translate, domain),
    },
  ];

  const changeInitDate = (date: any) => {
    let dateFormat = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    leadRepository.updateEffectDate(dateFormat).then((res) => {
      setUpsellProduct(res);
      setCapital(res.capital);
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIROMENT !== "DEV") {
      if (!backFlag) {
        getStatus();
      }
    }
  }, []);

  useEffect(() => {
    if (newIpa) {
      setNewIpa(false);
      handleProduct(capital, ipa);
    }
  }, [ipa]);

  useEffect(() => {
    if (sendEventsNewLead === "true") {
      const dataInfo = {
        page: window.location.href,
        leadId: leadId,
        intent: undefined,
        phone: checkOut.lead.phone,
        email: checkOut.lead.email,
        calculatedCapital: checkOut.lead.capital,
        calculatedPremium: checkOut.price,
        coverage: checkOut.coverage === "premium" ? "FIPA" : "F",
        gender: checkOut.lead.gender,
        postalCode: checkOut.lead.zipcode,
        utm_campaign: utm_campaign,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_content: utm_content,
        utm_term: utm_term,
        utm_test: utm_test,
        gclid: gclid,
        fbclid: fbclid,
        platform: "App",
        entryPage: getEntryPage(),
        referrerUrl: getReferrerUrl(),
        ABTestVersion: getABTestVersion()
      };

      identifyFS({
        lead: leadId,
        data: {
          emailDomain: checkOut.lead.email && checkOut.lead.email.split("@")[1],
          email: checkOut.lead.email,
          brokerId: 1,
        },
      });

      let event = "leadCreated";
        handleTrackerQuestion({
          type: "FS",
          event,
          data: {
            birthDate: new Date(checkOut.lead.birthdate),
            ...dataInfo
          }
        });
        handleTrackerQuestion({
          type: "GA",
          data: {
            event,
            birthDate: checkOut.lead.birthdate,
            ...dataInfo
          }
        });

      event = "calculatorQuoteGenerated";
      handleTrackerQuestion({
        type: "FS",
        event,
        data: {
          birthDate: new Date(checkOut.lead.birthdate),
          ...dataInfo
        }
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          birthDate: checkOut.lead.birthdate,
          ...dataInfo
        }
      });
      handleTrackerQuestion({
        type: "tracker",
        data: {
          leadUlid: leadId,
          campaign: gtf_campaign ?? "info-app",
        },
      });

      setSendEventsNewLead("false");
    }
  }, [])

  useEffect(() => {
    const getDownloadableFiles = async () => {
      setDownloadableLoading(true);
      const files = await apiGetDownloadableFiles(leadId, translate, product.coverage);
      setTimeout(() => setDownloadableLoading(false), 1000);
      setDownloadableFiles(files as [{href: string, label: string}]);
    }
    if(leadId && translate !== undefined) getDownloadableFiles();
  }, [product])

  return {
    addCapital,
    capital,
    capitalAdded,
    capitalAddedAmount,
    changeCapitalTooltip,
    changeDone,
    correctPrice,
    correctPricePromotion,
    datePickerProps,
    domain,
    externalLink,
    handleCallMe,
    handleIpaChange,
    handleUpgradedClose,
    ipa,
    isMobile,
    isPosition,
    listSectionData,
    priceChange,
    refIPA,
    renderPriceValidation,
    selectPlan,
    setChangeDone,
    translate,
    upGradedOpen,
    checkOut,
    product,
    downloadableFiles,
    isABtesting,
    downloadableLoading
  };
};

export default UpsellController;
