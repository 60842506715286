import {
  AnimatedAlert,
  Chip,
  GlobalIcon,
  SecureEncryptor,
  ShowPrice,
  TextWithInfoIcon,
  Toggle,
  Typography,
  useDevice,
} from "get-life-storybook-ts";
import { ProductI } from "../../../../../../api/request/Lead/Interfaces/ProductInterface.response";
import numberFormat, {
  numberFormatPayment,
} from "../../../../../../utils/Utils";
import Coupon, { ConditionsPromoLink } from "../../../Coupon/Coupon.component";
import {
  CouponInfoI,
  ValidateCouponT,
} from "../../../PaymentPage/PaymentPage.controller";
import { getMessage } from "../PaymentCardContent/PaymentCardContent.component";
import { DataI } from "../../../../../../api/request/Lead/Interfaces/DataInterface.response";
import {
  formatGetlifeDate,
  validDateYear,
} from "../../../../../../utils/PrepareDataCalendar";
import { useContext, useRef } from "react";
import { GetlifeContext } from "../../../../../../context/GetLifeContext.context";
import { DateTime } from "luxon";
import BenefitsModal from "../../../../../../components/BenefitsModal/BenefitsModal";

interface ResumePriceI {
  couponInfo?: CouponInfoI;
  checkoutData?: DataI;
  handleAddCoupon: (code: string, yearlyPrice: number) => void;
  handlePurchasePeriod: (value: string) => void;
  handleRemoveCoupon: (code: string) => void;
  isAvailableToggle: boolean;
  isButtonDisabled: boolean;
  product: ProductI;
  translate: (key: string) => string;
  validateCoupon?: ValidateCouponT;
  onConditionsClick: () => void;
  paymentLocation: any;
}

export const promotionPrice = ({
  data,
  product,
}: {
  data?: DataI;
  product: ProductI;
}) => {
  if (data?.lead.purchasePeriod === "yearly") {
    if (product.promotion.yearlyRealPrice) {
      numberFormatPayment(product.promotion.yearlyRealPrice);
    }
    const resultYearly = data?.feeCollection?.reduce(
      (summ: number, obj: any) => {
        return summ + obj.total;
      },
      0
    );
    return numberFormatPayment(resultYearly!);
  }
  return data?.priceSummary.priceToPay
    ? numberFormatPayment(data?.priceSummary.priceToPay!)
    : "0";
};

const ResumePrice: React.FC<ResumePriceI> = ({
  couponInfo,
  checkoutData,
  handleAddCoupon,
  handlePurchasePeriod,
  handleRemoveCoupon,
  isAvailableToggle,
  product,
  translate,
  validateCoupon,
  onConditionsClick,
  paymentLocation,
}) => {
  const { isMobile } = useDevice();

  const { domain, isABtesting } = useContext(GetlifeContext);

  const alertRef = useRef<any>(null);
  const benefitsModalRef = useRef<any>(null);

  const couponCondition = !!checkoutData?.priceSummary.firstPayment;

  return (
    <>
      {isABtesting && (
        <AnimatedAlert
          type="success"
          icon="LockIcon"
          title={translate(
            "paymentChallenger.priceDetails.alertOneStep"
          ).replace(
            "{capital}",
            `${numberFormatPayment(product.capital, "capital")} €`
          )}
          description=""
          defaultState="closed"
          autoOpenIn={2000}
          autoCloseIn={5000}
          ref={alertRef}
        />
      )}
      {isMobile && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            alertRef?.current?.toggleAlert();
          }}
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
              e.preventDefault();
              alertRef?.current?.toggleAlert();
            }
          }}
        >
          <Chip
            type="success"
            text={translate("paymentChallenger.priceDetails.capitalOf").replace(
              "{capital}",
              `${numberFormatPayment(product.capital, "capital")} €`
            )}
            icon="LockIcon"
          />
        </div>
      )}
      <Typography
        variant="H3"
        weight="700"
        text={translate("paymentChallenger.resumePrice.title")}
      />
      {!isMobile && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            alertRef?.current?.toggleAlert();
          }}
          onKeyDown={(e) => {
            if (e.key === " " || e.key === "Enter") {
              e.preventDefault();
              alertRef?.current?.toggleAlert();
            }
          }}
        >
          <Chip
            type="success"
            text={translate("paymentChallenger.priceDetails.capitalOf").replace(
              "{capital}",
              `${numberFormatPayment(product.capital, "capital")} €`
            )}
            icon="LockIcon"
          />
        </div>
      )}

      {isAvailableToggle && (
        <>
          <TextWithInfoIcon
            background="white"
            content={translate("paymentChallenger.resumePrice.infoText")}
            infoIcon
            weight="400"
            overlayButtonLabel="Aceptar"
            overlayTitle={
              <>
                <Typography
                  variant="H3"
                  weight="700"
                  text={translate(
                    "paymentChallenger.resumePrice.overlay.title"
                  )}
                />
              </>
            }
            overlayPrice={
              <>
                <Typography variant="Body" text={""}>
                  {getMessage({
                    message: translate(
                      "paymentChallenger.resumePrice.overlay.text"
                    ),
                    replace: (
                      <b>
                        {translate(
                          "paymentChallenger.resumePrice.overlay.text.bold"
                        )}
                      </b>
                    ),
                  })}
                </Typography>
                <br></br>
                <Typography
                  variant="Body"
                  weight="700"
                  text={translate(
                    "paymentChallenger.resumePrice.overlay.textSecond"
                  )}
                />
              </>
            }
          />
          <Toggle
            initialValue={product.period !== "yearly" ? false : true}
            labels={[
              translate("paymentChallenger.resumePrice.toggle.first"),
              translate("paymentChallenger.resumePrice.toggle.second"),
            ]}
            onValueChange={(e) =>
              handlePurchasePeriod(!e ? "monthly" : "yearly")
            }
          />
        </>
      )}
      <hr></hr>
      <div className="paymentChallenger__body__sections--resumePrice--price">
          <div className="paymentChallenger__body__sections--resumePrice--price--details">
            <div>
              <Typography
                variant="Body"
                weight="400"
                text={translate("paymentChallenger.priceDetails.insurance")}
              />
              <Typography
                variant="BodyMedium"
                weight="500"
                text={translate(
                  "paymentChallenger.priceDetails.capital"
                ).replace(
                  "{capital}",
                  `${numberFormatPayment(product.capital, "capital")} €`
                )}
              />
              <Typography
                variant="Link"
                weight="500"
                text={translate("paymentChallenger.priceDetails.benefits")}
                onClick={() => benefitsModalRef.current?.handleShowModal()}
              />
            </div>
            <Typography
              variant="Span"
              weight="700"
              text=""
              className="monthlyPrice"
            >
              {`${numberFormatPayment(
                checkoutData?.priceSummary.regularPrice!
              )} € ${translate(
                `paymentChallenger.resumePrice.${product?.period}`
              )}`}
            </Typography>
          </div>
        <ShowPrice
          finalPrice={numberFormatPayment(
            checkoutData?.priceSummary?.regularPrice!
          )}
          frequencyText={translate(
            `paymentChallenger.resumePrice.${product?.period}`
          )}
          totalText={translate("paymentChallenger.resumePrice.total")}
          showRegularPrice={
            !!product.promotion &&
            checkoutData?.priceSummary?.regularPrice !==
              checkoutData?.priceSummary?.priceToPay
          }
          promotionPrice={
            product.promotion
              ? promotionPrice({ data: checkoutData, product })
              : undefined
          }

        />
        {couponInfo && (
          <div className="paymentChallenger__body__sections--resumePrice--price--conditions">
            <ConditionsPromoLink
              translate={translate}
              onConditionsClick={onConditionsClick}
            />
          </div>
        )}
      </div>
      {(domain === "es" || domain === "pt" || domain === "it") &&
        couponCondition && (
          <div className="paymentChallenger__body__sections--promoted">
            <div><GlobalIcon iconName="CalendarTimeIcon" size="XS" /></div>
            <Typography variant="BodySmall" text="" color="dark-gray-100">
              {getMessage({
                message: translate(
                  `payment.paymentCard.checkBox.promoted.${checkoutData?.lead.purchasePeriod}`
                )
                  .replace("%", formatGetlifeDate(DateTime.now(), domain))
                  .replace("&", validDateYear(domain, "dd-MM-yyyy"))
                  .replace("#", validDateYear(domain).split(",")[0]),
                replace: (
                  <Typography
                    variant="SpanSmall"
                    weight="700"
                    color="life-blue-100"
                    text={numberFormat(
                      checkoutData?.priceSummary.firstPayment,
                      "price"
                    )}
                  />
                ),
              })}
            </Typography>
          </div>
        )}
      {(!product?.promotion || product?.promotion?.promotionalCode) && (
        <Coupon
          couponCode={product?.promotion?.promotionalCode}
          headerLabel={translate("coupons.accordion.headerContentAB")}
          translate={translate}
          validateCoupon={validateCoupon}
          handleClick={handleAddCoupon}
          handleRemove={handleRemoveCoupon}
          yearlyPrice={product.finalYearlyPrice}
          isRemovable={product?.promotion?.removable}
          onConditionsClick={onConditionsClick}
          couponInfo={
            couponInfo ? (
              <ConditionsPromoLink
                translate={translate}
                onConditionsClick={onConditionsClick}
              />
            ) : undefined
          }
        />
      )}
      {!isMobile && (
        <div className="paymentChallenger__body__sections--resumePrice--final">
          <SecureEncryptor />
        </div>
      )}
      <BenefitsModal
        ref={benefitsModalRef}
        title={translate("calculatorPage.benefitsModal.title")}
        benefits={[
          {
            icon: (
              <GlobalIcon
                iconName="CalendarBrandIcon"
                size="M"
                color="var(--primary-color)"
                filled="#D9D8FD"
              />
            ),
            title: translate("calculatorPage.benefitsModal.benefit1.title"),
            description: translate(
              "calculatorPage.benefitsModal.benefit1.description"
            ),
          },
          {
            icon: (
              <GlobalIcon
                iconName="TransparencyIcon"
                size="M"
                color="var(--primary-color)"
                filled="#D9D8FD"
              />
            ),
            title: translate("calculatorPage.benefitsModal.benefit2.title"),
            description: translate(
              "calculatorPage.benefitsModal.benefit2.description"
            ),
          },
          {
            icon: (
              <GlobalIcon
                iconName="SaveMoneyIcon"
                size="M"
                color="var(--primary-color)"
                filled="#D9D8FD"
              />
            ),
            title: translate("calculatorPage.benefitsModal.benefit3.title"),
            description: translate(
              "calculatorPage.benefitsModal.benefit3.description"
            ),
          },
        ]}
      />
    </>
  );
};

export default ResumePrice;
