import React, { useEffect, useState } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './calendarGetlife.scss';
import { Calendar, utils } from 'react-modern-calendar-datepicker';
import { myCustomLocale } from './CustomLocale';

export default function CalendarGetlife(props) {
    const [selectedDay, setSelectedDay] = useState(null);

    useEffect(() => {
        if (selectedDay !== null) {
            const year = selectedDay.year;
            const month = selectedDay.month;
            const day = selectedDay.day;
            props.handleValues(`${year}-${month}-${day}`);
        }else{
            props.handleValues(null);
        }
    }, [selectedDay]);

    return (
        <Calendar
            value={selectedDay}
            onChange={setSelectedDay}
            maximumDate={utils().getToday()}
            colorPrimary="#104944" // added this
            colorPrimaryLight="#e3f4e8" // and this
            shouldHighlightWeekends
            locale={myCustomLocale}
            calendarClassName="responsive-calendar"
        />
    );
}
