import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Animation from "./../../../../../components/Components/lotties/waitingSignature.json";
import Lottie from "lottie-react";
import FadeIn from "react-fade-in";
import secure from "./../../../../../assets/img/secure.svg";
import "./../css/waitingSignature.scss";
import StarIcon from "@material-ui/icons/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import InputGetLife from "../../../../../components/Components/inputGetLife/InputGetLife";
import HttpLeadRepository from "../../../../../api/request/Lead/Lead.service";
import { GetlifeContext } from "../../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../../context/i18n.context";
import { ButtonDS } from "get-life-storybook-ts";

export default function WaitingSignature() {
  const location = useLocation();
  const actualUrl = location.pathname;
  const { token, leadId, domain, handleCallMe } = useContext(GetlifeContext);

  const [isSigned, setIsSigned] = useState(true);
  const [beneficiaries, setBeneficiaries] = useState(null);
  const [values, setValues] = useState({});
  const [response, setResponse] = useState("");
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [disabled, setDisabled] = useState(true);

  const leadRepository = new HttpLeadRepository(leadId, token);

  const rateUs = () => {
      window.open(translate("trustpilot.link"), "_blank");
  };

  const {
    state: { translate },
  } = useContext(I18nContext);

  const validations = [
    {
      name: "email",
      type: "string",
      errorText: translate("waitingSignature.emailError"),
      required: false,
      requiredText: translate("waitingSignature.emailRequired"),
      regExp:
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ];
  const handleValues = (emailEntered, id) => {
    const currentValues = { ...values };
    if (!currentValues[id]) {
      currentValues[id] = "";
    }
    currentValues[id] = emailEntered;
    setValues(currentValues);
    setDisabled(false);
  };

  const handleEmails = () => {
    const beneficiaries = [];

    Object.keys(values).map((element) => {
      let object = {
        id: element,
        email: values[element],
      };
      beneficiaries.push(object);
    });

    return {
      leadId: leadId,
      beneficiariesEmail: [...beneficiaries],
    };
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    leadRepository.getBeneficiaries().then((res) => {
      const beneficiaries = res.beneficiaries;
      setBeneficiaries(beneficiaries);
    });
  }, [leadId, token]);

  const listBeneficiaries = () => {
    return beneficiaries.map((item) => {
      return item.type === "physicalPerson" ? (
        <li key={item.id}>
          <label>{`${item.name} ${item.lastName} ${item.lastName2}`}</label>
          <InputGetLife
            id={item.id}
            handleValues={(e) => {
              handleValues(e, item.id);
            }}
            placeholder={translate("waitingSignature.emailPlaceholder")}
            actualUrl={actualUrl}
            answer={values[item.id] ?? ""}
            name="email"
            error={error["email" + item.id]}
            errorMessage={errorMessage["email" + item.id]}
          />
        </li>
      ) : (
        ""
      );
    });
  };

  const sendBeneficiariesEmail = () => {
    const emails = handleEmails();
    leadRepository
      .updateBeneficiariesNotify(emails)
      .then((res) => {
        setResponse("true");
        setDisabled(true);
      })
      .catch((e) => {
        setResponse("false");
      });
  };

  const inmediateSigned =
    new URLSearchParams(location.search).get("signed") === "1";
  const superTitle = inmediateSigned
    ? translate("waitingSignature.superTitle1")
    : translate("waitingSignature.superTitle2");
  const titleText = inmediateSigned
    ? translate("waitingSignature.titleText1")
    : translate("waitingSignature.titleText2");
  const subtitleText = inmediateSigned
    ? translate("waitingSignature.subtitleText1")
    : translate("waitingSignature.subtitleText2");
  const paragraphText = inmediateSigned
    ? ""
    : translate("waitingSignature.paragraphText");
  return (
    <div className={"waitingSignature-outerContainer"}>
      <FadeIn>
        <div className={"waitingSignature-container"}>
          <section className="waitingSignature-topContainer">
            <div className="waitingSignature-topContent">
              <Lottie
                className="waitingSignature-animation"
                animationData={Animation}
                loop={false}
              />
              <p className="waitingSignature-congrats">
                {translate("waitingSignature.congratulations")}
              </p>
              <p className="waitingSignature-congratsBold">{superTitle}</p>
              <p style={{ paddingTop: "29px" }} className="waitingSignature-p">
                {titleText}
              </p>
              <p className="waitingSignature-p">{subtitleText}</p>

              {!isSigned && (
                <p className="waitingSignature-signedError">{paragraphText}</p>
              )}
            </div>
          </section>

          <section className="waitingSignature-botomContainer">
            <article className="waitingSignature-boxContainer">
              <div className="waitingSignature-boxContent">
                {beneficiaries !== null &&
                beneficiaries !== undefined &&
                beneficiaries.length !== 0 ? (
                  <>
                    <h3 className="waitingSignature-box-title">
                      {translate("waitingSignature.beneficiariesTitle")}
                    </h3>
                    <p>{translate("waitingSignature.beneficiariesText")} </p>
                    <ul>{listBeneficiaries()}</ul>
                    <ButtonDS
                      label={translate("waiting.send")}
                      onClick={sendBeneficiariesEmail}
                      disabled={disabled}
                      className="w-full waitingSignature-box-button"
                    />
                    {response === "true" ? (
                      <p className="waitingSignature-box-message">
                        {translate("waitingSignature.beneficiariesDone")}
                      </p>
                    ) : response === "false" ? (
                      <p className="waitingSignature-box-message messageError">
                        {translate("waitingSignature.beneficiariesError")}
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <p className="waitingSignature-box-title">
                      <p>{translate("waitingSignature.experience")} </p>
                      <div className="waitingSignature-starsContent">
                        <StarIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        <StarIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        <StarIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        <StarIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        <StarHalfIcon
                          fontSize={"small"}
                          className={"waitingSignature_stars_icon_active"}
                        />
                        ?
                      </div>
                    </p>
                    <p className="waitingSignature-box-title">
                      {translate("waitingSignature.comment")}
                    </p>
                    <p className="waitingSignature-trusPilotLabel">
                      {translate("waitingSignature.trustpilot")}
                    </p>
                    <div className="waitingSignature-starBox">
                      <div className="waitingSignature-TruststarContainer">
                        <p className="waitingSignature-points">4.6</p>
                        <div className="waitingSignature-starsContent">
                          <StarIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                          <StarIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                          <StarIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                          <StarIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                          <StarHalfIcon
                            fontSize={"small"}
                            className={"waitingSignature_stars_icon_active"}
                          />
                        </div>
                      </div>
                      <div className="waitingSignature-starButtonContainer">
                        <ButtonDS
                          onClick={rateUs}
                          label={translate("waitingSignature.btnComment")}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </article>
          </section>
          <div className="waitingSignature-footerContainer">
            <img src={secure} alt="aplicación segura" />
            <p className="waitingSignature-footer">
              {translate("paymentFooter.text")}
            </p>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}
