import { Elements } from "@stripe/react-stripe-js";
import Popup from "reactjs-popup";
import { ButtonDS } from "get-life-storybook-ts";
import PolicyController from "./Policy.controller";
import Download from "./../../../../../assets/img/download.png";
import Calendar from "./../../../../../assets/img/calendar.png";
import "../css/policy.scss";
import ModalPolicy from "./ModalPolicy/ModalPolicy.component";
import WithdrawalModal from "./ModalWithdrawal/ModalWithdrawal.component";

const Policy = ({
    product,
    beneficiariesList,
    beneficiaries,
    handleAsesor,
    userData,
}: any) => {
    const {
        availablePaymentMethod,
        capitalFormatted,
        clientSecret,
        documents,
        fetchGetDataCard,
        modify,
        nodeElementModal,
        withdrawalModalRef,
        noSubscription,
        showTextPaymentMehtod,
        stripePromise,
        translate,
        translateBeneficiaries,
    } = PolicyController({ product });

    const ModalComponent = () => (
        <Popup
            trigger={
                <button className="dashboard-policyBeneficiariesDetail">
                    {translate("dashboard.policy.beneficiaryOthers.detailsBtn")}
                </button>
            }
            modal
        >
            <article className="dashboard-policyModal">
                <table>
                    <tr>
                        <th style={{ textAlign: "left" }}>
                            {translate("dashboard.policy.beneficiaryOthers.detailsName")}
                        </th>
                        <th>{translate("dashboard.policy.beneficiaryOthers.detailsId")}</th>
                        <th>
                            {translate("dashboard.policy.beneficiaryOthers.detailsCapital")}
                        </th>
                    </tr>
                    {beneficiariesList?.map((beneficiarie: any) => {
                        const { Nombre, Identificador, Capital } = beneficiarie;
                        return (
                            <tr key={Nombre + Identificador}>
                                <td> {Nombre}</td>
                                <td>{Identificador} </td>
                                <td>{Capital}€ </td>
                            </tr>
                        );
                    })}
                </table>
            </article>
        </Popup>
    );

    const Block = ({ field, data, others }: any): JSX.Element => {
        return (
            <div className="dashboard-policyBox">
                <div className="dashboard-policyBox_top">
                    <div className="dashboard-policyBoxHeader">
                        <p>{translate(`dashboard.policy.${field}Title`)}</p>
                    </div>

                    <div className="dashboard-policyBoxBody">
                        <p>{translate(`dashboard.policy.${field}Text`)}</p>

                        <p>
                            {field === "beneficiaries" ? translateBeneficiaries(data) : data}{" "}
                        </p>
                    </div>
                </div>
                <div className="dashboard-policyBox_bottom">
                    <div className="dashboard-policyButtonContainer">
                        <ButtonDS
                            label={translate("dashboard.policy.editBtn")}
                            onClick={modify}
                        />
                        {others}
                    </div>
                </div>
            </div>
        );
    };

    const handleClickOpen = () => {
        withdrawalModalRef.current?.handleShowPortal();
    };

    return (
        <div className="dashboard-policyContainer">
            <WithdrawalModal
                withdrawalModalRef={withdrawalModalRef}
                userData={userData}
                onTalkAgent={handleAsesor}
                signatureDate={product.signatureDate}
            />
            <p className="dashboard-title">{translate("dashboard.policy.title")}</p>
            <div className="dashboard-bodyPolicy">
                <div className="dashboard-policyheaderContainer">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p
                            className="dashboard-labelProfileBilling"
                            style={{ paddingLeft: "13.2px" }}
                        >
                            {translate("dashboard.policy.myInsurance")}
                        </p>
                    </div>
                </div>

                <div
                    className="dashboard-policyBodyContainer"
                    style={{ paddingTop: "28px", paddingLeft: "37px" }}
                >
                    <p
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "var(--primary-color)",
                        }}
                    >
                        {translate("dashboard.policy.lifeInsurance")}
                    </p>
                    <p
                        style={{
                            paddingTop: "27px",
                            fontSize: "16px",
                            color: "var(--primary-color)",
                        }}
                    >
                        {translate("dashboard.policy.text1")}
                        {capitalFormatted}
                        {translate("dashboard.policy.text2")}
                    </p>
                </div>
            </div>
            <div className="dashboard-policyBoxContainer">
                <Block field={"capital"} data={capitalFormatted} />
                <Block
                    field="warranty"
                    data={
                        product.coverage === "basic"
                            ? translate("dashboard.policy.warrantybasic")
                            : translate("dashboard.policy.warrantyPremium")
                    }
                />

                <Block
                    field={"beneficiaries"}
                    data={beneficiaries}
                    others={
                        beneficiaries === "Otros" && (
                            <>
                                <ModalComponent />
                            </>
                        )
                    }
                />
                {!noSubscription && <div className="dashboard-policyBox">
                    <div className="dashboard-policyBox_top">
                        <div className="dashboard-policyBoxHeader">
                            <p>{translate("dashboard.policy.paymentMethod")}</p>
                        </div>

                        <div className="dashboard-policyBoxBody-paymentMethod ">
                            <p className="first">{showTextPaymentMehtod()}</p>

                            <p className="second">
                                {translate("dashboard.policy.paymentMethodText")}
                            </p>
                        </div>
                    </div>
                    <div className="dashboard-policyBox_bottom">
                        <div className="dashboard-policyButtonContainer">
                            <ButtonDS
                                label={translate("dashboard.policy.editBtn")}
                                onClick={() => nodeElementModal.current?.handleShowPortal()}
                            />
                        </div>
                    </div>
                </div>}

                <div className="dashboard-policyBox">
                    <div className="dashboard-policyBoxHeader">
                        <p>{translate("dashboard.policy.cancelTitle")}</p>
                    </div>

                    <div
                        className="dashboard-policyBoxBody"
                        style={{
                            padding: " 0 24px",
                        }}
                    >
                        <p>{translate("dashboard.policy.cancelText1")}</p>

                        <p style={{ fontWeight: "normal", fontSize: "10px" }}>
                            {translate("dashboard.policy.cancelText2")}
                        </p>
                    </div>
                    <div className="dashboard-policyButtonContainer pl-[20px]">
                        <ButtonDS
                            buttonType="secondary"
                            label={translate("dashboard.policy.cancelBtn")}
                            onClick={handleClickOpen}
                        />
                    </div>
                </div>
            </div>

            <div className="dashboard-policyContract">
                <div className="dashboard-policyheaderContainer">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p
                            className="dashboard-labelProfileBilling"
                            style={{ paddingLeft: "13.2px" }}
                        >
                            {translate("dashboard.policy.contract.title")}
                        </p>
                    </div>
                </div>

                <div className="dashboard-policyheaderContainer">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            paddingRight: "45px",
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img
                                src={Calendar}
                                alt="calendario"
                                style={{ width: "23px", height: "21px" }}
                            />
                            <p
                                className="dashboard-labelProfileBilling"
                                style={{
                                    paddingLeft: "13.2px",
                                    fontWeight: "normal",
                                }}
                            >
                                {translate("dashboard.policy.contract.dateSigned")}
                            </p>
                        </div>
                        <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                            {product.signatureDate}
                        </div>
                    </div>
                </div>

                <div className="dashboard-policyheaderContainer">
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            paddingRight: "45px",
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img
                                src={Calendar}
                                alt="calendario"
                                style={{ width: "23px", height: "21px" }}
                            />
                            <p
                                className="dashboard-labelProfileBilling"
                                style={{
                                    paddingLeft: "13.2px",
                                    fontWeight: "normal",
                                }}
                            >
                                {translate("dashboard.policy.contract.dateRenewal1")}
                            </p>
                        </div>
                        <div
                            style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#a6a6a6",
                            }}
                        >
                            {product.renewalDate}
                        </div>
                    </div>
                </div>
                {documents && (documents.contractUrl || documents.certificateUrl) && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "15px",
                            alignItems: "center",
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            cursor: "pointer",
                            backgroundColor: "rgba(245,196,220, 0.5)",
                        }}
                    >
                        <img
                            src={Download}
                            alt="Descargar contrato"
                            style={{
                                width: "17px",
                                height: "14px",
                                paddingRight: "5px",
                                cursor: "pointer",
                            }}
                        />

                        <p style={{ fontSize: "12px" }}>
                            {documents && documents.contractUrl && (
                                <a href={documents.contractUrl} style={{ textDecoration: "none" }} target="_blank">
                                    {translate("dashboard.policy.contract.downloadBtn")} |{" "}
                                </a>

                            )}
                            {documents && documents.certificateUrl && (

                                <a href={documents.certificateUrl} style={{ textDecoration: "none" }} target="_blank">
                                    {translate("dashboard.policy.certificate.downloadBtn")}{" "}
                                </a>
                            )}
                        </p>
                    </div>
                )}
            </div>

            <Elements stripe={stripePromise}>
                <ModalPolicy
                    fetchGetDataCard={fetchGetDataCard}
                    availablePaymentMethod={availablePaymentMethod}
                    clientSecret={clientSecret}
                    nodeElementModal={nodeElementModal}
                    userData={userData[0].lead}
                />
            </Elements>
        </div>
    );
};
export default Policy;
